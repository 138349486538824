import React, { useEffect, useMemo, useRef, useState } from "react";
import Select from 'react-select'
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { assests } from "../../../../Assets/assets";
import { Box, Button, Card, Fab, NativeSelect, Typography } from "@mui/material";
import {
  CustomLabelInput,
  FormInput,
  InputField,
  InputTeaxtArea,
} from "../../../../Components/CustomElements";
import countryList from "react-select-country-list";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css'
import { languagesOptions } from "../../../../helper";
import { useNavigate } from "react-router-dom";
import { ImageUpload, updateUserInfo } from "../../../../Utils/Api";
import SnackBar from "../../../../Components/Snackbar";
import MultiUpload from "../../../../Components/MutipleUpload";
import { isAuthorised } from "../../../../Utils/helper";
import { setItem } from "../../../../Utils/localStorage";
import Masthead from "../../../../Components/Masthead";
import '../style.css'


const UserRegistrationForm = () => {
  const theme = createTheme();
  const navigate = useNavigate()
  const [snackbar, setSnackbar] = useState({ isOpen : false, message: '', type: ''})
  const [images, setImages] = useState([])
  const [userFormData, setUserFormData] = useState({
    hear_from_source: '',
    gender: '',
    date_of_birth: '',
    photo: '',
    using_whatsapp_messenger: '',
    another_whatsapp_mobile:'',
    preferedLanguage: [],
    street_name: '',
    city: '',
    country: '',
    postal_code: '',
  })
  const { hear_from_source, gender, photo, date_of_birth, using_whatsapp_messenger, another_whatsapp_mobile, preferedLanguage, street_name, city, country, postal_code} = userFormData
  const countryOptions = useMemo(() => countryList().getData(), [])
  const currentDate = new Date().toISOString().substring(0,10)
 const inputRefs = useRef({
  hear_from_source:null,
  gender:null,
  date_of_birth:null,
  using_whatsapp_messenger:null,
  street_name:null,
  city:null,
  postal_code:null,
  country:null,
  preferedLanguage:null
 }
 )
  const formatMultiSelectData = (data) => {
    const formatedData = data.map(({ value }) => value)
    return formatedData
  }

  const handleUpdateSitterData = async() => {
    let formData = new FormData()
    let ImgUrl = ''
    images.map((res) => {
      formData.append('image', res)
    })
    if(images.length > 0){
      await ImageUpload(formData).then((res) => {
        const data = res.data.data.url
        const list = data.map((url) => {return url})
        ImgUrl = list.toString()
        if(res.data.response_code == 80){
          setSnackbar({...snackbar, isOpen: true, message: "Profile Image Uploaded", type: 'success'})
        }
      })
    }
    await updateUserInfo({
      hear_from_source: hear_from_source,
      gender: gender,
      date_of_birth: date_of_birth,
      photo: ImgUrl,
      using_whatsapp_messenger: using_whatsapp_messenger,
      street_name: street_name,
      city: city,
      country: country.label,
      postal_code: postal_code,
      another_whatsapp_mobile: another_whatsapp_mobile,
      preferred_languages: formatMultiSelectData(preferedLanguage),
      }).then((res) => {
        const authStore = window.localStorage.getItem('AuthStore')
        const serialisedData = JSON.parse(authStore)
        const newResponse = res.data.data
        const merge = {...serialisedData, ...newResponse}
        setItem('AuthStore', JSON.stringify(merge))
      })
  }
  const showError = (field, message) => {
    const element = inputRefs.current[field];
  
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "center" });
      element.style.backgroundColor = "#FEF2F2"; 
    }
  
    setSnackbar({ isOpen: true, message, type: "error" });
  };
  const handleSubmit = async () => {
    const requiredFields = [
      "hear_from_source",
      "gender",
      "date_of_birth",
      "using_whatsapp_messenger",
      "street_name",
      "city",
      "postal_code",
      "country",
      "preferedLanguage"
    ];
  
    for (const field of requiredFields) {
      if (!userFormData[field] || (field === "preferedLanguage" && preferedLanguage.length === 0)) {
        showError(field, "Oops! Looks like you have missed a question");
        return;
      }
    }
  
    if (street_name.length < 4) {
      showError("street_name", "Street name must be at least 4 characters long");
      return;
    }
  
    // Proceed with submission
    await handleUpdateSitterData();
    navigate('/dog-registration');
  };
  
  return (
    <>
    <ThemeProvider theme={theme}>
    <Grid sx={{ display: "grid", gridTemplateRows: "auto 1fr", height: "100vh" }}>

    <Grid item sx={{ position: "sticky", top: 0, zIndex: 10 }}>
        <Masthead />
      </Grid>      
      <Grid container component="main" sx={{ flexGrow: 1, overflowY: "auto" ,scrollbarWidth: "none", /* Firefox */
    "&::-webkit-scrollbar": { display: "none" } /* Chrome, Safari, Edge */}}>            <CssBaseline />
        <Grid item xs={false} sm={2} md={4} sx={{
            backgroundImage: `url(${assests.newBgImg})`,
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light" 
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
          >
          <Grid className="welcome-wrapper">
            <p className="welcome-text">Welcome to Dogstays</p>
          </Grid>
        </Grid>
        <Grid className="hide-scrollbar" item xs={12} sm={10} md={8} component={Card} elevation={6} sx={{backgroundColor: '#ffff', height:'100%', overflowY: 'scroll', boxShadow: 'none'}}>
          <Typography component='h1' fontFamily='Brandon' sx={{width: '100%', textAlign: 'center', mt: 3, mb:3, fontSize:'28px'}}>Your Details</Typography>
          <Box
            className='form-registration-container'
              sx={{
                mt: 1,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
          <Grid container spacing={2} className="form-registration-wrapper">
            <Grid item xs={12} md={6} sx={{ display:'block' }}>
            <Grid item xs={12} md={10}>
              <FormInput fullWidth variant="standard">
                <CustomLabelInput htmlFor="hear_from_source">
                 How did you hear about DogStays? <span style={{color: 'red'}}>*</span>
                </CustomLabelInput>
                <NativeSelect
                  disableUnderline
                  defaultValue=''
                  ref={(el) => (inputRefs.current.hear_from_source = el)}
                  onFocus={() => {
                    if (inputRefs.current.hear_from_source) {
                      inputRefs.current.hear_from_source.style.backgroundColor = "";
                    }
                  }}   
                  value={hear_from_source}
                  onChange={(e) => setUserFormData({...userFormData, hear_from_source: e.target.value})}
                  name="hear_from_source"
                  id="hear_from_source"
                  inputProps={{style:{fontFamily: 'Brandon', padding:'8.5px 14px'}}}
                  sx={{ border: "1px solid #ced4d9", borderRadius: '3px', marginTop: '5px'}}
                >   <option selected disabled value=''>Select</option>
                    <option value='Google Search'>Google Search</option>
                    <option value='Facebook group'>Facebook group</option>
                    <option value='Through a friend'>Through a friend</option>
                    <option value='Booked With DogStays before'>Booked With DogStays before</option>
                    <option value='Facebook Ad'>Facebook Ad</option>
                    <option value='Google Ad'>Google Ad</option>
                    <option value='Other'>Other</option>
                    
                </NativeSelect>
              </FormInput>
            </Grid>

            {hear_from_source == "Other" &&
            <Grid item xs={12} md={10}>
            <FormInput fullWidth variant="standard">
              <CustomLabelInput shrink="true" >
              Please Share the Details
              </CustomLabelInput>
              <InputTeaxtArea
                size="small"
                placeholder="Enter Details"
                minRows={2}
                sx={{fontFamily: 'Brandon', height:"40px", fontSize: "1rem", border: "1px solid #ced4d9", borderRadius:'3px', padding: "8.5px 14px",  marginTop:"5px"}}
              />
            </FormInput>
          </Grid>
            }

            <Grid item xs={12} md={10}>
              <FormInput fullWidth variant="standard">
                <CustomLabelInput shrink="true" htmlFor=" ">
                 Gender <span style={{color: 'red'}}>*</span>
                </CustomLabelInput>
                <NativeSelect
                  disableUnderline
                  defaultValue=''
                  name="gender"
                  id="gender"
                  ref={(el) => (inputRefs.current.gender = el)}
                  onFocus={() => {
                    if (inputRefs.current.gender) {
                      inputRefs.current.gender.style.backgroundColor = "";
                    }
                  }}   
                  value={gender}
                  onChange={(e) => setUserFormData({...userFormData, gender: e.target.value})}
                  inputProps={{style:{fontFamily: 'Brandon', padding:'8.5px 14px'}}}
                  sx={{ border: "1px solid #ced4d9", borderRadius: '3px', marginTop: '5px'}}
                >   <option selected disabled value=''>Select</option>
                    <option value='male'>Male</option>
                    <option value='female'>Female</option>
                    <option value='other'>Other</option>
                </NativeSelect>
              </FormInput>
            </Grid>
            <Grid item xs={12} md={10}>
              <FormInput fullWidth variant="standard">
                <CustomLabelInput shrink="true" >
                  Your Date of birth <span style={{color: 'red'}}>*</span>
                </CustomLabelInput> 
                <InputField
                  name="date_of_birth"
                  type="date"
                  id="date_of_birth"
                  size="small"
                  ref={(el) => (inputRefs.current.date_of_birth = el)}
                  onFocus={() => {
                    if (inputRefs.current.date_of_birth) {
                      inputRefs.current.date_of_birth.style.backgroundColor = "";
                    }
                  }}   
                  onKeyDown={(e) => {e.preventDefault(); return}} // Prevent typing
                  onKeyUp={(e) => {e.preventDefault(); return}} // Prevent typing
                  value={date_of_birth}
                  InputProps={{ inputProps: { max: currentDate, min: '1925-01-01', inputMode: 'none'} }}
                  onChange={(e) => setUserFormData({...userFormData, date_of_birth: e.target.value})}
                  placeholder="Enter DOB"
                />
              </FormInput>
            </Grid>
            <Grid item xs={12} md={10}>
              <FormInput fullWidth variant="standard">
                <CustomLabelInput shrink="true" htmlFor="using_whatsapp_messenger">
                Do you use WhatsApp Messenger? <span style={{color: 'red'}}>*</span>
                </CustomLabelInput>
                <NativeSelect
                  disableUnderline
                  defaultValue=''
                  value={using_whatsapp_messenger}
                  ref={(el) => (inputRefs.current.using_whatsapp_messenger = el)}
                  onFocus={() => {
                    if (inputRefs.current.using_whatsapp_messenger) {
                      inputRefs.current.using_whatsapp_messenger.style.backgroundColor = "";
                    }
                  }}   
                  onChange={(e) => setUserFormData({...userFormData, using_whatsapp_messenger: e.target.value})}
                  name="using_whatsapp_messenger"
                  id="using_whatsapp_messenger"
                  inputProps={{style:{fontFamily: 'Brandon', padding:'8.5px 14px'}}}
                  sx={{ border: "1px solid #ced4d9", borderRadius: '3px', marginTop: '5px'}}
                >   <option selected disabled value=''>Select</option>
                    <option value='true'>Yes</option>
                    <option value='false'>No</option>
                </NativeSelect>
              </FormInput>
            </Grid>
            {using_whatsapp_messenger == 'true' && 
              <Grid item xs={12} md={10}>
              <FormInput fullWidth variant="standard">
                <CustomLabelInput shrink='true' htmlFor="another_whatsapp_mobile" sx={{marginBottom: '5px'}}>
                If the number you use on WhatsApp is different please share that with us here
                </CustomLabelInput>
                <PhoneInput
                  country={"lu"}
                  value={another_whatsapp_mobile}
                  onChange={(value) => setUserFormData({...userFormData, another_whatsapp_mobile: value})}
                  name="another_whatsapp_mobile"
                  id="another_whatsapp_mobile"
                />
              </FormInput>
            </Grid>}
            </Grid>

            <Grid item xs={12} md={6} sx={{display: 'block'}}>
            <Grid item xs={12} md={10}>
              <FormInput fullWidth variant="standard">
                <CustomLabelInput shrink="true" >
                  Street Name and Number <span style={{color: 'red'}}>*</span>
                </CustomLabelInput> 
                <InputField
                  type="text"
                  size="small"
                  value={street_name}
                  ref={(el) => (inputRefs.current.street_name = el)}
                  onFocus={() => {
                    if (inputRefs.current.street_name) {
                      inputRefs.current.street_name.style.backgroundColor = "";
                    }
                  }}   
                  onChange={(e) => {
                  let newValue = e.target.value.replace(/[^A-Za-z0-9\s]/g, ''); 
                    if (newValue.length > 50) {
                      setSnackbar({...snackbar, isOpen: true, message: "Street name cannot exceed 50 characters.", type: 'error'})
                    }  
                    setUserFormData({ ...userFormData, street_name: newValue.slice(0, 50) });
                  }}
                  placeholder="Enter Street Name and Number"
                />
              </FormInput>
            </Grid>
            <Grid item xs={12} md={10}>
              <FormInput fullWidth variant="standard">
                <CustomLabelInput shrink="true" >
                Area Name + City (Example: Merl, Luxembourg City) 
                <span style={{color: 'red'}}>*</span>
                </CustomLabelInput> 
                <InputField
                  type="text"
                  ref={(el) => (inputRefs.current.city = el)}
                  onFocus={() => {
                    if (inputRefs.current.city) {
                      inputRefs.current.city.style.backgroundColor = "";
                    }
                  }}  
                  size="small"
                  value={city}
                  onChange={(e) => {
                    const value = e.target.value.replace(/[^A-Za-z\s]/g, ''); 
                    setUserFormData({ ...userFormData, city: value });
                  }}
                  placeholder="Enter City"
                />
              </FormInput>
            </Grid>
            <Grid item xs={12} md={10}>
              <FormInput fullWidth variant="standard">
                <CustomLabelInput shrink="true" >
                  Postal Code <span style={{color: 'red'}}>*</span>
                </CustomLabelInput> 
                <InputField
                  type="text"
                  size="small"
                  ref={(el) => (inputRefs.current.postal_code = el)}
                  onFocus={() => {
                    if (inputRefs.current.postal_code) {
                      inputRefs.current.postal_code.style.backgroundColor = "";
                    }
                  }}  
                  value={postal_code}
                  onChange={(e) => {
                    const maxLength = 10;
                    const numericValue = e.target.value.replace(/\D/g, ""); 
                    if (numericValue.length <= maxLength) {
                      setUserFormData({ ...userFormData, postal_code: numericValue });
                    }
                  }}
                  placeholder="Enter Postal Code"
                />
              </FormInput>
            </Grid>            
            <Grid item xs={12} md={10}>
              <FormInput fullWidth variant="standard">
                <CustomLabelInput shrink="true"  sx={{marginBottom: '5px'}}>
                  Country <span style={{color: 'red'}}>*</span>
                </CustomLabelInput> 
                <div ref={(el) => (inputRefs.current.country = el)} >

                <Select
                className='select-font'
                options={countryOptions}
                  onFocus={() => {
                    if (inputRefs.current.country) {
                      inputRefs.current.country.style.backgroundColor = "";
                    }
                  }}  
                value={country}
                onChange={(value) => setUserFormData({
                  ...userFormData,
                  country: value
                })}
              />
              </div>
              </FormInput>
            </Grid>
            </Grid>
            <Grid item xs={12} sm={11}>
              <FormInput fullWidth variant="standard">
               <CustomLabelInput shrink='true' htmlFor="another_whatsapp_mobile" sx={{marginBottom: '5px'}}>
                Which languages do you speak or understand? <span style={{color: 'red'}}>*</span>
                </CustomLabelInput>
                <div ref={(el) => (inputRefs.current.preferedLanguage = el)} >
                <Select
                className='select-font'
                options={languagesOptions}
                value={preferedLanguage}
  onFocus={() => {
    if (inputRefs.current.preferedLanguage) {
      inputRefs.current.preferedLanguage.style.backgroundColor = "";
    }
  }}
                isMulti
                onChange={(value) => setUserFormData({
                  ...userFormData,
                  preferedLanguage: value
                })}
                name='preferred_languages'
                placeholder='Please select multiple languages'
              /></div>
              </FormInput>
            </Grid>
            <Grid item xs={12} sm={11}>
              <MultiUpload text='Please upload your profile picture' imageAssets={(res) => setImages(res)} limit={1}/>
            </Grid>

            <Grid item xs={12} sx={{display: 'flex', justifyContent:'center'}}>
            <Button
                type="submit"
                className="default-btn"
                variant="contained"
                onClick={handleSubmit}
                sx={{ mt: 3, mb: 2, width: "25%" }}
              >
                Continue
              </Button>
            </Grid>
 
          </Grid>
          </Box>
        </Grid>
      </Grid>
      </Grid>
    </ThemeProvider>
    <SnackBar
      open={snackbar.isOpen}
      handleClose={() => setSnackbar({...snackbar, isOpen: false})}
      duration={300000}
      msg={snackbar.message}
      type={snackbar.type}
    />
    </>
  );
};

export default UserRegistrationForm;
