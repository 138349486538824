import { Box, Button, Grid, Typography } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { createOTP, updateUserInfo, verifyEmailOTP, verifyMobileOTP } from "../../../../Utils/Api";
import { assests } from "../../../../Assets/assets";
import { CustomLabelInput, FormInput, InputField } from "../../../../Components/CustomElements";
import SnackBar from "../../../../Components/Snackbar";
import { getItem, removeItem, setItem } from "../../../../Utils/localStorage";
import PhoneInput from "react-phone-input-2";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import "../style.css"
import DynamicInfoDialog from "../../../../Components/Dialogs/dynamicInfoDialog";
import { isAuthorised } from "../../../../Utils/helper";
import Masthead from "../../../../Components/Masthead";
import { countryValidationRules } from "../../../../Utils/countryValidationRules";

const UserVerification = () => {
const navigate = useNavigate()
const [emailVerification, setEmailVerification] = useState({email: '', otp: '', status: '', disabled: false})
const [mobileVerification, setMobileVerification] = useState({mobile: '', otp: '', status: '', countryCode: '', disabled: false})
const [verifyEmailStatus, setVerifyEmailStatus] = useState(false)
const [verifyMobileStatus, setVerifyMobileStatus] = useState(false)
const [snackbar, setSnackbar] = useState({ isOpen : false, message: '', type: ''})
const [userForm, setUserForm] = useState({ firstName: '', lastName: '', password: '', cnfPassword: ''})
const [passwordInfoDialog, setPasswordInfoDialog] = useState(false)
const [emailError,setEmailError] = useState("")

const inputRefs = useRef({
  firstName: null,
  lastName: null,
  email:null,
  mobile:null,
  password: null,
  cnfPassword:null,
});



const [handlePasswords, setHandlePasswords] = useState({
  showPassword: false,
  passwordType: 'password',
  showCnfPassword: false,
  cnfpasswordType: 'password'
})
const theme = createTheme();

useEffect(() => {
  const origin = window.localStorage.getItem('origin');
  const getGoogleEmail = window.localStorage.getItem('email');
  if(origin == 'social'){
    setEmailVerification({...emailVerification, email:getGoogleEmail, status: 'verified', disabled: true})
  }
}, [])


const vaildPasswordRegx = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#@$!%*?&])[A-Za-z\d#@$!%*?&]{8,}$/;
const alphabetRegex = /^[A-Za-z]+$/
const emailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

const checkStringValidate = (value, inputName) => {
  if(alphabetRegex.test(value) && value.length > 0){
    return true
  }else{
    setUserForm({ ...userForm, [inputName]: '' })
    setSnackbar({ ...snackbar, isOpen: true, message: 'Only alphabets characters', type: 'error' })
    return false
  }
  }
  const showError = (field, message) => {
    const element = inputRefs.current[field];
  
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "center" });
      element.style.backgroundColor = "#FEF2F2"; 
    }
  
    setSnackbar({ isOpen: true, message, type: "error" });
  };

const handleCreateEmailOtp = async() => {
  if(emailVerification.email == ''){
    showError("email","Please Enter Your Email")
  }else if(!emailRegex.test(emailVerification.email)){
    setSnackbar({...snackbar, isOpen: true, message: `Invalid Email`, type: 'error'})
  }else{
    await createOTP({
      email: emailVerification.email
    }).then((res) => {
      if(res.data.response_code == 90){
        setVerifyEmailStatus(false)
        setEmailError(res.data.developer_message)
      }
      if(res.data.response_code == 80){
        setVerifyEmailStatus(true)
        setEmailError("")
      }
    })
  }
  }
  const handleKeyDown = (e) => {
    const input = e.target.value.replace(/\D/g, '');
    
    // Allow backspace, delete, arrow keys, and other navigation keys
    if (["Backspace", "Delete", "ArrowLeft", "ArrowRight"].includes(e.key)) {
      return;
    }
  
    // Find matching country code
    const countryEntry = Object.entries(countryValidationRules).find(([code]) => 
      input.startsWith(code.replace(/\D/g, ''))
    );
  
    if (countryEntry) {
      const [countryCode, { minLength }] = countryEntry;
      const numericCountryCode = countryCode.replace(/\D/g, '');

      if (numericCountryCode === "62") {
        if (input.length > 13) {
          e.preventDefault();
        }
        return;
      }
      if (numericCountryCode === "66") {
        if (input.length > 11) {
          e.preventDefault();
        }
        return;
      }
      if (numericCountryCode === "670") {
        if (input.length > 11) {
          e.preventDefault();
        }
        return;
      }
      if (numericCountryCode === "95") {
        if (input.length > 10) {
          e.preventDefault();
        }
        return;
      }
      if (numericCountryCode === "678") {
        if (input.length > 9) {
          e.preventDefault();
        }
        return;
      }
      if (numericCountryCode === "688") {
        if (input.length > 8) {
          e.preventDefault();
        }
        return;
      }
      const totalMaxLength = numericCountryCode.length + minLength;
      
      if (input.length >= totalMaxLength) {
        e.preventDefault();
      }
    }
  };
  const handleCreateMobileOtp = async () => {
    const { countryCode, mobile } = mobileVerification;
    
    if (!mobile) {
      showError("mobile", "Please Enter Your Mobile");
      return;
    }
  
    const countryRule = countryValidationRules[countryCode];
    
    if (countryRule) {
      const { minLength } = countryRule;
      if (mobile.length < minLength) {
        showError("mobile", `Mobile number must be at least ${minLength} digits.`);
        return;
      }
    }
  
    try {
      await createOTP({
        mobile: mobileVerification.mobile,
        country_code: mobileVerification.countryCode
      });
      setVerifyMobileStatus(true);
    } catch (error) {
      // Handle error if needed
    }
  };

const handleVerifyEmailOtp = async() => {
    await verifyEmailOTP({
      email:emailVerification.email,
      otp:emailVerification.otp
    }).then((res) => {
        if(res.data.response_code == 90){
          setSnackbar({...snackbar, isOpen: true, message: `${res.data.ui_message}`, type: 'error'})
        }else if(res.data.response_code == 80){
          setItem('token', res.data.data.token);
          setSnackbar({...snackbar, isOpen: true, message: "Email Verified", type: 'success'})
          setVerifyEmailStatus(false)
          setEmailVerification({...emailVerification, disabled:true, status: 'verified'})
        }
    })
}

const handleVerifyMobileOtp = async() => {
    await verifyMobileOTP({
      mobile: mobileVerification.mobile,
      country_code: mobileVerification.countryCode,
      otp: mobileVerification.otp
    }).then((res) => {
      if(res.data.response_code == 90){
        setSnackbar({...snackbar, isOpen: true, message: `${res.data.ui_message}`, type: 'error'})
      }else{
        setSnackbar({...snackbar, isOpen: true, message: "Mobile Verified", type: 'success'})
        setVerifyMobileStatus(false)
        setMobileVerification({...mobileVerification, status: 'verified', disabled: true})
      }
  })
}

const handleEmailData = (e) => {
    setEmailVerification({
      ...emailVerification,
      [e.target.name]: e.target.value,
    });
  };

const handleMobileData = (e) => {
    setMobileVerification({
      ...mobileVerification,
      [e.target.name]: e.target.value,
    });
  };
  
  const handleUpdateUserInfo = () => {
    const requiredFields = ["firstName", "lastName", "password"];
  
    for (const field of requiredFields) {
      if (!userForm?.[field]) {
        showError(field, "Oops! Looks like you have missed a question");
        return;
      }
    }
  
    if (!vaildPasswordRegx.test(userForm.password)) {
      setPasswordInfoDialog(true);
      return;
    }
  
    if (userForm.password !== userForm.cnfPassword) {
      showError("cnfPassword", "Password doesn't match");
      return;
    }
  
    if (emailVerification?.status !== 'verified') {
      showError("email", "Verify Your Email");
      return;
    }
  
    if (mobileVerification?.status !== 'verified') {
      showError("mobile", "Verify Your Mobile");
      return;
    }
  
    updateUserInfo({
      first_name: userForm.firstName,
      last_name: userForm.lastName,
      password: userForm.password
    }).then((res) => {
  
      if (res.data.response_code === 90) {
        setSnackbar({ ...snackbar, isOpen: true, message: `${res.data.ui_message}`, type: 'error' });
      } else if (res.data.response_code === 80) {
        const userData = res.data.data;
  
  
        if (userData) {
          setItem('AuthStore', JSON.stringify(userData));
        } else {
          console.error("Error: userData is undefined");
        }
  
        setTimeout(() => {
          window.location.href = '/user-registration';
        }, 500);
      }
    }).catch((error) => {
      console.error("Error updating user info:", error);
    });
  };
  

return(
    <>
    <ThemeProvider theme={theme}>
          <Grid sx={{ display: "grid", gridTemplateRows: "auto 1fr", height: "100vh" }}>
      
    <Grid item sx={{ position: "sticky", top: 0, zIndex: 10 }}>
        <Masthead />
      </Grid>
 <Grid container component="main" sx={{ flexGrow: 1, overflowY: "auto" ,scrollbarWidth: "none", /* Firefox */
    "&::-webkit-scrollbar": { display: "none" } /* Chrome, Safari, Edge */}}>    
      <Grid
          item xs={false} sm={4} md={6} sx={{
          backgroundImage: `url(${assests.newBgImg})`,
          backgroundRepeat: "no-repeat",
          backgroundColor: (t) =>
            t.palette.mode === "light"
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
          <Grid className="welcome-wrapper">
            <p className="welcome-text">Welcome to Dogstays</p>
          </Grid>
        </Grid>
        <Grid className="form-verification-container"   item xs={12} sm={8} md={6} elevation={6} sx={{boxShadow: 'none'}}>
        <div className="wrapper" style={{backgroundColor: 'white', padding: '0px 20px', borderRadius:'10px'}}>
          <h1 style={{fontFamily:'Amatic', textAlign:'center', margin:'0'}}>Hello!</h1>
            <p style={{fontSize: '16px', fontFamily:'Brandon', color: '#444140', textAlign: 'center'}}>
              Let's get started. First, please share the below details with us. You also need to set a password for your DogStays account.
               The next time you want to send us a booking request you can use this email address and password to login to your DogStays dashboard and send us a request from there.
            </p>
              <Grid item xs={12}>
                <FormInput fullWidth variant="standard">
                    <CustomLabelInput shrink='true' htmlFor="firstName">
                      First Name <span style={{color: 'red'}}>*</span>
                    </CustomLabelInput>
                    <InputField
                    name="firstName"
                    ref={(el) => (inputRefs.current.firstName = el)}
                    type="text"
                    id="firstName"
                    size="small"
                    onFocus={() => {
                      if (inputRefs.current.firstName) {
                        inputRefs.current.firstName.style.backgroundColor = "";
                      }
                    }}                    placeholder="Enter Name"
                    onChange={(e) => {checkStringValidate(e.target.value, e.target.name) && setUserForm({...userForm, firstName: e.target.value})}}
                    value={userForm.firstName}
                    />
                </FormInput>
              </Grid>
              <Grid item xs={12}>
                <FormInput fullWidth variant="standard">
                    <CustomLabelInput shrink='true' htmlFor="lastName">
                    Last Name <span style={{color: 'red'}}>*</span>
                    </CustomLabelInput>
                    <InputField
                    name="lastName"
                    type="text"
                    ref={(el) => (inputRefs.current.lastName = el)}
                    onFocus={() => {
                      if (inputRefs.current.lastName) {
                        inputRefs.current.lastName.style.backgroundColor = "";
                      }
                    }}  
                    id="lastName"
                    size="small"
                    placeholder="Enter Name"
                    onChange={(e) => {
                      const value = e.target.value;
                      if (value.length > 30) return; 
                      if ( checkStringValidate(value, e.target.name)) {
                        setUserForm({ ...userForm, lastName: value });
                      }
                    }}                  
                      value={userForm.lastName}
                    />
                </FormInput>
              </Grid>

          <Grid container sx={{display:'flex', alignItems:'end' }}>
            <Grid item xs={9}>
                <FormInput fullWidth variant="standard">
                    <CustomLabelInput shrink='true' htmlFor="email">
                      Email Address <span style={{color: 'red'}}>*</span>
                    </CustomLabelInput>
                    <InputField
                    name="email"
                    ref={(el) => (inputRefs.current.email = el)}
                    onFocus={() => {
                      if (inputRefs.current.email) {
                        inputRefs.current.email.style.backgroundColor = "";
                      }
                    }}  
                    type="email"
                    id="email"
                    size="small"
                    placeholder="Enter Email"
                    onChange={handleEmailData}
                    value={emailVerification.email}
                    disabled={emailVerification.disabled}
                    />
                </FormInput>

                </Grid>

                <Grid item xs={3} sx={{ textAlign: "center" }}>
               {emailVerification.status == 'verified' ? 
               <Typography className="verified-badge" component='h5' >Verified</Typography>
               :
               <Button className="default-btn" variant="contained" sx={{marginBottom:'2px'}}
                    onClick={handleCreateEmailOtp}    
                >
                    {!verifyEmailStatus ? 'VERIFY' : 'Resend'}
                </Button>}
                </Grid>
                {emailError && (
  <p style={{ fontSize: "14px", color: "red", margin: "1px",fontFamily:'Brandon' }}>
    {emailError} {" "}
    <a href="/login" target="_blank" rel="noopener noreferrer" style={{color:"#fe7648",fontSize:"14px",fontFamily:'Brandon',fontWeight:"600"}}>
       here
    </a>
  </p>
)}

                {verifyEmailStatus && 
                <>
                <Grid item xs={9}>
                <Typography fontFamily="Brandon" mt={1} sx={{fontSize: "14px" ,color: '#fe7648'}} >We have sent a one time password to your email address. Please enter it below.
                  Remember to check the spam folder too!</Typography>
                <FormInput fullWidth variant="standard">
                    <CustomLabelInput shrink='true' htmlFor="otp">
                    One Time Password (OTP)
                    </CustomLabelInput>
                    <InputField
                    name="otp"
                    type="text"
                    id="otp"
                    size="small"
                    placeholder="Enter OTP"
                    onChange={handleEmailData}
                    value={emailVerification.otp}
                    sx={{border: '1px soliud red'}}
                    />
                </FormInput>
                </Grid>
                <Grid item xs={3} sx={{ textAlign: "center" }}>
                  <Button className="default-btn" variant="contained" onClick={handleVerifyEmailOtp}>
                    Verify
                  </Button>
                </Grid>
                </>
                }
          </Grid>

          <Grid container sx={{display:'flex', alignItems:'end' }}>
          <Grid item xs={9}>
                <FormInput fullWidth variant="standard">
                    <CustomLabelInput shrink='true' htmlFor="mobile">
                     Mobile <span style={{color: 'red'}}>*</span>
                    </CustomLabelInput>
                    <div ref={(el) => (inputRefs.current.mobile = el)} >

                    <PhoneInput
  country={"lu"}
  id="mobile"
  disabled={mobileVerification.disabled}
  // value={userForm.mobile}  // Ensure this value is properly formatted
  onFocus={() => {
    if (inputRefs.current.mobile) {
      inputRefs.current.mobile.style.backgroundColor = "";
    }
  }}
  onChange={(value, data) => {
    setMobileVerification({
      ...mobileVerification,
      mobile: value.replace(data.dialCode, "").trim(), // Ensure correct slicing
      countryCode: data.dialCode
    });
  }}
  onKeyDown={handleKeyDown}
/>


  
</div>

                </FormInput>
                </Grid>
                <Grid item xs={3} sx={{ textAlign: "center" }}>
                {mobileVerification.status == 'verified' ? 
                <Typography className="verified-badge" component='h5'>Verified</Typography> :
                <Button className="default-btn" variant="contained" sx={{marginBottom:'2px'}}
                    onClick={handleCreateMobileOtp}>{!verifyMobileStatus ? 'VERIFY' : 'Resend'} </Button>
                }
                </Grid>
                {verifyMobileStatus && 
                <>
                <Grid item xs={9}>
                <FormInput fullWidth variant="standard">
                    <CustomLabelInput shrink='true' htmlFor="otp">
                    One Time Password (OTP)
                    </CustomLabelInput>
                    <InputField
                    name="otp"
                    type="text"
                    id="otp"
                    size="small"
                    placeholder="Enter OTP"
                    onChange={handleMobileData}
                    value={mobileVerification.otp}
                    sx={{border: '1px soliud red'}}
                    />
                </FormInput>
                </Grid>
                <Grid item xs={3} sx={{ textAlign: "center" }}>
                <Button className="default-btn" variant="contained" onClick={handleVerifyMobileOtp}>
                    Verify
                </Button>
                </Grid>
                </>
                }
          </Grid>
          <Grid item xs={12}>
                <FormInput fullWidth variant="standard">
                    <CustomLabelInput shrink='true' htmlFor="password">
                    Password <span style={{color: 'red'}}>*</span>
                    </CustomLabelInput>
                    <p style={{fontSize: '14px', fontFamily:'Brandon', color: '#fe7648', margin: 0}}>Password must contain: Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character (can include #@$!%*?&)</p>
                    <InputField
                    name="password"
                    type={handlePasswords.passwordType}
                    id="password"
                    size="small"
                    ref={(el) => (inputRefs.current.password = el)}
                    onFocus={() => {
                      if (inputRefs.current.password) {
                        inputRefs.current.password.style.backgroundColor = "";
                      }
                    }}                  
                        placeholder="Enter Password"
                    onChange={(e) => {setUserForm({...userForm, password: e.target.value})}}
                    value={userForm.password}
                    sx={{position: 'relative'}}
                    />
                <Box sx={{position: 'absolute', bottom: '4px', right: '10px'}}>
                  {!handlePasswords.showPassword ? 
                  <VisibilityIcon sx={{color: '#fe7648', cursor: 'pointer'}} onClick={() => setHandlePasswords({...handlePasswords, showPassword: true, passwordType: 'text'})}/>
                   : <VisibilityOffIcon sx={{color: '#fe7648', cursor: 'pointer'}} onClick={() => setHandlePasswords({...handlePasswords, showPassword: false, passwordType: 'password'})}/>}
                </Box>
                </FormInput>
              </Grid>
              <Grid container sx={{display:'flex', alignItems:'end' }}>
              <Grid item xs={12}>
                <FormInput fullWidth variant="standard">
                    <CustomLabelInput shrink='true' htmlFor="cnf_password">
                    Confirm Password <span style={{color: 'red'}}>*</span>
                    </CustomLabelInput>
                    <InputField
                    name="cnf_password"
                    type={handlePasswords.cnfpasswordType}
                    id="cnf_password"
                    ref={(el) => (inputRefs.current.cnfPassword = el)}
                    onFocus={() => {
                      if (inputRefs.current.cnfPassword) {
                        inputRefs.current.cnfPassword.style.backgroundColor = "";
                      }
                    }}  
                    size="small"
                    placeholder="Enter Password"
                    onChange={(e) => {setUserForm({...userForm, cnfPassword: e.target.value})}}
                    value={userForm.cnfPassword}
                    sx={{position: 'relative'}}
                    />
                <Box sx={{position: 'absolute', bottom: '4px', right: '10px'}}>
                  {!handlePasswords.cnfPassword ? 
                  <VisibilityIcon sx={{color: '#fe7648', cursor: 'pointer'}} onClick={() => setHandlePasswords({...handlePasswords, cnfPassword: true, cnfpasswordType: 'text'})}/>
                   : <VisibilityOffIcon sx={{color: '#fe7648', cursor: 'pointer'}} onClick={() => setHandlePasswords({...handlePasswords, cnfPassword: false, cnfpasswordType: 'password'})}/>}
                </Box>
                </FormInput>
              </Grid>
              {/* <Grid item xs={3} sx={{ textAlign: "center" }}>
                <Typography component='h5' sx={{color: '#66bb6a', fontFamily:'Brandon', fontWeight: 'bold'}}>Verified</Typography>
              </Grid> */}
              </Grid>
              <Grid item xs={12} sx={{ marginTop: "25px", textAlign: 'center'}}>
                  <Button variant="contained"  className="default-btn" sx={{width: "35%"}} onClick={handleUpdateUserInfo}>
                Continue
              </Button>
            </Grid>
        </div>
        </Grid>
      </Grid>
      </Grid>
    </ThemeProvider>
    <DynamicInfoDialog 
      isOpen={passwordInfoDialog}
      handleCloseDialog={() => {setPasswordInfoDialog(false)}}
      title={"Password Must Contain:"}
      subtitle={"Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character (can include #@$!%*?&)"}
    />
    <SnackBar
      open={snackbar.isOpen}
      handleClose={() => setSnackbar({...snackbar, isOpen: false})}
      duration={300000}
      msg={snackbar.message}
      type={snackbar.type}
    />
    </>
)
}

export default UserVerification