import React, { useEffect, useState } from "react";
import { Box, Button, Grid, NativeSelect, Typography } from "@mui/material";
import Masthead from "../../../../../../Components/Masthead";
import "../style.css";
import {
  CustomLabelInput,
  FormInput,
  InputField,
  InputTeaxtArea,
} from "../../../../../../Components/CustomElements";
import MultiUpload from "../../../../../../Components/MutipleUpload";
import { useNavigate, useParams } from "react-router-dom";
import { ImageUpload, getDogdetailByid, updateDogdetailById } from "../../../../../../Utils/Api";
import { BackBtn } from "../../../../../../Assets/assets";
import SnackBar from "../../../../../../Components/Snackbar";
import EditMultiUpload from "../../../../../../Components/MutipleUpload/editUpload";

const EditDog = () => {
  const navigate = useNavigate()
  const [dogFormData, setDogFormData] = useState({
    name: null,
    breed: null,
    gender: null,
    photos: [],
    dob: null,
    is_neutered: null,
    age_category: null,
    size_category: null,
    is_friendly_with_other_dogs: null,
    is_friendly_with_other_dogs_text: null,
    is_comfortable_around_cat: null,
    is_comfortable_around_cat_text: null,
    chew_or_damage_things: null,
    chew_or_damage_things_text: null,
    can_left_alone: null,
    can_left_how_long: null,
    can_left_how_long_text: null,
    reaction_toward_children: null,
    reaction_toward_stranger: null,
    is_toilet_trained: null,
    is_toilet_trained_text: null,
    is_aggressive: null,
    is_aggressive_text: null,
    is_vaccinated: null,
    is_vaccinated_text: null,
    additional_info: null,
  });
  const [snackbar, setSnackbar] = useState({ isOpen: false, message: '', type: '' })
  const [images, setImages] = useState([])
  const [editFilterImages, setEditFilterImages] = useState()

  const {
    name,
    breed,
    gender,
    photos,
    dob,
    is_neutered,
    age_category,
    size_category,
    is_friendly_with_other_dogs,
    is_friendly_with_other_dogs_text,
    is_comfortable_around_cat,
    is_comfortable_around_cat_text,
    chew_or_damage_things,
    chew_or_damage_things_text,
    can_left_alone,
    can_left_how_long,
    can_left_how_long_text,
    reaction_toward_children,
    reaction_toward_stranger,
    is_toilet_trained,
    is_toilet_trained_text,
    is_aggressive,
    is_aggressive_text,
    is_vaccinated,
    is_vaccinated_text,
    additional_info,
  } = dogFormData;

const inputRefs = React.useRef({
  name: null,
  breed: null,
  gender: null,
  dob:null,
  is_neutered:null,
  age_category:null,
  size_category:null,
  images:null,
  is_friendly_with_other_dogs:null,
  is_friendly_with_other_dogs_text:  null,

  is_comfortable_around_cat:null,
  is_comfortable_around_cat_text:null,
  chew_or_damage_things:null,
  chew_or_damage_things_text:null,
  can_left_alone:null,

  can_left_how_long:null,
  can_left_how_long_text: null,

  reaction_toward_children:null,

  reaction_toward_stranger:null,
  is_toilet_trained:null,
  is_toilet_trained_text:null,
  is_aggressive:null,
  is_aggressive_text:null,
  is_vaccinated:null,
  is_vaccinated_text:null,
});
  const getDogDetails = async () => {
    const { data } = await getDogdetailByid(dogId)
    setDogFormData({
      ...dogFormData,
      name: data.data.name,
      breed: data.data.breed,
      gender: data.data.gender,
      photos: data.data.photos,
      dob: data.data.dob,
      is_neutered: data.data.is_neutered,
      age_category: data.data.age_category,
      size_category: data.data.size_category,
      is_friendly_with_other_dogs: data.data.is_friendly_with_other_dogs,
      is_friendly_with_other_dogs_text: data.data.is_friendly_with_other_dogs_text,
      is_comfortable_around_cat: data.data.is_comfortable_around_cat,
      is_comfortable_around_cat_text: data.data.is_comfortable_around_cat_text,
      chew_or_damage_things: data.data.chew_or_damage_things,
      chew_or_damage_things_text: data.data.chew_or_damage_things_text,
      can_left_alone: data.data.can_left_alone,
      can_left_how_long: data.data.can_left_how_long,
      can_left_how_long_text: data.data.can_left_how_long_text,
      reaction_toward_children: data.data.reaction_toward_children,
      reaction_toward_stranger: data.data.reaction_toward_stranger,
      is_toilet_trained: data.data.is_toilet_trained,
      is_toilet_trained_text: data.data.is_toilet_trained_text,
      is_aggressive: data.data.is_aggressive,
      is_aggressive_text: data.data.is_aggressive_text,
      is_vaccinated: data.data.is_vaccinated,
      is_vaccinated_text: data.data.is_vaccinated_text,
      additional_info: data.data.additional_info,
    })
  }

  const { dogId } = useParams()


  useEffect(() => {
    getDogDetails()
  }, [])
  const showError = (field, message) => {
    const element = inputRefs.current[field];
  
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "center" });
      element.style.border = "1px solid #EF4444"; // Orange border
    }
  
    setSnackbar({ isOpen: true, message, type: "error" });
};

const clearError = (field) => {
    const element = inputRefs.current[field];
    if (element) {
      element.style.backgroundColor = ""; // Clear background
    }
};
 
  const handleUpdateDogFormDetails = () => {
  
    

    
  
    if(!name || name.length < 3){
      showError("name", "Name must be at least 3 characters long");
      return;
    } else {
      clearError("name");
    }
    if(!breed){  // This will catch null, undefined, and empty string
      showError("breed", "Please enter your dog's breed");
      return;
    }
    if(!gender){
      showError("gender","Please select a gender")
      return;
    } else {
        clearError("gender")
    }
    if(!dob ){
      showError("dob", "Please select your dog's date of birth");
      return;
    } else {
      clearError("dob");
    }

   if (!is_neutered) {
      showError("is_neutered", "Please select an option");
      return;
  } else {
      clearError("is_neutered");
  }
  if (!age_category) {
      showError("age_category", "Please select an option");
      return;
  } else {
      clearError("age_category");
  }
  if (!size_category ) {
      showError("size_category", "Please select an option");
      return;
  } else {
      clearError("size_category");
  }

    if (!is_friendly_with_other_dogs) {
      showError("is_friendly_with_other_dogs", "Please select an option");
      return;
  } else {
      clearError("is_friendly_with_other_dogs");
  }
  if (is_friendly_with_other_dogs !== "Yes" && !is_friendly_with_other_dogs_text) {
    showError("is_friendly_with_other_dogs_text", "Please provide details about your dog's behavior with other dogs");
    return;
} else {
    clearError("is_friendly_with_other_dogs_text");
}
    if(!is_comfortable_around_cat ){
      showError("is_comfortable_around_cat", "Please select an option");
      return;
    }
    else {
      clearError("is_comfortable_around_cat");
  }
    if (is_comfortable_around_cat !== "Yes" && !is_comfortable_around_cat_text) {
      showError("is_comfortable_around_cat_text", "Please provide details about your dog's behavior with cats");
      return;
    }
    else {
      clearError("is_comfortable_around_cat_text");
  }
    if(!chew_or_damage_things ){
      showError("chew_or_damage_things", "Please select an option");
      return;
    }
    else {
      clearError("chew_or_damage_things");
  }
    if (chew_or_damage_things !== "No" && !chew_or_damage_things_text) {
      showError("chew_or_damage_things_text", "Please provide details about your dog's behavior with chewing or damaging things");
      return;
    }
    else{
      clearError("chew_or_damage_things_text");
    }
    if(!can_left_alone ){
      showError("can_left_alone", "Please select an option");
      return
    }
    else {
      clearError("can_left_alone");
  }
    if(can_left_alone === "Yes" && !can_left_how_long){
      showError("can_left_how_long", "Please select an option");
      return
    }
    else {
      clearError("can_left_how_long");
  }
    if(can_left_alone !== "Yes" &&  !can_left_how_long_text){
      showError("can_left_how_long_text", "Please provide a reason for why your dog cannot be left alone.");
      return
    }
    else {
      clearError("can_left_how_long_text");
  }
  if(can_left_alone === "Yes" &&  can_left_how_long === "Other" && !can_left_how_long_text){
    showError("can_left_how_long_text", "Please specify how long your dog can be left alone.");
    return
  }
  else {
    clearError("can_left_how_long_text");
}
    if(!reaction_toward_children ){
      showError("reaction_toward_children", "Please provide details about your dog's reaction towards children");
      return
    }
    else {
      clearError("reaction_toward_children");
  }
    if(!reaction_toward_stranger ){
      showError("reaction_toward_stranger", "Please provide details about your dog's reaction towards strangers");
      return
    }
    else {
      clearError("reaction_toward_stranger");
  }
    if(!is_toilet_trained ){
      showError("is_toilet_trained", "Please select an option");
      return
    }
    else {
      clearError("is_toilet_trained");
  }
    if(is_toilet_trained !== "Yes" && !is_toilet_trained_text){
      showError("is_toilet_trained_text", "Please provide details about your dog's toilet training");
      return
    }
    else {
      clearError("is_toilet_trained_text");
  }
    if(!is_aggressive ){
      showError("is_aggressive", "Please select an option");
      return
    }
    else {
      clearError("is_aggressive");
  }
    if(is_aggressive !== "No" && !is_aggressive_text){ 
      showError("is_aggressive_text", "Please provide details about your dog's aggressive behavior");
      return
    }
    else {
      clearError("is_aggressive_text");
  }
    if(!is_vaccinated ){
      showError("is_vaccinated", "Please select an option");
      return
    }
    else {
      clearError("is_vaccinated");
  }
    if(is_vaccinated !== "Yes" && !is_vaccinated_text){
      showError("is_vaccinated_text", "Please provide details about your dog's vaccination status");
      return
    }
      else {
      clearError("is_vaccinated_text");
  }
  
     
  
    updateDogdetailById(
      {
        name,
        breed,
        gender,
        dob,
        is_neutered,
        age_category,
        size_category,
        is_friendly_with_other_dogs,
        is_friendly_with_other_dogs_text,
        is_comfortable_around_cat,
        is_comfortable_around_cat_text,
        chew_or_damage_things,
        chew_or_damage_things_text,
        can_left_alone,
        can_left_how_long,
        can_left_how_long_text,
        reaction_toward_children,
        reaction_toward_stranger,
        is_toilet_trained,
        is_toilet_trained_text,
        is_aggressive,
        is_aggressive_text,
        is_vaccinated,
        is_vaccinated_text,
        additional_info,
      },
      dogId
    ).then((res) => {
      if (res.data.response_code === 80) {
        navigate("/dashboard/dog");
      }
    });
  };
  

  const handleUpdateDogImages = async () => {
    const imgUrls = []
    let formData = new FormData()

      images.map((res) => {
        formData.append('image', res)
      })
      images && await ImageUpload(formData).then((res) => {
        const data = res.data.data.url
        const list = data.map((url) => { return url })
        editFilterImages && editFilterImages.length > 0 ? imgUrls.push(editFilterImages, list) : imgUrls.push(list)
      });
      await updateDogdetailById({
        photos: imgUrls.flat(),
      }, dogId).then((res) => {
        if (res.data.response_code == 80) {
          navigate('/dashboard/dog')
        }
      })
  }

  return (
    <Box sx={{ display: "grid", gridTemplateRows: "auto 1fr", height: "100vh" }}>
  <Grid item sx={{ position: "sticky", top: 0, zIndex: 10 }}>
        <Masthead />
      </Grid>
        <Box container component="main" sx={{ flexGrow: 1, overflowY: "auto" ,scrollbarWidth: "none", /* Firefox */
                              "&::-webkit-scrollbar": { display: "none" } /* Chrome, Safari, Edge */}}>
            <Box className="view-dog-container">
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box className="wrap-back-btn" onClick={() => { navigate('/dashboard/dog') }}>{BackBtn()} Back</Box>
          <Box className="wrap-back-btn" style={{color: '#f48220', marginLeft: "5px"}} onClick={() => { navigate('/dashboard') }}> /Home</Box>
          <Typography className="view-dog-heading" sx={{ mt: 5, mb: 5 }}>
            Edit Dog Details
          </Typography>
        </Box>

        <Box>
          <Typography
            fontFamily="Brandon"
            sx={{
              width: "100%",
              mb: 1,
              fontSize: "22px",
              textDecoration: "underline",
            }}
          >
            Basic Information
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} sx={{ display: "block" }}>
              <Grid item xs={12}>
                <FormInput fullWidth variant="standard">
                  <CustomLabelInput shrink="true">Dog Name <span style={{color: 'red', fontSize: '20px'}}>*</span></CustomLabelInput>
                  <InputField
                    className="disabled-field"
                    type="text"
                    size="small"
                    placeholder="Enter Name"
                    ref={(el) => (inputRefs.current.name = el)}

                    value={name}
                    onChange={(e) => {
                      let newValue = e.target.value.replace(/[^A-Za-z\s]/g, ''); 
                      setSnackbar((prev) => ({
                        ...prev,
                        isOpen:  newValue.length > 10, 
                        message:  newValue.length > 10 
                            ? "Dog name cannot exceed 10 characters." 
                            : "",
                        type: 'error'
                      }));
                        setDogFormData({ ...dogFormData, name: newValue.slice(0, 10) });
                      }}
                  />
                </FormInput>
              </Grid>
              <Grid item xs={12}>
                <FormInput fullWidth variant="standard">
                  <CustomLabelInput shrink="true">Dog Breed <span style={{color: 'red', fontSize: '20px'}}>*</span></CustomLabelInput>
                  <InputField
                  className="disabled-field"
                    type="text"
                    size="small"
                    placeholder="Enter "
                    value={breed}
                    ref={(el) => (inputRefs.current.breed = el)}

                    onChange={(e) => {
                      const newValue = e.target.value.replace(/[^A-Za-z\s]/g, ''); 
                      setDogFormData({ ...dogFormData, breed: newValue })
                    }}
                  />
                </FormInput>
              </Grid>
              <Grid item xs={12}>
                <FormInput fullWidth variant="standard">
                  <CustomLabelInput shrink="true">Dog Gender <span style={{color: 'red', fontSize: '20px'}}>*</span></CustomLabelInput>
                  <NativeSelect
                  className="disabled-field"
                    disableUnderline
                    defaultValue=""
                    value={gender}
                    ref={(el) => (inputRefs.current.gender = el)}

                    onChange={(e) =>
                      setDogFormData({ ...dogFormData, gender: e.target.value })
                    }
                    inputProps={{
                      style: {
                        fontFamily: "Brandon",
                        padding: "8.5px 14px",
                      },
                    }}
                    sx={{
                      border: "1px solid #ced4d9",
                      borderRadius: "3px",
                      marginTop: "5px",
                    }}
                  >
                    {" "}
                    <option selected disabled value="">
                      Select Gender
                    </option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                  </NativeSelect>
                </FormInput>
              </Grid>
              <Grid item xs={12}>
                <FormInput fullWidth variant="standard">
                  <CustomLabelInput shrink="true" htmlFor="date_of_birth">
                    Date of Birth <span style={{color: 'red', fontSize: '20px'}}>*</span>
                  </CustomLabelInput>
                  <InputField
                  className="disabled-field"
                    type="date"
                    size="small"
                    placeholder="Enter DOB"
                    value={dob}
                    ref={(el) => (inputRefs.current.dob = el)}

                    onChange={(e) =>
                      setDogFormData({ ...dogFormData, dob: e.target.value })
                    }
                  />
                </FormInput>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6} sx={{ display: "block" }}>
              <Grid item xs={12}>
                <FormInput fullWidth variant="standard">
                  <CustomLabelInput shrink="true">Neutered <span style={{color: 'red', fontSize: '20px'}}>*</span></CustomLabelInput>
                  <NativeSelect
                  className="disabled-field"
                    disableUnderline
                    defaultValue=""
                    value={is_neutered}
                    ref={(el) => (inputRefs.current.is_neutered = el)}

                    onChange={(e) =>
                      setDogFormData({
                        ...dogFormData,
                        is_neutered: e.target.value,
                      })
                    }
                    inputProps={{
                      style: {
                        fontFamily: "Brandon",
                        padding: "8.5px 14px",
                      },
                    }}
                    sx={{
                      border: "1px solid #ced4d9",
                      borderRadius: "3px",
                      marginTop: "5px",
                    }}
                  >
                    {" "}
                    <option selected disabled value="">
                      Select
                    </option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </NativeSelect>
                </FormInput>
              </Grid>
              <Grid item xs={12}>
                <FormInput fullWidth variant="standard">
                  <CustomLabelInput shrink="true" htmlFor=" ">
                    What age category is your dog? <span style={{color: 'red', fontSize: '20px'}}>*</span>
                  </CustomLabelInput>
                  <NativeSelect
                  className="disabled-field"
                    disableUnderline
                    defaultValue=""
                    ref={(el) => (inputRefs.current.age_category = el)}

                    value={age_category}
                    onChange={(e) =>
                      setDogFormData({
                        ...dogFormData,
                        age_category: e.target.value,
                      })
                    }
                    inputProps={{
                      style: {
                        fontFamily: "Brandon",
                        padding: "8.5px 14px",
                      },
                    }}
                    sx={{
                      border: "1px solid #ced4d9",
                      borderRadius: "3px",
                      marginTop: "5px",
                    }}
                  >
                    {" "}
                    <option selected disabled value="">
                      Select
                    </option>
                    <option value="young_puppy">
                      Very Young Puppy (less than 1 year old)
                    </option>
                    <option value="puppy">Puppy (less than 2 years old)</option>
                    <option value="adult_dog">
                      Adult dog (over 2 years old)
                    </option>
                    <option value="senior_dog">
                      Senior dog (above 7 years old)
                    </option>
                  </NativeSelect>
                </FormInput>
              </Grid>
              <Grid item xs={12}>
                <FormInput fullWidth variant="standard">
                  <CustomLabelInput shrink="true" htmlFor=" ">
                    What size category is your dog? <span style={{color: 'red', fontSize: '20px'}}>*</span>
                  </CustomLabelInput>
                  <NativeSelect
                  className="disabled-field"
                    disableUnderline
                    defaultValue=""
                    value={size_category}
                    ref={(el) => (inputRefs.current.size_category = el)}

                    onChange={(e) =>
                      setDogFormData({
                        ...dogFormData,
                        size_category: e.target.value,
                      })
                    }
                    inputProps={{
                      style: {
                        fontFamily: "Brandon",
                        padding: "8.5px 14px",
                      },
                    }}
                    sx={{
                      border: "1px solid #ced4d9",
                      borderRadius: "3px",
                      marginTop: "5px",
                    }}
                  >
                    {" "}
                    <option selected disabled value="">
                      Select
                    </option>
                    <option value="small">Small (less than 14 kilos)</option>
                    <option value="medium">
                      Medium (between 14 and 25 kilos)
                    </option>
                    <option value="large">Large (more than 25 kilos)</option>
                  </NativeSelect>
                </FormInput>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Box>
          <Typography
            fontFamily="Brandon"
            sx={{
              width: "100%",
              mb: 1,
              mt: 3,
              fontSize: "22px",
              textDecoration: "underline",
            }}
          >
            Additional Information
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} sx={{ display: "block" }}>
              <Grid item xs={12}>
                <FormInput fullWidth variant="standard">
                  <CustomLabelInput shrink="true" htmlFor=" ">
                    Is your dog friendly with other dogs? <span style={{color: 'red', fontSize: '20px'}}>*</span>
                  </CustomLabelInput>
                  <NativeSelect
                  className="disabled-field"
                    disableUnderline
                    defaultValue=""
                    value={is_friendly_with_other_dogs}
                    ref={(el) => {
                      if (el) inputRefs.current.is_friendly_with_other_dogs = el;
                    }}
                    onChange={(e) =>
                      setDogFormData({
                        ...dogFormData,
                        is_friendly_with_other_dogs: e.target.value,
                      })
                    }
                    inputProps={{
                      style: {
                        fontFamily: "Brandon",
                        padding: "8.5px 14px",
                      },
                    }}
                    sx={{
                      border: "1px solid #ced4d9",
                      borderRadius: "3px",
                      marginTop: "5px",
                    }}
                  >
                    <option selected disabled value="">
                      Select
                    </option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                    <option value="other">It Depends</option>
                  </NativeSelect>
                </FormInput>
              </Grid>

              {is_friendly_with_other_dogs && (
                <Grid item xs={12}>
                  <FormInput fullWidth variant="standard">
                    <CustomLabelInput shrink="true">
                      Please share additional details here:
                    </CustomLabelInput>
                    <InputTeaxtArea
                    className="disabled-field"
                      size="small"
                      placeholder="Enter Details"
                      minRows={2}
                      sx={{
                        fontFamily: "Brandon",
                        height: "40px",
                        fontSize: "1rem",
                        border: "1px solid #ced4d9",
                        borderRadius: "3px",
                        padding: "8.5px 14px",
                        marginTop: "5px",
                      }}
                      ref={(el) => (inputRefs.current.is_friendly_with_other_dogs_text = el)}

                      value={is_friendly_with_other_dogs_text}
                      onChange={(e) =>
                        setDogFormData({
                          ...dogFormData,
                          is_friendly_with_other_dogs_text: e.target.value,
                        })
                      }
                    />
                  </FormInput>
                </Grid>
              )}
              <Grid item xs={12}>
                <FormInput fullWidth variant="standard">
                  <CustomLabelInput shrink="true">
                    Is your dog comfortable around cats? <span style={{color: 'red', fontSize: '20px'}}>*</span>
                  </CustomLabelInput>
                  <NativeSelect
                  className="disabled-field"
                    disableUnderline
                    defaultValue=""
                    value={is_comfortable_around_cat}
                    ref={(el) => (inputRefs.current.is_comfortable_around_cat = el)}

                    onChange={(e) =>
                      setDogFormData({
                        ...dogFormData,
                        is_comfortable_around_cat: e.target.value,
                      })
                    }
                    inputProps={{
                      style: {
                        fontFamily: "Brandon",
                        padding: "8.5px 14px",
                      },
                    }}
                    sx={{
                      border: "1px solid #ced4d9",
                      borderRadius: "3px",
                      marginTop: "5px",
                    }}
                  >
                    <option selected disabled value="">
                      Select
                    </option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                    <option value="other">Not Sure</option>
                  </NativeSelect>
                </FormInput>
              </Grid>
              {is_comfortable_around_cat && (
                <Grid item xs={12}>
                  <FormInput fullWidth variant="standard">
                    <CustomLabelInput shrink="true">
                      Please share additional details here:
                    </CustomLabelInput>
                    <InputTeaxtArea
                    className="disabled-field"
                      size="small"
                      placeholder="Enter Details"
                      minRows={2}
                      sx={{
                        fontFamily: "Brandon",
                        height: "40px",
                        fontSize: "1rem",
                        border: "1px solid #ced4d9",
                        borderRadius: "3px",
                        padding: "8.5px 14px",
                        marginTop: "5px",
                      }}
                      ref={(el) => (inputRefs.current.is_comfortable_around_cat_text = el)}

                      value={is_comfortable_around_cat_text}
                      onChange={(e) =>
                        setDogFormData({
                          ...dogFormData,
                          is_comfortable_around_cat_text: e.target.value,
                        })
                      }
                    />
                  </FormInput>
                </Grid>
              )}
              <Grid item xs={12}>
                <FormInput fullWidth variant="standard">
                  <CustomLabelInput shrink="true">
                    Does your dog chew or damage things? <span style={{color: 'red', fontSize: '20px'}}>*</span>
                  </CustomLabelInput>
                  <NativeSelect
                  className="disabled-field"
                    disableUnderline
                    defaultValue=""
                    value={chew_or_damage_things}
                    ref={(el) => (inputRefs.current.chew_or_damage_things = el)}

                    onChange={(e) =>
                      setDogFormData({
                        ...dogFormData,
                        chew_or_damage_things: e.target.value,
                      })
                    }
                    inputProps={{
                      style: {
                        fontFamily: "Brandon",
                        padding: "8.5px 14px",
                      },
                    }}
                    sx={{
                      border: "1px solid #ced4d9",
                      borderRadius: "3px",
                      marginTop: "5px",
                    }}
                  >
                    <option selected disabled value="">
                      Select
                    </option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                    <option value="other">others</option>
                  </NativeSelect>
                </FormInput>
              </Grid>
              {chew_or_damage_things && (
                <Grid item xs={12}>
                  <FormInput fullWidth variant="standard">
                    <CustomLabelInput shrink="true">
                      Please share additional details here:
                    </CustomLabelInput>
                    <InputTeaxtArea
                    className="disabled-field"
                      size="small"
                      placeholder="Enter Details"
                      minRows={2}
                      ref={(el) => (inputRefs.current.chew_or_damage_things_text = el)}

                      sx={{
                        fontFamily: "Brandon",
                        height: "40px",
                        fontSize: "1rem",
                        border: "1px solid #ced4d9",
                        borderRadius: "3px",
                        padding: "8.5px 14px",
                        marginTop: "5px",
                      }}
                      value={chew_or_damage_things_text}
                      onChange={(e) =>
                        setDogFormData({
                          ...dogFormData,
                          chew_or_damage_things_text: e.target.value,
                        })
                      }
                    />
                  </FormInput>
                </Grid>
              )}
              <Grid item xs={12}>
                <FormInput fullWidth variant="standard">
                  <CustomLabelInput shrink="true" htmlFor=" ">
                    Can your dog be left alone at home for some time?
                  </CustomLabelInput>
                  <NativeSelect
                  className="disabled-field"
                    disableUnderline
                    defaultValue=""
                    ref={(el) => (inputRefs.current.can_left_alone = el)}

                    value={can_left_alone}
                    onChange={(e) =>
                      setDogFormData({
                        ...dogFormData,
                        can_left_alone: e.target.value,
                      })
                    }
                    inputProps={{
                      style: {
                        fontFamily: "Brandon",
                        padding: "8.5px 14px",
                      },
                    }}
                    sx={{
                      border: "1px solid #ced4d9",
                      borderRadius: "3px",
                      marginTop: "5px",
                    }}
                  >
                    <option selected disabled value="">
                      Select
                    </option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                    <option value="other">others</option>
                  </NativeSelect>
                </FormInput>
              </Grid>
              {can_left_alone &&  can_left_alone === "Yes" && (
                <Grid item xs={12}>
                  <FormInput fullWidth variant="standard">
                    <CustomLabelInput shrink="true">
                      For how long? <span style={{color: 'red'}}>*</span>
                    </CustomLabelInput>
                    <NativeSelect
                    className="disabled-field"
                      disableUnderline
                      defaultValue=""
                      value={can_left_how_long}
                      ref={(el) => (inputRefs.current.can_left_how_long = el)}

                      onChange={(e) =>
                        setDogFormData({
                          ...dogFormData,
                          can_left_how_long: e.target.value,
                        })
                      }
                      inputProps={{
                        style: {
                          fontFamily: "Brandon",
                          padding: "8.5px 14px",
                        },
                      }}
                      sx={{
                        border: "1px solid #ced4d9",
                        borderRadius: "3px",
                        marginTop: "5px",
                      }}
                    >
                      {" "}
                      <option selected disabled value="">
                        Select
                      </option>
                      1-2 hours, 2-4 hours, 4-6 hours, 6-8 hours, As long as
                      needed, Other
                      <option value="1-2 hours">1-2 hours</option>
                      <option value="2-4 hours">2-4 hours</option>
                      <option value="4-6 hours"> 4-6 hours</option>
                      <option value="6-8 hours"> 6-8 hours</option>
                      <option value="As long as needed">
                        {" "}
                        As long as needed
                      </option>
                      <option value="Other"> other</option>
                    </NativeSelect>
                  </FormInput>
                </Grid>
              )}
              {can_left_alone && 
                <Grid item xs={12} md={10}>
                <FormInput fullWidth variant="standard">
                  <CustomLabelInput
                    shrink="true"
                  >
                    Any additional details?
                  </CustomLabelInput>
                  <InputTeaxtArea
                    className="disabled-field"
                    size="small"
                    ref={(el) => (inputRefs.current.can_left_how_long_text = el)}

                    placeholder="Enter Details"
                    minRows={2}
                    sx={{fontFamily: 'Brandon', height:"40px", fontSize: "1rem", border: "1px solid #ced4d9", borderRadius:'3px', padding: "8.5px 14px", marginTop:"5px"}}
                    value={can_left_how_long_text}
                    onChange={(e) => setDogFormData({...dogFormData, can_left_how_long_text: e.target.value})}
                  />
                </FormInput>
              </Grid>
              }
            </Grid>
            <Grid item xs={12} md={6} sx={{ display: "block" }}>
              <Grid item xs={12}>
                <FormInput fullWidth variant="standard">
                  <CustomLabelInput shrink="true" htmlFor="street_name">
                    How does your dog react to children? <span style={{color: 'red', fontSize: '20px'}}>*</span>
                  </CustomLabelInput>
                  <InputTeaxtArea
                  className="disabled-field"
                    maxRows={2}
                    value={reaction_toward_children}
                    ref={(el) => (inputRefs.current.reaction_toward_children = el)}

                    onChange={(e) =>
                      setDogFormData({
                        ...dogFormData,
                        reaction_toward_children: e.target.value,
                      })
                    }
                    size="small"
                    placeholder="Enter Details"
                    sx={{
                      fontFamily: "Brandon",
                      height: "40px",
                      fontSize: "1rem",
                      border: "1px solid #ced4d9",
                      borderRadius: "3px",
                      padding: "8.5px 14px",
                      marginTop: "5px",
                    }}
                  />
                </FormInput>
              </Grid>
              <Grid item xs={12}>
                <FormInput fullWidth variant="standard">
                  <CustomLabelInput shrink="true" htmlFor="street_name">
                    How does your dog react to strangers? <span style={{color: 'red', fontSize: '20px'}}>*</span>
                  </CustomLabelInput>
                  <InputTeaxtArea
                  className="disabled-field"
                  ref={(el) => (inputRefs.current.reaction_toward_stranger = el)}

                    maxRows={2}
                    size="small"
                    value={reaction_toward_stranger}
                    onChange={(e) =>
                      setDogFormData({
                        ...dogFormData,
                        reaction_toward_stranger: e.target.value,
                      })
                    }
                    placeholder="Enter Details"
                    sx={{
                      fontFamily: "Brandon",
                      height: "40px",
                      fontSize: "1rem",
                      border: "1px solid #ced4d9",
                      borderRadius: "3px",
                      padding: "8.5px 14px",
                      marginTop: "5px",
                    }}
                  />
                </FormInput>
              </Grid>
              <Grid item xs={12}>
                <FormInput fullWidth variant="standard">
                  <CustomLabelInput shrink="true" htmlFor=" ">
                    Is your dog completely toilet trained? <span style={{color: 'red', fontSize: '20px'}}>*</span>
                  </CustomLabelInput>
                  <NativeSelect
                  className="disabled-field"
                    disableUnderline
                    defaultValue=""
                    value={is_toilet_trained}
                    ref={(el) => (inputRefs.current.is_toilet_trained = el)}

                    onChange={(e) =>
                      setDogFormData({
                        ...dogFormData,
                        is_toilet_trained: e.target.value,
                      })
                    }
                    inputProps={{
                      style: {
                        fontFamily: "Brandon",
                        padding: "8.5px 14px",
                      },
                    }}
                    sx={{
                      border: "1px solid #ced4d9",
                      borderRadius: "3px",
                      marginTop: "5px",
                    }}
                  >
                    <option selected disabled value="">
                      Select
                    </option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                    <option value="other">others</option>
                  </NativeSelect>
                </FormInput>
              </Grid>
              {is_toilet_trained && (
                <Grid item xs={12}>
                  <FormInput fullWidth variant="standard">
                    <CustomLabelInput shrink="true">
                      Please share additional details here:
                    </CustomLabelInput>
                    <InputTeaxtArea
                    className="disabled-field"
                      size="small"
                      placeholder="Enter Details"
                      minRows={2}
                      sx={{
                        fontFamily: "Brandon",
                        height: "40px",
                        fontSize: "1rem",
                        border: "1px solid #ced4d9",
                        borderRadius: "3px",
                        padding: "8.5px 14px",
                        marginTop: "5px",
                      }}
                      value={is_toilet_trained_text}
                      ref={(el) => (inputRefs.current.is_toilet_trained_text = el)}

                      onChange={(e) =>
                        setDogFormData({
                          ...dogFormData,
                          is_toilet_trained_text: e.target.value,
                        })
                      }
                    />
                  </FormInput>
                </Grid>
              )}
              <Grid item xs={12}>
                <FormInput fullWidth variant="standard">
                  <CustomLabelInput shrink="true" htmlFor=" ">
                    Is your dog aggressive in any situation? <span style={{color: 'red', fontSize: '20px'}}>*</span>
                  </CustomLabelInput>
                  <NativeSelect
                  className="disabled-field"
                    disableUnderline
                    defaultValue=""
                    value={is_aggressive}
                    ref={(el) => (inputRefs.current.is_aggressive = el)}

                    onChange={(e) =>
                      setDogFormData({
                        ...dogFormData,
                        is_aggressive: e.target.value,
                      })
                    }
                    inputProps={{
                      style: {
                        fontFamily: "Brandon",
                        padding: "8.5px 14px",
                      },
                    }}
                    sx={{
                      border: "1px solid #ced4d9",
                      borderRadius: "3px",
                      marginTop: "5px",
                    }}
                  >
                    <option selected disabled value="">
                      Select
                    </option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                    <option value="other">others</option>
                  </NativeSelect>
                </FormInput>
              </Grid>
              {is_aggressive && (
                <Grid item xs={12}>
                  <FormInput fullWidth variant="standard">
                    <CustomLabelInput shrink="true">
                      Please share additional details here:
                    </CustomLabelInput>
                    <InputTeaxtArea
                    className="disabled-field"
                    ref={(el) => (inputRefs.current.is_aggressive_text = el)}

                      size="small"
                      placeholder="Enter Details"
                      minRows={2}
                      sx={{
                        fontFamily: "Brandon",
                        height: "40px",
                        fontSize: "1rem",
                        border: "1px solid #ced4d9",
                        borderRadius: "3px",
                        padding: "8.5px 14px",
                        marginTop: "5px",
                      }}
                      value={is_aggressive_text}
                      onChange={(e) =>
                        setDogFormData({
                          ...dogFormData,
                          is_aggressive_text: e.target.value,
                        })
                      }
                    />
                  </FormInput>
                </Grid>
              )}
              <Grid item xs={12}>
                <FormInput fullWidth variant="standard">
                  <CustomLabelInput shrink="true" htmlFor=" ">
                    Is your dog fully vaccinated? <span style={{color: 'red', fontSize: '20px'}}>*</span>
                  </CustomLabelInput>
                  <NativeSelect
                  className="disabled-field"
                    disableUnderline
                    defaultValue=""
                    ref={(el) => (inputRefs.current.is_vaccinated = el)}

                    value={is_vaccinated}
                    onChange={(e) =>
                      setDogFormData({
                        ...dogFormData,
                        is_vaccinated: e.target.value,
                      })
                    }
                    inputProps={{
                      style: {
                        fontFamily: "Brandon",
                        padding: "8.5px 14px",
                      },
                    }}
                    sx={{
                      border: "1px solid #ced4d9",
                      borderRadius: "3px",
                      marginTop: "5px",
                    }}
                  >
                    <option selected disabled value="">
                      Select
                    </option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                    <option value="other">others</option>
                  </NativeSelect>
                </FormInput>
              </Grid>
              {is_vaccinated && (
                <Grid item xs={12}>
                  <FormInput fullWidth variant="standard">
                    <CustomLabelInput shrink="true">
                      Please share additional details here:
                    </CustomLabelInput>
                    <InputTeaxtArea
                    className="disabled-field"
                      size="small"
                      placeholder="Enter Details"
                      minRows={2}
                      sx={{
                        fontFamily: "Brandon",
                        height: "40px",
                        fontSize: "1rem",
                        border: "1px solid #ced4d9",
                        borderRadius: "3px",
                        padding: "8.5px 14px",
                        marginTop: "5px",
                      }}
                      value={is_vaccinated_text}
                      ref={(el) => (inputRefs.current.is_vaccinated_text = el)}
                      onChange={(e) =>
                        setDogFormData({
                          ...dogFormData,
                          is_vaccinated_text: e.target.value,
                        })
                      }
                    />
                  </FormInput>
                </Grid>
              )}
            </Grid>
            <Grid item xs={12}>
              <FormInput fullWidth variant="standard">
                <CustomLabelInput shrink="true">
                  Is there anything else you would like us or the dog sitter to
                  know?
                </CustomLabelInput>
                <InputTeaxtArea
                className="disabled-field"
                  minRows={2}
                  sx={{
                    fontFamily: "Brandon",
                    height: "40px",
                    fontSize: "1rem",
                    border: "1px solid #ced4d9",
                    borderRadius: "3px",
                    padding: "8.5px 14px",
                    marginTop: "5px",
                  }}
                  size="small"
                  placeholder="Enter Details"
                  value={additional_info}
                  onChange={(e) =>
                    setDogFormData({
                      ...dogFormData,
                      additional_info: e.target.value,
                    })
                  }
                />
              </FormInput>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ textAlign: 'center' }}>
        <Button className="default-btn" variant="contained" onClick={handleUpdateDogFormDetails} sx={{ mt: 3, mb: 5, width: "25%" }}>Update Data</Button>
      </Box>
        <Typography
          fontFamily="Brandon"
          sx={{
            width: "100%",
            mb: 1,
            mt: 3,
            fontSize: "22px",
            textDecoration: "underline",
          }}
        >
          Uploads
        </Typography>
        {<EditMultiUpload
          text='Please upload at least one and maximum 3 pictures of your dog. We need these to find you
            the right dog sitter. (accepted formats: JPEG, JPG, PNG and file size: up to 5 MB each)'
          imageAssets={(res) => { setImages(res) }}
          filteredEditImg={(res) => setEditFilterImages(res)}
          uploadedImages={photos}
        />}
      </Box>
      <Box sx={{ textAlign: 'center' }}>
        <Button className="default-btn" variant="contained" onClick={handleUpdateDogImages} sx={{ mt: 3, mb: 5, width: "25%" }}>Update Images</Button>
      </Box></Box>
      <SnackBar
        open={snackbar.isOpen}
        handleClose={() => setSnackbar({ ...snackbar, isOpen: false })}
        duration={300000}
        msg={snackbar.message}
        type={snackbar.type}
      />
    </Box>

  );
}

export default EditDog