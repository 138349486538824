import React from 'react'
import Masthead from '../../../../../../Components/Masthead'
import BookingRequestForm from '../../../../../../Components/BookingRequestForm'
import '../style.css'
import { Box, Grid } from '@mui/material'

const CreateBookingRequest = () => {
    return(
                <Box sx={{ display: "grid", gridTemplateRows: "auto 1fr", height: "100vh" }}>
        
<Grid item sx={{ position: "sticky", top: 0, zIndex: 10 }}>
            <Masthead />
          </Grid>        
           <Box container component="main" sx={{ flexGrow: 1, overflowY: "auto" ,scrollbarWidth: "none", /* Firefox */
                                                                "&::-webkit-scrollbar": { display: "none" } /* Chrome, Safari, Edge */}}>       
            <Box className='new-booking-container'>
        <BookingRequestForm/>
        </Box>
        </Box>
        </Box>
    )
}

export default CreateBookingRequest