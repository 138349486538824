import React, { useEffect, useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { assests } from "../../../../Assets/assets";
import { Copyright, CustomLabelInput, DividerRoot, FormInput, InputField } from "../../../../Components/CustomElements";
import { GoogleAuth } from "../../../../Components/GoogleLogin";
import { FacebookAuth } from "../../../../Components/FacebookLogin";
import { Chip, Divider } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { emailLogin } from "../../../../Utils/Api";
import { setItem } from "../../../../Utils/localStorage";
import SnackBar from "../../../../Components/Snackbar";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import RegistrationInfoDialog from "../../../../Components/Dialogs/registerInfoUser";
import { isAuthorised } from "../../../../Utils/helper";
import Masthead from "../../../../Components/Masthead";
import '../style.css'
import ForgotPasswordDialog from "../../../../Components/Dialogs/forgotPassword";

const UserLogin = () => {

  const theme = createTheme();
  const navigate = useNavigate()
  const [user, setUser] = useState({ email: '', password: ''})
  const [snackbar, setSnackbar] = useState({isOpen: false, message: "", type: ""})
  const [isInfoDialogOpen, setIsInfoDialogOpen] = useState(false)
  const [handlePassword, setHandlePassword] = useState({showPassword: false, passwordType: 'password'})
  const isRedirect = sessionStorage.getItem('isRedirected')
  const [forgotPasswordDialog, setForgotPasswordDialog] = useState(false)

  useEffect(() => {
   const path = isAuthorised()
   if(isRedirect){
    return
   }
   else{ navigate(path) }
  }, [])

  const handleSubmit = () => {
      emailLogin(user)
      .then((res) => {
        if(res.data.response_code == 80){
          setItem('token', res.data.data.token)
          setItem('AuthStore', JSON.stringify(res.data.data))
          if(res.data.data.profile_status == "pending"){
            if(res.data.data.profile_step == "mobile_verification"){
              window.location.href = '/user-verification'
            }else if(res.data.data.profile_step == "user_form"){
              window.location.href = '/user-registration'
            }else if(res.data.data.profile_step == "add_dog"){
              window.location.href = '/dog-registration'
            }
          }
          if(res.data.data.profile_status == "completed"){
            // navigate('/dashboard')
            if(isRedirect) { 
              window.location.href = isRedirect
              sessionStorage.clear()
            }
            else window.location.href = '/dashboard'
          }
        } else if(res.data.response_code == 90){
          setSnackbar({...snackbar, isOpen: true, message: `${res.data.developer_message}`, type: 'error'})
        } else if(res.data.response_code == 91){
          setIsInfoDialogOpen(true)
        }
      })
  }

  const handleChange = (e) => {
    setUser({
      ...user,
      [e.target.name]: e.target.value
    })
  }


  return (
    <>
    <ThemeProvider theme={theme}>
    <Grid sx={{ display: "grid", gridTemplateRows: "auto 1fr", height: "100vh" }}>
    <Grid item sx={{ position: "sticky", top: 0, zIndex: 10 }}>
        <Masthead />
      </Grid>
      <Grid container component="main" sx={{ flexGrow: 1, overflowY: "auto" ,scrollbarWidth: "none", /* Firefox */
    "&::-webkit-scrollbar": { display: "none" } /* Chrome, Safari, Edge */}}>
        <CssBaseline />
        <Grid item xs={false} sm={4} md={6} sx={{
            backgroundImage: `url(${assests.newBgImg})`,
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
            height:"100vh"

          }}
        >
          <Grid className="welcome-wrapper">
            <p className="welcome-text">Welcome to Dogstays</p>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={8} md={6} component={Paper} elevation={6} sx={{boxShadow: 'none'}}>
          <Box
            sx={{
              my: 1,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "#fe7648", border: '1px solid #80808075' }}></Avatar>

            <Typography component="h1" fontFamily="monopola" variant="h5">
              Login
            </Typography>

            <Box
              component="form"
              className="form-container"
              noValidate
              sx={{
                mt: 1,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <FormInput fullWidth variant="standard">
                <CustomLabelInput shrink='true'>
                  Email Address
                </CustomLabelInput>
                <InputField
                  name="email"
                  type="text"
                  size="small"
                  placeholder="Enter Email"
                  value={user.email}
                  onChange={handleChange}
                />
              </FormInput>

              <FormInput fullWidth variant="standard">
                <CustomLabelInput shrink='true'>
                  Password
                </CustomLabelInput>
                <InputField
                  name="password"
                  type={handlePassword.passwordType}
                  size="small"
                  placeholder="Enter Password"
                  value={user.password}
                  onChange={handleChange}
                  sx={{position: 'relative'}}
                />
                <Box sx={{position: 'absolute', bottom: '0px', right: '10px'}}>
                  {!handlePassword.showPassword ? 
                  <VisibilityIcon sx={{color: '#fe7648', cursor: 'pointer'}} onClick={() => setHandlePassword({...handlePassword, showPassword: true, passwordType: 'text'})}/>
                   : <VisibilityOffIcon sx={{color: '#fe7648', cursor: 'pointer'}} onClick={() => setHandlePassword({...handlePassword, showPassword: false, passwordType: 'password'})}/>}
                </Box>
              </FormInput>

              <Button
                className="default-btn"
                variant="contained"
                sx={{ mt: 3, mb: 2, width: "75%" }}
                onClick={handleSubmit}
              >
                Login
              </Button>

              <Grid container>
                <Grid item xs>
                  <Link fontFamily="brandon" color="#fe7648" onClick={() => setForgotPasswordDialog(true)}>
                    Forgot password?
                  </Link>
                </Grid>
              </Grid>

              <DividerRoot sx={{marginTop: "25px"}}>
                <Divider>
                  <Chip label="Login With" />
                </Divider>
              </DividerRoot> 

              {/* <Grid container sx={{ mt: 3 }}> */}
                {/* <Grid item xs={6} sx={{ pr: 2 }}> */}
                  <Button variant="contained" onClick={GoogleAuth} sx={{width: "75%",marginTop:"16px", background:'#0f4fbd', display:'flex', gap:'10px'}}>
                  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="18px" height="18px" viewBox="0 0 48 48" class="abcRioButtonSvg"><g><path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path><path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path><path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path><path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"></path><path fill="none" d="M0 0h48v48H0z"></path></g></svg>
                  Google
                  </Button>
                {/* </Grid> */}
                {/* <Grid item xs={6} sx={{ pr: 2 }}>
                  <Button variant="contained" onClick={FacebookAuth} sx={{ width: "100%", background:'#4267B2'}}>
                  Facebook
                  </Button>
                </Grid> */}
              {/* </Grid> */}

              <DividerRoot sx={{marginTop: "25px"}}>
                <Divider>
                  <Chip label="New User" />
                </Divider>
              </DividerRoot> 

              <Grid item xs={12} sx={{ marginTop: "25px"}}>
                  <Button variant="contained" onClick={() => navigate('/register')} className="default-btn" sx={{width: "120px"}}>
                  Start Here
                  </Button>
                </Grid>
              <Copyright sx={{ mt: 5 , position: 'fixed', bottom: '20px', right: '20px'}} />
            </Box>  
          </Box>
        </Grid>
      </Grid>
      </Grid>
    </ThemeProvider>
   
      <RegistrationInfoDialog isOpen={isInfoDialogOpen} handleCloseDialog={() => setIsInfoDialogOpen(false)}/>
       <SnackBar    
        open={snackbar.isOpen}
        handleClose={() => setSnackbar({ ...snackbar, isOpen: false })}
        duration={300000}
        msg={snackbar.message}
        type={snackbar.type}
      />
      {forgotPasswordDialog && <ForgotPasswordDialog
        isOpen={forgotPasswordDialog}
        handleCloseDialog={() => setForgotPasswordDialog(false)}
      />}
    </>
  )
}

export default UserLogin
