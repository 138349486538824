import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useNavigate } from 'react-router-dom';

 const AddDogDialog = ({isOpen, handleCloseDialog}) => {
  const navigate = useNavigate();

  return (
    <div>
      <Dialog
        maxWidth='xs'
        fullWidth={true}
        open={isOpen}
        onClose={handleCloseDialog}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle sx={{fontFamily: 'Brandon', textAlign: 'center'}} id="responsive-dialog-title">
          {"Please add at least one dog"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText sx={{fontFamily: 'Brandon'}}>
          You have not added a Dog. Please add at least one dog
           <a style={{color: '#fe7648', cursor: 'pointer'}} onClick={() => navigate('/dog-registration')}> visit this link</a> to add.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button className='default-btn' onClick={handleCloseDialog}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default AddDogDialog