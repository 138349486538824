import React, { useRef } from 'react'
import Select from 'react-select'
import { Box, Checkbox, FormControlLabel, Grid, NativeSelect, Radio, RadioGroup } from "@mui/material"
import { CustomLabelInput, FormInput, InputField } from "../CustomElements"
import { useEffect, useMemo, useState } from "react"
import { getParentAddress } from "../../Utils/Api"
import countryList from "react-select-country-list"
import './style.css'
import SnackBar from '../Snackbar'
import { useLocation } from 'react-router-dom'

const AddressModule = ({data,inputRefs,activeModule}) => {
    const [differentAddress, setdifferentAddress] = useState({
      sitterLocation: {location: '', checked: false},
      parentLocation: {location: '', checked: false},
      otherLocation: {location: '', checked: false}
    })
    const { sitterLocation, parentLocation, otherLocation } = differentAddress
    const [selectedAddressData, setSelectedAddressData] = useState({
      location_type: [], //parent_location, sitter_location, other_address,
      parent_address: '', //id of address
      manual_address: {
        street_name: null,
        city: null,
        country: null,
        postal_code: null
      },
    })
      const [snackbar, setSnackbar] = useState({ isOpen : false, message: '', type: ''})
      const [ownerAddress, setOwnerAddress] = useState([])
    const countryOptions = useMemo(() => countryList().getData(), [])
    const [showAlertTxt, setShowAlertTxt] = useState(false)
    const [isDisabled, setIsDisabled] = useState({
      homeAddress: false,
      otherAddress: false
    })
    useEffect(() => {
        getParentAddress().then((res) => {setOwnerAddress(res.data.data)})
      }, [])

      useEffect(() => {
        data(selectedAddressData)
      }, [selectedAddressData])

      useEffect(() => {
        !sitterLocation.checked && !parentLocation.checked && !otherLocation.checked ?  setShowAlertTxt(true) : setShowAlertTxt(false)
      }, [differentAddress])
    return <Grid item xs={12}>
    <Grid item xs={12}>
        <FormInput fullWidth variant="standard">
            <CustomLabelInput htmlFor="hear_from_source">
            Which locations are you comfortable with for this booking? Please select all the options which apply (sitter’s home or your home/new address) <span style={{color: 'red', fontSize: '20px'}}>*</span>
            </CustomLabelInput>
            <Box 
            className={`location-options`}
            ref={(el) => (inputRefs.current.parent_address = el)}
            >
              <FormControlLabel className="checkbox-container" label="At dog sitter's home" control={<Checkbox checked={differentAddress.sitterLocation.checked} onChange={() => {
                setdifferentAddress({...differentAddress, sitterLocation:{location:'sitter_location', checked:!differentAddress.sitterLocation.checked}})
                selectedAddressData.location_type.includes('sitter_location') ? setSelectedAddressData({...selectedAddressData, location_type: selectedAddressData.location_type.filter((value) => value != 'sitter_location')}) : setSelectedAddressData({...selectedAddressData, location_type: [...selectedAddressData.location_type, 'sitter_location']})
                }}  sx={{ color: '#fe7648', '&.Mui-checked': { color: '#fe7648' }, }}/>} />
              <FormControlLabel  className="checkbox-container" label="At my home address" control={<Checkbox checked={differentAddress.parentLocation.checked} disabled={isDisabled.homeAddress} onChange={() => {
                setdifferentAddress({...differentAddress, parentLocation: {location:'parent_location', checked:!differentAddress.parentLocation.checked}})
                setIsDisabled({...isDisabled, otherAddress: !differentAddress.parentLocation.checked})
                selectedAddressData.location_type.includes('parent_location') ? setSelectedAddressData({...selectedAddressData, location_type: selectedAddressData.location_type.filter((value) => value != 'parent_location')}) : setSelectedAddressData({...selectedAddressData, location_type: [...selectedAddressData.location_type, 'parent_location']})
                }}  sx={{ color: '#fe7648', '&.Mui-checked': { color: '#fe7648' }, }}/>} />
              <FormControlLabel className="checkbox-container" label="At a new address" control={<Checkbox checked={differentAddress.otherLocation.checked} disabled={isDisabled.otherAddress} onChange={() => {
                setdifferentAddress({...differentAddress, otherLocation:{location:'other_address', checked:!differentAddress.otherLocation.checked}})
                setIsDisabled({...isDisabled, homeAddress: !differentAddress.otherLocation.checked})
                selectedAddressData.location_type.includes('other_address') ? setSelectedAddressData({...selectedAddressData, location_type: selectedAddressData.location_type.filter((value) => value != 'other_address')}) : setSelectedAddressData({...selectedAddressData, location_type: [...selectedAddressData.location_type, 'other_address']})
                }}  sx={{ color: '#fe7648', '&.Mui-checked': { color: '#fe7648' }, }}/>} />
            </Box>
            {showAlertTxt && <p style={{fontFamily: 'Brandon', fontSize: '14px', color: 'red', margin: 0}}>Please select the location type</p>}
          </FormInput>
      </Grid>
      {differentAddress.parentLocation.checked && differentAddress.parentLocation.location == "parent_location" && 
      <Grid item xs={12}  
      {...(activeModule && window.location.pathname != "/booking-request" ? { sm: 12, md: 5 } : {})}         >
        <FormInput fullWidth variant="standard">
          <CustomLabelInput shrink="true">
         Parent addresses <span style={{color: 'red', fontSize: '20px'}}>*</span>
          </CustomLabelInput>
         <NativeSelect
          disableUnderline
          defaultValue=''
          ref={(el) => (inputRefs.current.parent_address = el)}
          inputProps={{style:{fontFamily: 'Brandon', padding:'8.5px 14px'}}}
          onChange={(e) => {setSelectedAddressData({...selectedAddressData, parent_address: e.target.value})}}
          sx={{ border: "1px solid #ced4d9", borderRadius: '3px', marginTop: '5px'}}>
            <option selected disabled value=''>Select</option>
            {ownerAddress.map((item) => { 
            return  <option value={item.id}>{item.street_name + ' ' + item.city + ' ' +  item.country+ ' ' + item.postal_code}</option>
        })}
          </NativeSelect>
          </FormInput>
        </Grid>
      }
       {differentAddress.otherLocation.checked && differentAddress.otherLocation.location == "other_address" && 
        <Grid xs={12} sx={{ gap: '10px' }} {...(activeModule  && window.location.pathname !="/booking-request" ? { container: true } : {})}>
<Grid item xs={12}  {...(activeModule && window.location.pathname != "/booking-request" ? { sm: 12, md: 5 } : {})} >
<FormInput fullWidth variant="standard">
                <CustomLabelInput shrink="true" htmlFor="date_of_birth">
                Street Name and Number <span style={{color: 'red', fontSize: '20px',position:"relative"}}>*</span>
                </CustomLabelInput> 
                <InputField
                  type="text"
                  size="small"
                  value={selectedAddressData.manual_address.street_name}
                  ref={(el) => (inputRefs.current.street_name = el)}
                  onChange={(e) => {
                    let newValue = e.target.value.replace(/[^A-Za-z0-9\s]/g, ''); // Allow only letters, numbers, and spaces
                    setSnackbar((prev) => ({
                      ...prev,
                      isOpen: newValue.length >50 , 
                      message: "Street name cannot exceed 50 characters." ,
                      type: 'error'
                    }));
                    setSelectedAddressData({
                      ...selectedAddressData,
                      manual_address: { ...selectedAddressData.manual_address, street_name: newValue.slice(0, 50) },
                    });
                  }}
                                placeholder="Enter Street Name and Number"
                />

              </FormInput>
            </Grid>
            <Grid item xs={12} {...(activeModule && window.location.pathname != "/booking-request" ? { sm: 12, md: 5 } : {})}>
            <FormInput fullWidth variant="standard">
                <CustomLabelInput shrink="true" htmlFor="date_of_birth">
                Area Name + City (Example: Merl, Luxembourg City)<span style={{color: 'red', fontSize: '20px'}}>*</span>
                </CustomLabelInput> 
                <InputField
                  type="text"
                  size="small"
                  value={selectedAddressData.manual_address.city}
                  ref={(el) => (inputRefs.current.city = el)}
                  onChange={(e) => {
                    const value = e.target.value.replace(/[^A-Za-z\s]/g, ''); 
                    setSelectedAddressData({...selectedAddressData, manual_address: {...selectedAddressData.manual_address, city: value}})}                  }
                  placeholder="Enter City"
                />
              </FormInput>
            </Grid>
            <Grid item xs={12} {...(activeModule && window.location.pathname != "/booking-request" ? { sm: 12, md: 5 } : {})}>
            <FormInput fullWidth variant="standard">
                <CustomLabelInput shrink="true" htmlFor="date_of_birth">
                  Postal Code <span style={{color: 'red', fontSize: '20px'}}>*</span>
                </CustomLabelInput> 
                <InputField
                  type="text"
                  size="small"
                  ref={(el) => (inputRefs.current.postal_code = el)}
                  value={selectedAddressData.manual_address.postal_code}
                  onChange={(e) => {
                    const maxLength = 10;
                    const numericValue = e.target.value.replace(/\D/g, ""); 
                    if (numericValue.length <= maxLength) {
                      setSelectedAddressData({...selectedAddressData, manual_address: {...selectedAddressData.manual_address, postal_code: numericValue}})
                    }
                  }}
                  placeholder="Enter Postal Code"
                />
              </FormInput>
            </Grid>
            <Grid item xs={12} {...(activeModule && window.location.pathname != "/booking-request" ? { sm: 12, md: 5 } : {})}>
            <FormInput fullWidth variant="standard">
                <CustomLabelInput shrink="true" htmlFor="date_of_birth" sx={{marginBottom: '5px'}}>
                  Country <span style={{color: 'red', fontSize: '20px'}}>*</span>
                </CustomLabelInput> 
                <div                 ref={(el) => (inputRefs.current.country = el)}
                >
                <Select
                className='select-font'
                options={countryOptions}
                // value={selectedAddressData.manual_address.country}
                onChange={(e) => setSelectedAddressData({...selectedAddressData, manual_address: {...selectedAddressData.manual_address, country: e.label}})}
              />
              </div>
              </FormInput>
            </Grid>

        </Grid>}
        <SnackBar
      open={snackbar.isOpen}
      handleClose={() => setSnackbar({...snackbar, isOpen: false})}
      duration={300000}
      msg={snackbar.message}
      type={snackbar.type}
    />
    </Grid>
  }

  export default AddressModule