import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Card, Grid, NativeSelect, TextareaAutosize, Typography } from "@mui/material";
import { assests, dogSvg } from "../../../../Assets/assets";
import {
  FormInput,
  InputField,
  CustomLabelInput,
  InputTeaxtArea,
} from "../../../../Components/CustomElements";
import MultiUpload from "../../../../Components/MutipleUpload";
import { useState } from "react";
import { addDog, getDogCounts, ImageUpload } from "../../../../Utils/Api";
import { useNavigate } from "react-router-dom";
import SnackBar from "../../../../Components/Snackbar";
import { isAuthorised } from "../../../../Utils/helper";
import { setItem } from "../../../../Utils/localStorage";
import { useEffect } from "react";
import "../style.css"
import Masthead from "../../../../Components/Masthead/index"

const steps = ["Basic Information + Photos", "More About Your Dog", "Confirm"];

const DogResgistration = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [dogCount, setDogCount] = useState(0)
  const theme = createTheme();
  const navigate = useNavigate()
  const [snackbar, setSnackbar] = useState({ isOpen : false, message: '', type: ''})
  const [images, setImages] = useState([])
  const [imagesLength, setImagesLength] = useState(0)
  const [dogFormData, setDogFormData] = useState({
    name: null,
    breed: null,
    gender: null,
    photos: [],
    dob: null,
    is_neutered: null,
    age_category: null,
    size_category: null,
    is_friendly_with_other_dogs: null,
    is_friendly_with_other_dogs_text:  null,
    is_comfortable_around_cat: null,
    is_comfortable_around_cat_text:  null,
    chew_or_damage_things: null,
    chew_or_damage_things_text:  null,
    can_left_alone: null,
    can_left_how_long:  null,
    can_left_how_long_text: null,
    reaction_toward_children: null,
    reaction_toward_stranger: null,
    is_toilet_trained: null,
    is_toilet_trained_text: null,
    is_aggressive: null,
    is_aggressive_text: null,
    is_vaccinated: null,
    is_vaccinated_text:  null,
    additional_info:  null
  })
  const [highlightUpload, setHighlightUpload] = useState(false);

const inputRefs = React.useRef({
  name: null,
  breed: null,
  gender: null,
  dob:null,
  is_neutered:null,
  age_category:null,
  size_category:null,
  images:null,
  is_friendly_with_other_dogs:null,
  is_friendly_with_other_dogs_text:null,
  is_comfortable_around_cat:null,
  is_comfortable_around_cat_text:null,
  chew_or_damage_things:null,
  chew_or_damage_things_text:null,
  can_left_alone:null,
  can_left_how_long:null,
  can_left_how_long_text:null,
  reaction_toward_children:null,
  reaction_toward_stranger:null,
  is_toilet_trained:null,
  is_toilet_trained_text:null,
  is_aggressive:null,
  is_aggressive_text:null,
  is_vaccinated:null,
  is_vaccinated_text:null,
});

  const {name, breed, gender, photos, dob, is_neutered, age_category, size_category,
    is_friendly_with_other_dogs, is_friendly_with_other_dogs_text, is_comfortable_around_cat,
     is_comfortable_around_cat_text, chew_or_damage_things, chew_or_damage_things_text, can_left_alone,
      can_left_how_long, can_left_how_long_text, reaction_toward_children, reaction_toward_stranger,
       is_toilet_trained, is_toilet_trained_text, is_aggressive, is_aggressive_text, is_vaccinated, is_vaccinated_text, additional_info} = dogFormData;
  const currentDate = new Date().toISOString().substring(0,10)

  const showError = (field, message) => {
    const element = inputRefs.current[field];
  
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "center" });
      element.style.backgroundColor = "#FEF2F2"; // Red background for error
    }
  
    setSnackbar({ isOpen: true, message, type: "error" });
};

const clearError = (field) => {
    const element = inputRefs.current[field];
    if (element) {
      element.style.backgroundColor = ""; // Clear background
    }
};

  const handleNext = async () => {
    const requiredFields = [
      "name",
      "breed",
      "gender",
      "dob",
      "is_neutered",
      "age_category",
      "size_category",
      "images",
    ];

    for (const field of requiredFields) {
      if (!eval(field) || (field === "images" && images.length === 0)) {
        if (field === "images") setHighlightUpload(true);
  
        showError(field, "Oops! Looks like you have missed a question");
        return;
      }
    }
    setHighlightUpload(false);

    if (!name || name.length < 3) {
      
      showError("name", "Oops! Name must be at least 3 characters long");
      return;
    }
   
    
    if (!breed || breed.length < 3) {
      
      showError("breed", "Oops! Breed  must be at least 3 characters long");
      return;
    }
    if (!breed || breed.length > 28) {
      
      showError("breed", "Oops! Breed must be at most 28 characters long");
      return;
    }
      setActiveStep(1);

    if (activeStep === 1) {
      if (is_friendly_with_other_dogs === null) {
        showError("is_friendly_with_other_dogs", "Please select an option");
        return;
    } else {
        clearError("is_friendly_with_other_dogs");
    }
    if (is_friendly_with_other_dogs !== "Yes" && !is_friendly_with_other_dogs_text) {
      showError("is_friendly_with_other_dogs_text", "Please provide details about your dog's behavior with other dogs");
      return;
  } else {
      clearError("is_friendly_with_other_dogs_text");
  }
      if(is_comfortable_around_cat === null){
        showError("is_comfortable_around_cat", "Please select an option");
        return;
      }
      else {
        clearError("is_comfortable_around_cat");
    }
      if (is_comfortable_around_cat !== "Yes" && !is_comfortable_around_cat_text) {
        showError("is_comfortable_around_cat_text", "Please provide details about your dog's behavior with cats");
        return;
      }
      else {
        clearError("is_comfortable_around_cat_text");
    }
      if(chew_or_damage_things === null){
        showError("chew_or_damage_things", "Please select an option");
        return;
      }
      else {
        clearError("chew_or_damage_things");
    }
      if (chew_or_damage_things !== "No" && !chew_or_damage_things_text) {
        showError("chew_or_damage_things_text", "Please provide details about your dog's behavior with chewing or damaging things");
        return;
      }
      else{
        clearError("chew_or_damage_things_text");
      }
      if(can_left_alone === null){
        showError("can_left_alone", "Please select an option");
        return
      }
      else {
        clearError("can_left_alone");
    }
      if(can_left_alone === "Yes" && !can_left_how_long){
        showError("can_left_how_long", "Please select an option");
        return
      }
      else {
        clearError("can_left_how_long");
    }
      if(can_left_alone !== "Yes" &&  !can_left_how_long_text){
        showError("can_left_how_long_text", "Please provide a reason for why your dog cannot be left alone.");
        return
      }
      else {
        clearError("can_left_how_long_text");
    }
    if(can_left_alone === "Yes" &&  can_left_how_long === "Other" && !can_left_how_long_text){
      showError("can_left_how_long_text", "Please specify how long your dog can be left alone.");
      return
    }
    else {
      clearError("can_left_how_long_text");
  }
      if(reaction_toward_children === null){
        showError("reaction_toward_children", "Please select an option");
        return
      }
      else {
        clearError("reaction_toward_children");
    }
      if(reaction_toward_stranger === null){
        showError("reaction_toward_stranger", "Please select an option");
        return
      }
      else {
        clearError("reaction_toward_stranger");
    }
      if(is_toilet_trained === null){
        showError("is_toilet_trained", "Please select an option");
        return
      }
      else {
        clearError("is_toilet_trained");
    }
      if(is_toilet_trained !== "Yes" && !is_toilet_trained_text){
        showError("is_toilet_trained_text", "Please provide details about your dog's toilet training");
        return
      }
      else {
        clearError("is_toilet_trained_text");
    }
      if(is_aggressive === null){
        showError("is_aggressive", "Please select an option");
        return
      }
      else {
        clearError("is_aggressive");
    }
      if(is_aggressive !== "No" && !is_aggressive_text){ 
        showError("is_aggressive_text", "Please provide details about your dog's aggressive behavior");
        return
      }
      else {
        clearError("is_aggressive_text");
    }
      if(is_vaccinated === null){
        showError("is_vaccinated", "Please select an option");
        return
      }
      else {
        clearError("is_vaccinated");
    }
      if(is_vaccinated !== "Yes" && !is_vaccinated_text){
        showError("is_vaccinated_text", "Please provide details about your dog's vaccination status");
        return
      }
        else {
        clearError("is_vaccinated_text");
    }
      const imgUrls = [];
      if (activeStep === 1) {
        let formData = new FormData();
        images.map((res) => {
          formData.append("image", res);
        });
  
        if (images.length > 0) {
          await ImageUpload(formData)
            .then((res) => {
              const data = res.data.data.url;
              const list = data.map((url) => url);
              imgUrls.push(list);
            })
            .then(() => {
              addDog({
                name: name,
                breed: breed,
                gender: gender,
                photos: imgUrls[0],
                dob: dob,
                is_neutered: is_neutered,
                age_category: age_category,
                size_category: size_category,
                is_friendly_with_other_dogs: is_friendly_with_other_dogs,
                is_friendly_with_other_dogs_text: is_friendly_with_other_dogs_text,
                is_comfortable_around_cat: is_comfortable_around_cat,
                is_comfortable_around_cat_text: is_comfortable_around_cat_text,
                chew_or_damage_things: chew_or_damage_things,
                chew_or_damage_things_text: chew_or_damage_things_text,
                can_left_alone: can_left_alone,
                can_left_how_long: can_left_how_long,
                can_left_how_long_text: can_left_how_long_text,
                reaction_toward_children: reaction_toward_children,
                reaction_toward_stranger: reaction_toward_stranger,
                is_toilet_trained: is_toilet_trained,
                is_toilet_trained_text: is_toilet_trained_text,
                is_aggressive: is_aggressive,
                is_aggressive_text: is_aggressive_text,
                is_vaccinated: is_vaccinated,
                is_vaccinated_text: is_vaccinated_text,
                additional_info: additional_info,
              }).then((res) => {
                if (res.data.response_code === 80) {
                  setSnackbar({
                    ...snackbar,
                    isOpen: true,
                    message: "Dog Added Successfully",
                    type: "success",
                  });
                  const authStore = window.localStorage.getItem("AuthStore");
                  const serialisedData = JSON.parse(authStore);
                  const newResponse = res.data.data;
                  const merge = { ...serialisedData, ...newResponse };
                  setItem("AuthStore", JSON.stringify(merge));
                  setActiveStep((tab) => tab + 1);
                }
              });
            });
        }
        getDogCounts().then((res) => {
          setDogCount(res.data.data.count);
        });
      } else {
        setActiveStep((tab) => tab + 1);
      }
    }
  };
  

  const handleBack = () => {
    setActiveStep((tab) => tab - 1);
  };

  const handleAddAnotherDog = () => {
    setDogFormData({...dogFormData, name: null, breed: null, gender: null, photos:[], dob: null, is_neutered: null, age_category: null, size_category: null, is_friendly_with_other_dogs: null, is_friendly_with_other_dogs_text:  null, is_comfortable_around_cat: null, is_comfortable_around_cat_text:  null, chew_or_damage_things: null, chew_or_damage_things_text:  null, can_left_alone: null, can_left_how_long:  null, can_left_how_long_text: null, reaction_toward_children: null, reaction_toward_stranger: null, is_toilet_trained: null, is_toilet_trained_text: null, is_aggressive: null, is_aggressive_text: null, is_vaccinated: null, is_vaccinated_text:  null, additional_info:  null})
    getDogCounts().then((res) => {
      setDogCount(res.data.data.count)
    })
    setActiveStep(0)
  }

  useEffect(() => {
    getDogCounts().then((res) => {
      setDogCount(res.data.data.count)
    })
  },[])


  
  return (
    <>
      <style>{`.stepper-bg  svg{ fill : #fe7648 }`}</style>
      <ThemeProvider theme={theme}>
            <Grid sx={{ display: "grid", gridTemplateRows: "auto 1fr", height: "100vh" }}>
            <Grid item sx={{ position: "sticky", top: 0, zIndex: 10 }}>
        <Masthead />
      </Grid>
  <Grid container component="main" sx={{ flexGrow: 1, overflowY: "auto" ,scrollbarWidth: "none", /* Firefox */
    "&::-webkit-scrollbar": { display: "none" } /* Chrome, Safari, Edge */}}>         
     <Grid
            item
            xs={false}
            sm={2}
            md={4}
            sx={{
              backgroundImage: `url(${assests.newBgImg})`,
              backgroundRepeat: "no-repeat",
              backgroundColor: (t) =>
                t.palette.mode === "light"
                  ? t.palette.grey[50]
                  : t.palette.grey[900],
              backgroundSize: "cover",
              backgroundPosition: "center",
              height: '100vh'
            }}
          >
                    <Grid className="welcome-wrapper">
                      <p className="welcome-text">Welcome to Dogstays</p>
                    </Grid>
                  </Grid>
          <Grid
            item
            xs={12}
            sm={10}
            md={8}
            component={Card}
            elevation={6}
            className="hide-scrollbar"
            sx={{ backgroundColor: "#ffff", height:'100%', overflowY: 'scroll', boxShadow: 'none' }}
          >
            <Box sx={{display: 'flex', flexDirection: 'row-reverse'}}>
            <Box sx={{display: 'flex', alignItems: 'center', marginRight: '40px'}}>
            {dogSvg()} {dogCount}
            </Box>
            <Typography
              component="h1"
              fontFamily="Brandon"
              sx={{
                width: "100%",
                textAlign: "center",
                mt: 5,
                mb: 5,
                fontSize: "28px",
              }}
            >
             Your Dog(s)
            </Typography>
            </Box>

            <Box
              className='dog-registration-container'
              fullWidth
              noValidate
              sx={{
                mt: 1,
              }}
            >
            <p style={{fontSize: '14px', fontFamily:'Brandon', color: '#444140', textAlign: 'center', paddingBottom: '2px'}}>
            Now please add your dog to your DogStays account. You need to add your dogs one at a time so please only enter information for one dog here.
             We will give you the option to add the next dog once you complete this dog's profile. 
             You can see how many dogs have been added to your DogStays profile by looking at the top right of the page. You can edit/change your dogs profile from your dashboard later.
            </p>
              <Grid
                container
                spacing={2}
                className='dog-registration-wrapper'
              >
                <Box sx={{ width: "100%" }}>
                  <Stepper
                    activeStep={activeStep}
                    sx={{ width: "100%", margin: "0 auto" , marginBottom: "20px"}} 
                  >
                    {steps.map((label, index) => {
                      return (
                        <Step key={label}>
                          <StepLabel className="stepper-bg" sx={{fontFamily:'Brandon'}} >{label}</StepLabel>
                        </Step>
                      );
                    })}
                  </Stepper>

                  <div sx={{marginBottom: '40px'}}>
                    {activeStep == 0 && (
                      <>
                        <Grid container>
                          <Grid
                            item
                            xs={12}
                            md={6}
                            sx={{display: 'block'}}
                          >
                            <Grid item xs={12} md={10}>
                              <FormInput fullWidth variant="standard">
                                <CustomLabelInput shrink="true">
                                  Dog Name <span style={{color: 'red'}}>*</span>
                                </CustomLabelInput>
                                <InputField
                                  type="text"
                                  size="small"
                                  placeholder="Enter Name"
                                  value={name}
                                  ref={(el) => (inputRefs.current.name = el)}
                                  onChange={(e) => {
                                    let newValue = e.target.value.replace(/[^A-Za-z\s]/g, ''); 
                                    setSnackbar((prev) => ({
                                      ...prev,
                                      isOpen:  newValue.length > 10, 
                                      message:newValue.length > 10 
                                          ? "Dog name cannot exceed 10 characters." 
                                          : "",
                                      type: 'error'
                                    }));
                                    
                                      setDogFormData({ ...dogFormData, name: newValue.slice(0, 10) });
                                    }}
                                />
                              </FormInput>
                            </Grid>
                            <Grid item xs={12} md={10}>
                              <FormInput fullWidth variant="standard">
                                <CustomLabelInput shrink="true">
                                  Dog Breed <span style={{color: 'red'}}>*</span>
                                </CustomLabelInput>
                                <InputField
                                  type="text"
                                  size="small"
                                  ref={(el) => (inputRefs.current.breed = el)}
                                  placeholder="Enter Breed"
                                  value={breed}
                                  onChange={(e) => {
                                    const newValue = e.target.value.replace(/[^A-Za-z\s]/g, ''); 
                                    setDogFormData({ ...dogFormData, breed: newValue.slice(0, 28) });
                                  }}
                                />
                              </FormInput>
                            </Grid>
                            <Grid item xs={12} md={10}>
                              <FormInput fullWidth variant="standard">
                                <CustomLabelInput shrink="true">
                                  Dog Gender <span style={{color: 'red'}}>*</span>
                                </CustomLabelInput>
                                <NativeSelect
                                  disableUnderline
                                  defaultValue=""
                                  ref={(el) => (inputRefs.current.gender = el)}
                                  value={gender}
                                  onChange={(e) => setDogFormData({...dogFormData, gender: e.target.value})}
                                  inputProps={{
                                    style: {
                                      fontFamily: "Brandon",
                                      padding: "8.5px 14px",
                                    },
                                  }}
                                  sx={{
                                    border: "1px solid #ced4d9",
                                    borderRadius: "3px",
                                    marginTop: "5px",
                                  }}
                                >
                                  {" "}
                                  <option selected disabled value="">
                                    Select Gender
                                  </option>
                                  <option value="Male">Male</option>
                                  <option value="Female">Female</option>
                                </NativeSelect>
                              </FormInput>
                            </Grid>
                            <Grid item xs={12} md={10}>
                              <FormInput fullWidth variant="standard">
                                <CustomLabelInput
                                  shrink="true"
                                  htmlFor="date_of_birth"
                                >
                                  Date of Birth <span style={{color: 'red'}}>*</span>
                                </CustomLabelInput>
                                <InputField
                                  name="date_of_birth"
                                  type="date"
                                  id="date_of_birth"
                                  ref={(el) => (inputRefs.current.dob = el)}
                                  size="small"
                                  placeholder="Enter DOB"
                                  onKeyDown={(e) => {e.preventDefault(); return}} // Prevent typing
                                  onKeyUp={(e) => {e.preventDefault(); return}} // Prevent typing
                                  value={dob}
                                  InputProps={{ inputProps: { max: currentDate, inputMode: 'none'} }}
                                  onChange={(e) => setDogFormData({...dogFormData, dob: e.target.value})}
                                />
                              </FormInput>
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            md={6}
                            sx={{display: 'block'}}
                          >
                           <Grid item xs={12} md={10}>
                              <FormInput fullWidth variant="standard">
                                <CustomLabelInput shrink="true">
                                  Neutered <span style={{color: 'red'}}>*</span>
                                </CustomLabelInput>
                                <NativeSelect
                                  disableUnderline
                                  defaultValue=""
                                  value={is_neutered}
                                  ref={(el) => (inputRefs.current.is_neutered = el)}
                                  onChange={(e) => setDogFormData({...dogFormData, is_neutered: e.target.value})}
                                  inputProps={{
                                    style: {
                                      fontFamily: "Brandon",
                                      padding: "8.5px 14px",
                                    },
                                  }}
                                  sx={{
                                    border: "1px solid #ced4d9",
                                    borderRadius: "3px",
                                    marginTop: "5px",
                                  }}
                                >
                                  {" "}
                                  <option selected disabled value="">
                                    Select
                                  </option>
                                  <option value="Yes">Yes</option>
                                  <option value="No">No</option>
                                </NativeSelect>
                              </FormInput>
                            </Grid> 
                            <Grid item xs={12} md={10}>
                              <FormInput fullWidth variant="standard">
                                <CustomLabelInput shrink="true" htmlFor=" ">
                                What age category is your dog? <span style={{color: 'red'}}>*</span>
                                </CustomLabelInput>
                                <NativeSelect
                                  disableUnderline
                                  ref={(el) => (inputRefs.current.age_category = el)}
                                  defaultValue=""
                                  value={age_category}
                                  onChange={(e) => setDogFormData({...dogFormData, age_category: e.target.value})}
                                  inputProps={{
                                    style: {
                                      fontFamily: "Brandon",
                                      padding: "8.5px 14px",
                                    },
                                  }}
                                  sx={{
                                    border: "1px solid #ced4d9",
                                    borderRadius: "3px",
                                    marginTop: "5px",
                                  }}
                                >
                                  {" "}
                                  <option selected disabled value="">
                                    Select
                                  </option>
                                  <option value="young_puppy">Very Young Puppy (less than 1 year old)</option>
                                  <option value="puppy">Puppy (less than 2 years old)</option>
                                  <option value="adult_dog">Adult dog (over 2 years old)</option>
                                  <option value="senior_dog">Senior dog (above 7 years old)</option>
                                </NativeSelect>
                              </FormInput>
                            </Grid> 
                            <Grid item xs={12} md={10}>
                              <FormInput fullWidth variant="standard">
                                <CustomLabelInput shrink="true" htmlFor=" ">
                                What size category is your dog? <span style={{color: 'red'}}>*</span>
                                </CustomLabelInput>
                                <NativeSelect
                                  disableUnderline
                                  defaultValue=""
                                  value={size_category}
                                  ref={(el) => (inputRefs.current.size_category = el)}
                                  onChange={(e) => setDogFormData({...dogFormData, size_category: e.target.value})}
                                  inputProps={{
                                    style: {
                                      fontFamily: "Brandon",
                                      padding: "8.5px 14px",
                                    },
                                  }}
                                  sx={{
                                    border: "1px solid #ced4d9",
                                    borderRadius: "3px",
                                    marginTop: "5px",
                                  }}
                                >
                                  {" "}
                                  <option selected disabled value="">
                                    Select
                                  </option>
                                  <option value="small">Small (less than 14 kilos)</option>
                                  <option value="medium">Medium (between 14 and 25 kilos)</option>
                                  <option value="large">Large (more than 25 kilos)</option>
                                </NativeSelect>
                              </FormInput>
                            </Grid>
                          </Grid>
                        </Grid>
                        <MultiUpload 
                        text='Please upload at least one and maximum 3 pictures of your dog. We need these to find you
                              the right dog sitter. (accepted formats: JPEG, JPG, PNG and file size: up to 5 MB each)'
                        imageAssets={(res) => {
                          setImages(res)
                          setImagesLength(res.length)
                        }}
                        limit={3}
                        isMadatory={true}  
                        highlight={highlightUpload}                     
                         />
                      </>
                    )}
                    {activeStep == 1 && 
                      <Grid container>
                      <Grid item xs={12} md={6} sx={{ display: "block" }}
                      >
                       <Grid item xs={12} md={10}>
                          <FormInput fullWidth variant="standard">
                            <CustomLabelInput shrink="true" htmlFor=" ">
                              Is your dog friendly with other dogs? <span style={{color: 'red'}}>*</span>
                            </CustomLabelInput>
                            <NativeSelect
                              disableUnderline
                              ref={(el) => (inputRefs.current.is_friendly_with_other_dogs = el)}
                              defaultValue=""
                              value={is_friendly_with_other_dogs}
                              onChange={(e) => setDogFormData({...dogFormData, is_friendly_with_other_dogs: e.target.value})}
                              inputProps={{
                                style: {
                                  fontFamily: "Brandon",
                                  padding: "8.5px 14px",
                                },
                              }}
                              sx={{
                                border: "1px solid #ced4d9",
                                borderRadius: "3px",
                                marginTop: "5px",
                              }}
                            >
                              <option selected disabled value="">
                                Select
                              </option>
                              <option value="Yes">Yes</option>
                              <option value="No">No</option>
                              <option value="other">It Depends</option>
                            </NativeSelect>
                          </FormInput>
                        </Grid>

                        {is_friendly_with_other_dogs && 
                            <Grid item xs={12} md={10}>
                            <FormInput fullWidth variant="standard">
                              <CustomLabelInput
                                shrink="true"
                              >
                                Any additional details?
                              </CustomLabelInput>
                              <InputTeaxtArea
                                size="small"
                                placeholder="Enter Details"
                                ref={(el) => (inputRefs.current.is_friendly_with_other_dogs_text = el)}
                                minRows={2}
                                sx={{fontFamily: 'Brandon', height:"40px", fontSize: "1rem", border: "1px solid #ced4d9", borderRadius:'3px', padding: "8.5px 14px",  marginTop:"5px"}}
                                value={is_friendly_with_other_dogs_text}
                                onChange={(e) => setDogFormData({...dogFormData, is_friendly_with_other_dogs_text: e.target.value})}
                              />
                            </FormInput>
                          </Grid>}
                        <Grid item xs={12} md={10}>
                          <FormInput fullWidth variant="standard">
                            <CustomLabelInput shrink="true">
                            Is your dog comfortable around cats? <span style={{color: 'red'}}>*</span>
                            </CustomLabelInput>
                            <NativeSelect
                              disableUnderline
                              ref={(el) => (inputRefs.current.is_comfortable_around_cat = el)}
                              defaultValue=""
                              value={is_comfortable_around_cat}
                              onChange={(e) => setDogFormData({...dogFormData, is_comfortable_around_cat: e.target.value})}
                              inputProps={{
                                style: {
                                  fontFamily: "Brandon",
                                  padding: "8.5px 14px",
                                },
                              }}
                              sx={{
                                border: "1px solid #ced4d9",
                                borderRadius: "3px",
                                marginTop: "5px",
                              }}
                            >
                              <option selected disabled value="">
                                Select
                              </option>
                              <option value="Yes">Yes</option>
                              <option value="No">No</option>
                              <option value="other">Not Sure</option>
                            </NativeSelect>
                          </FormInput>
                        </Grid> 
                        {is_comfortable_around_cat && 
                            <Grid item xs={12} md={10}>
                            <FormInput fullWidth variant="standard">
                              <CustomLabelInput
                                shrink="true"
                              >
                                Any additional details?
                              </CustomLabelInput>
                              <InputTeaxtArea
                                size="small"
                                placeholder="Enter Details"
                                ref={(el) => (inputRefs.current.is_comfortable_around_cat_text = el)}
                                minRows={2}
                                sx={{fontFamily: 'Brandon', height:"40px", fontSize: "1rem", border: "1px solid #ced4d9", borderRadius:'3px', padding: "8.5px 14px", marginTop:"5px"}}
                                value={is_comfortable_around_cat_text}
                                onChange={(e) => setDogFormData({...dogFormData, is_comfortable_around_cat_text: e.target.value})}
                              />
                            </FormInput>
                          </Grid>}
                        <Grid item xs={12} md={10}>
                          <FormInput fullWidth variant="standard">
                            <CustomLabelInput shrink="true" >
                            Does your dog chew or damage things? <span style={{color: 'red'}}>*</span>
                            </CustomLabelInput>
                            <NativeSelect
                              disableUnderline
                              defaultValue=""
                              ref={(el) => (inputRefs.current.chew_or_damage_things = el)}
                              value={chew_or_damage_things}
                              onChange={(e) => setDogFormData({...dogFormData, chew_or_damage_things: e.target.value})}
                              inputProps={{
                                style: {
                                  fontFamily: "Brandon",
                                  padding: "8.5px 14px",
                                },
                              }}
                              sx={{
                                border: "1px solid #ced4d9",
                                borderRadius: "3px",
                                marginTop: "5px",
                              }}
                            >
                              <option selected disabled value="">
                                Select
                              </option>
                              <option value="Yes">Yes</option>
                              <option value="No">No</option>
                              <option value="other">other</option>
                            </NativeSelect>
                          </FormInput>
                        </Grid> 
                        {chew_or_damage_things && 
                            <Grid item xs={12} md={10}>
                            <FormInput fullWidth variant="standard">
                              <CustomLabelInput
                                shrink="true"
                              >
                                Any additional details?
                              </CustomLabelInput>
                              <InputTeaxtArea
                                size="small"
                                placeholder="Enter Details"
                                minRows={2}
                                ref={(el) => (inputRefs.current.chew_or_damage_things_text = el)}
                                sx={{fontFamily: 'Brandon', height:"40px", fontSize: "1rem", border: "1px solid #ced4d9", borderRadius:'3px', padding: "8.5px 14px", marginTop:"5px"}}
                                value={chew_or_damage_things_text}
                                onChange={(e) => setDogFormData({...dogFormData, chew_or_damage_things_text: e.target.value})}
                              />
                            </FormInput>
                          </Grid>}
                        <Grid item xs={12} md={10}>
                          <FormInput fullWidth variant="standard">
                            <CustomLabelInput shrink="true" htmlFor=" ">
                            Can your dog be left alone at home for some time? <span style={{color: 'red'}}>*</span>
                            </CustomLabelInput>
                            <NativeSelect
                              disableUnderline
                              defaultValue=""
                              value={can_left_alone}
                              ref={(el) => (inputRefs.current.can_left_alone = el)}
                              onChange={(e) => setDogFormData({...dogFormData, can_left_alone: e.target.value})}
                              inputProps={{
                                style: {
                                  fontFamily: "Brandon",
                                  padding: "8.5px 14px",
                                },
                              }}
                              sx={{
                                border: "1px solid #ced4d9",
                                borderRadius: "3px",
                                marginTop: "5px",
                              }}
                            >
                              <option selected disabled value="">
                                Select
                              </option>
                              <option value="Yes">Yes</option>
                              <option value="No">No</option>
                              <option value="other">other</option>
                            </NativeSelect>
                          </FormInput>
                        </Grid>

                        { can_left_alone && can_left_alone === "Yes" && 
                        <Grid item xs={12} md={10}>
                          <FormInput fullWidth variant="standard">
                          <CustomLabelInput shrink="true">
                            For how long? <span style={{color: 'red'}}>*</span>
                          </CustomLabelInput>
                          <NativeSelect
                            disableUnderline
                            ref={(el) => (inputRefs.current.can_left_how_long = el)}
                            defaultValue=""
                            value={can_left_how_long}
                            onChange={(e) => setDogFormData({...dogFormData, can_left_how_long: e.target.value})}
                            inputProps={{
                              style: {
                                fontFamily: "Brandon",
                                padding: "8.5px 14px",
                              },
                            }}
                            sx={{
                              border: "1px solid #ced4d9",
                              borderRadius: "3px",
                              marginTop: "5px",
                            }}
                          >
                            {" "}
                            <option selected disabled value="">
                              Select
                            </option>1-2 hours, 2-4 hours, 4-6 hours, 6-8 hours, As long as needed, Other
                            <option value="1-2 hours">1-2 hours</option>
                            <option value="2-4 hours">2-4 hours</option>
                            <option value="4-6 hours"> 4-6 hours</option>
                            <option value="6-8 hours"> 6-8 hours</option>
                            <option value="As long as needed"> As long as needed</option>
                            <option value="Other"> other</option>
                          </NativeSelect>
                        </FormInput>
                        </Grid>
                        }
                        {can_left_alone && 
                        <Grid item xs={12} md={10}>
                        <FormInput fullWidth variant="standard">
                          <CustomLabelInput
                            shrink="true"
                          >
                            Any additional details?
                          </CustomLabelInput>
                          <InputTeaxtArea
                            size="small"
                            placeholder="Enter Details"
                            minRows={2}
                            ref={(el) => (inputRefs.current.can_left_how_long_text = el)}
                           
                            sx={{fontFamily: 'Brandon', height:"40px", fontSize: "1rem", border: "1px solid #ced4d9", borderRadius:'3px', padding: "8.5px 14px", marginTop:"5px"}}
                            value={can_left_how_long_text}
                            onChange={(e) => setDogFormData({...dogFormData, can_left_how_long_text: e.target.value})}
                          />
                        </FormInput>
                      </Grid>
                        }
                      </Grid>
                      <Grid item xs={12} md={6} sx={{ display: "block",}}
                      >
                        <Grid item xs={12} md={10}>
                        <FormInput fullWidth variant="standard">
                          <CustomLabelInput shrink="true" htmlFor="street_name">
                          How does your dog react to children? <span style={{color: 'red'}}>*</span>
                          </CustomLabelInput>
                          <InputTeaxtArea
                            maxRows={2}
                            ref={(el) => (inputRefs.current.reaction_toward_children = el)}
                            value={reaction_toward_children}
                            onChange={(e) => setDogFormData({...dogFormData, reaction_toward_children: e.target.value})}
                            size="small"
                            placeholder="Enter Details"
                            sx={{fontFamily: 'Brandon', height:"40px", fontSize: "1rem", border: "1px solid #ced4d9", borderRadius:'3px', padding: "8.5px 14px", marginTop:"5px"}}
                          />
                        </FormInput>
                      </Grid>
                      <Grid item xs={12} md={10}>
                        <FormInput fullWidth variant="standard">
                          <CustomLabelInput shrink="true" htmlFor="street_name">
                          How does your dog react to strangers? <span style={{color: 'red'}}>*</span>
                          </CustomLabelInput>
                          <InputTeaxtArea
                            maxRows={2}
                            size="small"
                            ref={(el) => (inputRefs.current.reaction_toward_stranger = el)}
                            value={reaction_toward_stranger}
                            onChange={(e) => setDogFormData({...dogFormData, reaction_toward_stranger: e.target.value})}
                            placeholder="Enter Details"
                            sx={{fontFamily: 'Brandon', height:"40px", fontSize: "1rem", border: "1px solid #ced4d9", borderRadius:'3px', padding: "8.5px 14px", marginTop:"5px" }}
                          />
                        </FormInput>
                      </Grid>
                      <Grid item xs={12} md={10}>
                          <FormInput fullWidth variant="standard">
                            <CustomLabelInput shrink="true" htmlFor=" ">
                            Is your dog completely toilet trained? <span style={{color: 'red'}}>*</span>
                            </CustomLabelInput>
                            <NativeSelect
                              disableUnderline
                              defaultValue=""
                              value={is_toilet_trained}
                              ref={(el) => (inputRefs.current.is_toilet_trained = el)}
                              onChange={(e) => setDogFormData({...dogFormData, is_toilet_trained: e.target.value})}
                              inputProps={{
                                style: {
                                  fontFamily: "Brandon",
                                  padding: "8.5px 14px",
                                },
                              }}
                              sx={{
                                border: "1px solid #ced4d9",
                                borderRadius: "3px",
                                marginTop: "5px",
                              }}
                            >
                              <option selected disabled value="">
                                Select
                              </option>
                              <option value="Yes">Yes</option>
                              <option value="No">No</option>
                              <option value="other">other</option>
                            </NativeSelect>
                          </FormInput>
                        </Grid>
                        {is_toilet_trained && 
                            <Grid item xs={12} md={10}>
                            <FormInput fullWidth variant="standard">
                              <CustomLabelInput
                                shrink="true"
                              >
                                Any additional details?
                              </CustomLabelInput>
                              <InputTeaxtArea
                                size="small"
                                placeholder="Enter Details"
                                minRows={2}
                                ref={(el) => (inputRefs.current.is_toilet_trained_text = el)}
                                sx={{fontFamily: 'Brandon', height:"40px", fontSize: "1rem", border: "1px solid #ced4d9", borderRadius:'3px', padding: "8.5px 14px", marginTop:"5px"}}
                                value={is_toilet_trained_text}
                                onChange={(e) => setDogFormData({...dogFormData, is_toilet_trained_text: e.target.value})}
                              />
                            </FormInput>
                          </Grid>}
                        <Grid item xs={12} md={10}>
                          <FormInput fullWidth variant="standard">
                            <CustomLabelInput shrink="true" htmlFor=" ">
                            Is your dog aggressive in any situation? <span style={{color: 'red'}}>*</span>
                            </CustomLabelInput>
                            <NativeSelect
                              disableUnderline
                              defaultValue=""
                              value={is_aggressive}
                              ref={(el) => (inputRefs.current.is_aggressive = el)}
                              onChange={(e) => setDogFormData({...dogFormData, is_aggressive: e.target.value})}
                              inputProps={{
                                style: {
                                  fontFamily: "Brandon",
                                  padding: "8.5px 14px",
                                },
                              }}
                              sx={{
                                border: "1px solid #ced4d9",
                                borderRadius: "3px",
                                marginTop: "5px",
                              }}
                            >
                              <option selected disabled value="">
                                Select
                              </option>
                              <option value="Yes">Yes</option>
                              <option value="No">No</option>
                              <option value="other">other</option>
                            </NativeSelect>
                          </FormInput>
                        </Grid>
                        {is_aggressive && 
                            <Grid item xs={12} md={10}>
                            <FormInput fullWidth variant="standard">
                              <CustomLabelInput
                                shrink="true"
                              >
                                Any additional details?
                              </CustomLabelInput>
                              <InputTeaxtArea
                                size="small"
                                placeholder="Enter Details"
                                minRows={2}
                                ref={(el) => (inputRefs.current.is_aggressive_text = el)}
                             
                                sx={{fontFamily: 'Brandon', height:"40px", fontSize: "1rem", border: "1px solid #ced4d9", borderRadius:'3px', padding: "8.5px 14px", marginTop:"5px"}}
                                value={is_aggressive_text}
                                onChange={(e) => setDogFormData({...dogFormData, is_aggressive_text: e.target.value})}
                              />
                            </FormInput>
                          </Grid>}
                        <Grid item xs={12} md={10}>
                          <FormInput fullWidth variant="standard">
                            <CustomLabelInput shrink="true" htmlFor=" ">
                            Is your dog fully vaccinated? <span style={{color: 'red'}}>*</span>
                            </CustomLabelInput>
                            <NativeSelect
                              disableUnderline
                              ref={(el) => (inputRefs.current.is_vaccinated = el)}
                              defaultValue=""
                              value={is_vaccinated}
                              onChange={(e) => setDogFormData({...dogFormData, is_vaccinated: e.target.value})}
                              inputProps={{
                                style: {
                                  fontFamily: "Brandon",
                                  padding: "8.5px 14px",
                                },
                              }}
                              sx={{
                                border: "1px solid #ced4d9",
                                borderRadius: "3px",
                                marginTop: "5px",
                              }}
                            >
                              <option selected disabled value="">
                                Select
                              </option>
                              <option value="Yes">Yes</option>
                              <option value="No">No</option>
                              <option value="other">other</option>
                            </NativeSelect>
                          </FormInput>
                        </Grid> 
                        {is_vaccinated && 
                            <Grid item xs={12} md={10}>
                            <FormInput fullWidth variant="standard">
                              <CustomLabelInput
                                shrink="true"
                              >
                                Any additional details?
                              </CustomLabelInput>
                              <InputTeaxtArea
                                size="small"
                                placeholder="Enter Details"
                                minRows={2}
                                ref={(el) => (inputRefs.current.is_vaccinated_text = el)}

                                sx={{fontFamily: 'Brandon', height:"40px", fontSize: "1rem", border: "1px solid #ced4d9", borderRadius:'3px', padding: "8.5px 14px", marginTop:"5px"}}
                                value={is_vaccinated_text}
                                onChange={(e) => setDogFormData({...dogFormData, is_vaccinated_text: e.target.value})}
                              />
                            </FormInput>
                          </Grid>}
                      </Grid>
                      <Grid item xs={12}>
                      <FormInput fullWidth variant="standard">
                        <CustomLabelInput shrink="true">
                        Is there anything else you would like us or the dog sitter to know?
                        </CustomLabelInput>
                        <InputTeaxtArea
                          minRows={2}
                          sx={{fontFamily: 'Brandon', height:"40px", fontSize: "1rem", border: "1px solid #ced4d9", borderRadius:'3px', padding: "8.5px 14px", marginTop:"5px"}}
                          size="small"
                          placeholder="Enter Details"
                          value={additional_info}
                          onChange={(e) => setDogFormData({...dogFormData, additional_info: e.target.value})}
                        />
                      </FormInput>
                    </Grid>
                    </Grid>
                    }

                    {activeStep == 2 &&
                    <Grid container>
                      <Grid xs={12}>
                        <p style={{textAlign: 'center', fontFamily: 'Brandon', fontWeight: '600'}}>We have successfully added your dog to your profile.<br/> What do you want to do now?</p>
                      </Grid>
                      <Grid item xs={12} sx={{display: 'grid', gap: '10px', placeItems: 'center'}}>  
                      <Button
                        type="submit"
                        className="default-btn"
                        variant="contained"
                        sx={{ mt: 3, mb: 2, width: "35%" }}
                        onClick={handleAddAnotherDog}
                      >
                        Add Another Dog
                      </Button>
                        <Button
                          type="submit"
                          className="default-btn"
                          variant="contained"
                          onClick={() => navigate('/booking-request')}
                          sx={{ mt: 3, mb: 2, width: "35%" }}
                        >
                          Send Booking Request
                        </Button>
                        <Button
                          type="submit"
                          className="default-btn"
                          variant="contained"
                          onClick={() => navigate('/dashboard?showCarousel=true')}
                          sx={{ mt: 3, mb: 2, width: "35%" }}
                        >
                          My DogStays Account
                        </Button>
                      </Grid>
                    </Grid>
                    }
                    <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                    {(activeStep == 0 || activeStep == 2) ?
                      null : <Button
                      className="dog-action-btn"
                        onClick={handleBack}
                        sx={{ mr: 1 }}
                      >
                        Back
                      </Button> 
                    }
                      <Box sx={{ flex: "1 1 auto" }} />
                      {activeStep !== 2 &&
                      <Button className="dog-action-btn" onClick={handleNext}>
                        {activeStep === 1 ? "Submit" : "Next"}
                      </Button>
                      }
                    </Box>
                  </div>
                </Box>

                <Grid
                  item
                  xs={12}
                  sx={{ display: "flex", justifyContent: "center" }}
                ></Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
        </Grid>
      </ThemeProvider>
      <SnackBar
      open={snackbar.isOpen}
      handleClose={() => setSnackbar({...snackbar, isOpen: false})}
      duration={300000}
      msg={snackbar.message}
      type={snackbar.type}
    />
    </>
  );
};

export default DogResgistration;
