import React, { useEffect, useState } from "react";
import {Card, CardContent, CardMedia, Container, Grid, Typography} from "@mui/material";
import request from '../../../../Assets/Images/request.svg'
import booking from '../../../../Assets/Images/booking.svg'
import payments from '../../../../Assets/Images/payments.svg'
import dog from '../../../../Assets/Images/dog.svg'
import meeting from '../../../../Assets/Images/meeting.svg'
import Masthead from "../../../../Components/Masthead";
import { useNavigate } from "react-router-dom";
import { getSelfProfile } from "../../../../Utils/Api";
import Loader from "../../../../Components/Loader";
import "./style.css";
import { assests } from "../../../../Assets/assets";
import MobileMasthead from "../../../../Components/Masthead/MobileMasthead";
import FirstLoginFlow from "../../../../Components/FirstLoginFlow";

const Dashboard = () => {
  const tabs = [
    {type: "Send New Booking Request", image: assests.newBooking, path: '/dashboard/booking/create'},
    {type: "My Dog(s)", image: dog, path: '/dashboard/dog'},
    {type: "My Bookings", image: request, path: '/dashboard/bookings_request'},
    {type: "My Meetings", image: meeting, path: '/dashboard/meetings'},
    // {type: "My Bookings", image: booking, path: '#'},
    {type: "My Payments", image: payments, path: '/dashboard/payments'},
  ];
  const navigate = useNavigate()
  const [userData, setUserData] = useState([]) 
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    getSelfProfile().then((res) => {
      setUserData(res.data.data)
    })
    setLoading(false)
  },[])

  return (
    <>
        <Grid sx={{ display: "grid", gridTemplateRows: "auto 1fr", height: "100vh" }}>
    
        <Grid item sx={{ position: "sticky", top: 0, zIndex: 10 }}>
    <Masthead/>
    </Grid>
    <Grid container component="main" sx={{ flexGrow: 1, overflowY: "auto" ,scrollbarWidth: "none", /* Firefox */
        "&::-webkit-scrollbar": { display: "none" } /* Chrome, Safari, Edge */}}>
    <Container fixed sx={{mt:5}}>
    <div className="account-info">
  <p>
    {(userData?.first_name || userData?.last_name) 
      ? `${userData?.first_name || ''} ${userData?.last_name || ''}`.trim() + ',' 
      : '\u00A0'}
  </p>
  <p>{userData?.email || '\u00A0'}</p>
</div>
      {loading ? <Loader/> :
      <div className="account-tabs">
        {tabs.map((item) => {
          return (
            <Card onClick={() => navigate(item.path)} sx={{minWidth:300, maxWidth: 345, borderRadius:'15px', border: '1px solid #80808075', boxShadow: 'none' }}>
              <CardMedia
                sx={{ height: 180 , backgroundSize: 'contain'}}
                image={item.image}
              />
              <CardContent sx={{borderTop: '1px solid black'}}>
                <Typography gutterBottom variant="h6" component="div" sx={{fontFamily: 'Brandon', textAlign: 'center'}}>
                  {item.type}
                </Typography>
              </CardContent>
            </Card>
          );
        })}
      </div>
      }
    </Container>
    </Grid>
    </Grid>
    <FirstLoginFlow/>
  </>
  );
};

export default Dashboard;
