import { Box, Button, Checkbox, FormControlLabel, Grid, NativeSelect } from "@mui/material"
import { useEffect, useState } from "react"
import { CustomLabelInput, FormInput, InputField, InputTeaxtArea } from "../../../../../Components/CustomElements"
import { LocalizationProvider, MobileTimePicker } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import dayjs from "dayjs"
import { calculateOvernightTotalDays } from "../../../../../Utils/helper"
import { updateBookingById } from "../../../../../Utils/Api"
import { isEditable } from "@testing-library/user-event/dist/utils"
import SnackBar from "../../../../../Components/Snackbar"

const ConstentOvernightDates = ({data, updateStep, bookingAt, selectedAddress}) => {
  
    const [overnightData, setOvernightData] = useState({
        overnight_start_date_time: { date: '', time: ''},
        overnight_end_date_time: { date: '', time: ''}
    })
    const [overnightTimeDiff, setOvernightTimeDiff] = useState('')
    const [editDates, setEditDates] = useState({ editBtnText: 'Change', isFieldsDisabled: true})
    const [locationType, setLocationType] = useState({ sitterLocation: false, parentLocation: false, otherLocation: false})
    const [snackbar, setSnackbar] = useState({ isOpen : false, message: '', type: ''})
    console.log(selectedAddress,'over')
    useEffect(() => {
      if(locationType.sitterLocation){
        selectedAddress('')
      }
    },[locationType])

    const handleRenderDates = () => {
      const startDate = data.overnight_start_date_time && data.overnight_start_date_time.slice(0, 16).split('T')
      const endDate = data.overnight_end_date_time && data.overnight_end_date_time.slice(0, 16).split('T')
      setOvernightData({...overnightData,
        overnight_start_date_time: { ...overnightData.overnight_start_date_time, date: startDate?.[0], time: startDate?.[1]},
        overnight_end_date_time: { ...overnightData.overnight_end_date_time, date: endDate?.[0], time: endDate?.[1]}})
    }

    useEffect(() => {
      handleRenderDates()
    },[data])

      useEffect(() => {
        const time = calculateOvernightTotalDays(overnightData.overnight_start_date_time, overnightData.overnight_end_date_time)
        setOvernightTimeDiff(time)
        }, [overnightData]);

    const handleUpdateOvernightBooking = () => {
      updateBookingById(data.booking_id, {
        overnight_end_date_time: `${overnightData.overnight_end_date_time.date}T${overnightData.overnight_end_date_time.time}`,
        overnight_start_date_time: `${overnightData.overnight_start_date_time.date}T${overnightData.overnight_start_date_time.time}`
      }).then((res) => {
        if(res.data.response_code == 80){
          setSnackbar({...snackbar, isOpen: true, message: 'Dates Updated', type: 'success'})
          setEditDates({...editDates, isFieldsDisabled: !editDates.isFieldsDisabled})
        }
      })
    }
    const getNextDay = (date) => {
      if (!date) return "";
      const nextDay = new Date(date);
      nextDay.setDate(nextDay.getDate() + 1);
      return nextDay.toISOString().split("T")[0]; 
    };
    const handleStartDateChange = (e) => {
      const newStartDate = e.target.value;
      const newEndDate = getNextDay(newStartDate); // Calculate next day automatically
      
      setOvernightData({
        ...overnightData,
        overnight_start_date_time: {
          ...overnightData.overnight_start_date_time, 
          date: newStartDate
        },
        overnight_end_date_time: {
          ...overnightData.overnight_end_date_time,
          date: newEndDate
        }
      });
    };
return(
    <>
        <Grid container xs={12} spacing={5}>
          <Grid item xs={12} md={6}>
          <FormInput fullWidth variant="standard">
            <CustomLabelInput shrink="true">
              Start Date <span style={{color: 'red', fontSize: '20px'}}>*</span>
            </CustomLabelInput> 
            <InputField
              type="date"
              size="small"
              value={overnightData.overnight_start_date_time.date}
              inputProps={{ min: new Date().toISOString().substring(0,10)}}
              disabled={editDates.isFieldsDisabled}
              onChange={handleStartDateChange}
              placeholder="Enter Date"
            />
          </FormInput>
          </Grid>
          <Grid item xs={12} md={6}>
          <FormInput fullWidth variant="standard">
              <CustomLabelInput shrink="true">
              Start Time <span style={{color: 'red', fontSize: '20px'}}>*</span>
              </CustomLabelInput>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <MobileTimePicker
                 sx={{marginTop: '5px'}}
                 ampm={false}
                 defaultValue={dayjs()}
                 value={dayjs(`2014-08-18T${overnightData.overnight_start_date_time.time}`)}
                 disabled={editDates.isFieldsDisabled}
                 onChange={(e) => setOvernightData({...overnightData, overnight_start_date_time: {...overnightData.overnight_start_date_time, time: e.format('HH:mm')}})}
                 />
              </LocalizationProvider>            
              </FormInput>
              </Grid>
        </Grid>

        <Grid container xs={12} spacing={5}>
          <Grid item xs={12} md={6}>
          <FormInput fullWidth variant="standard">
            <CustomLabelInput shrink="true">
              End Date <span style={{color: 'red', fontSize: '20px'}}>*</span>
            </CustomLabelInput> 
            <InputField
              type="date"
              size="small"
              value={overnightData.overnight_end_date_time.date}
              onChange={(e) => setOvernightData({...overnightData, 
                overnight_end_date_time: {...overnightData.overnight_end_date_time, date: e.target.value}})}
              disabled={editDates.isFieldsDisabled}
              inputProps={{ min: getNextDay(overnightData.overnight_start_date_time.date) }}
              placeholder="Enter Date"
            />
          </FormInput>
          </Grid>
          <Grid item xs={12} md={6}>
          <FormInput fullWidth variant="standard">
              <CustomLabelInput shrink="true">
              End Time <span style={{color: 'red', fontSize: '20px'}}>*</span>
              </CustomLabelInput>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <MobileTimePicker 
                 sx={{marginTop: '5px'}}
                 ampm={false}
                 defaultValue={dayjs()}
                 value={dayjs(`2014-08-18T${overnightData.overnight_end_date_time.time}`)}
                 disabled={editDates.isFieldsDisabled}
                 onChange={(e) => setOvernightData({...overnightData, overnight_end_date_time: {...overnightData.overnight_end_date_time, time: e.format('HH:mm')}})}
                 />
              </LocalizationProvider>            
              </FormInput>
              </Grid>
        </Grid>

          <h4 style={{fontFamily: 'Brandon', fontWeight:'bold', margin: '5px 0px 0px 0px', color: '#00000099', textAlign: 'center'}}>Total number of days in your booking: {overnightTimeDiff}</h4>

        <Grid item xs={12}>
          <FormInput fullWidth variant="standard">
              <CustomLabelInput htmlFor="hear_from_source">
              Where will the booking happen? You can only choose one option. <span style={{color: 'red', fontSize: '20px'}}>*</span>
              </CustomLabelInput>
              <Box className="location-options">
                {data && data.location_type && data.location_type.length > 0 && <FormControlLabel label="At dog sitter's home" control={<Checkbox checked={locationType.sitterLocation} disabled={locationType.parentLocation || locationType.otherLocation} onChange={() => {setLocationType({...locationType, sitterLocation: !locationType.sitterLocation}); bookingAt('sitter')}} sx={{ color: '#fe7648', '&.Mui-checked': { color: '#fe7648' }, }}/>} />}
                {data && data.location_type && data.location_type.length > 0 && <FormControlLabel label="At my home address" control={<Checkbox checked={locationType.parentLocation} disabled={locationType.sitterLocation || locationType.otherLocation} onChange={() => {setLocationType({...locationType, parentLocation: !locationType.parentLocation}); bookingAt('parent')}} sx={{ color: '#fe7648', '&.Mui-checked': { color: '#fe7648' }, }}/>} />}
                {/* {data && data.location_type && data.location_type.length > 0 && <FormControlLabel label="At a new address" control={<Checkbox checked={locationType.otherLocation} disabled={locationType.parentLocation || locationType.sitterLocation} onChange={() => {setLocationType({...locationType, otherLocation: !locationType.otherLocation}); bookingAt('parent')}} sx={{ color: '#fe7648', '&.Mui-checked': { color: '#fe7648' }, }}/>} />} */}
              </Box>
            </FormInput>
        </Grid>
        {(locationType.parentLocation) && <Grid item xs={12}>
          <FormInput fullWidth variant="standard">
              <CustomLabelInput htmlFor="hear_from_source">
                At Parent Address
              </CustomLabelInput>
              <NativeSelect
                disableUnderline
                defaultValue=""
                // value={is_neutered}
                onChange={(e) => selectedAddress(e.target.value)} 
                inputProps={{
                  style: {
                    fontFamily: "Brandon",
                    padding: "8.5px 14px",
                  },
                }}
                sx={{
                  border: "1px solid #ced4d9",
                  borderRadius: "3px",
                  marginTop: "5px",
                }}
              >
                <option selected disabled value="">
                  Select
                </option>
                {data.parent_all_address.map((item) => {
                  return(
                    <option value={item.id}>{item.street_name + ' ' + item.postal_code + ' ' + item.city + ' ' + item.country}</option>
                  )
                })}
              </NativeSelect>
            </FormInput>
        </Grid>}
        {(locationType.otherLocation) && <Grid item xs={12}>
          <FormInput fullWidth variant="standard">
              <CustomLabelInput htmlFor="hear_from_source">
                At Other Address
              </CustomLabelInput>
              <InputTeaxtArea
                value={`${data?.manual_address?.street_name || ''} ${data?.manual_address?.city || ''} ${data?.manual_address?.country || ''} ${data?.manual_address?.postal_code || ''}`}
                disabled
                minRows={2}
                sx={{fontFamily: 'Brandon', height:"40px", fontSize: "1rem", border: "1px solid #ced4d9", borderRadius:'3px', padding: "8.5px 14px", marginTop:"5px"}}
                size="small"
                placeholder="Enter Details"
              />
            </FormInput>
        </Grid>}
          <Grid item xs= {12} sx={{display: 'flex', justifyContent: 'center', gap: '10px'}}> 
            <Button
              type="submit"
              className={editDates.isFieldsDisabled  ? "default-btn" : 'cancel-action-btn'}
              variant="contained"
              sx={{ mt: 3, mb: 2, width: "auto" }}
              onClick={() =>  {handleRenderDates(); setEditDates({...editDates, isFieldsDisabled: !editDates.isFieldsDisabled})}}
            >
              {editDates.isFieldsDisabled ? 'Change' : 'Undo'}
            </Button>
            {!editDates.isFieldsDisabled && <Button
              type="submit"
              className="default-btn"
              variant="contained"
              sx={{ mt: 3, mb: 2, width: "auto" }}
              onClick={handleUpdateOvernightBooking}
            >
              Update
            </Button>}
            </Grid>

            <Grid item xs= {12} sx={{display: 'flex', justifyContent: 'center'}}> 
            <Button
              type="submit"
              className="default-btn"
              variant="contained"
              sx={{ mt: 3, mb: 2, width: "auto" }}
              onClick={() => {(locationType.sitterLocation || locationType.parentLocation || locationType.otherLocation) ? updateStep(2) : setSnackbar({...snackbar, isOpen: true, message: 'Please Confirm The Location', type: 'error'}) }}
            >
              Confirm and continue
            </Button>
            </Grid> 
            <SnackBar
              open={snackbar.isOpen}
              handleClose={() => setSnackbar({...snackbar, isOpen: false})}
              duration={300000}
              msg={snackbar.message}
              type={snackbar.type}
            />
    </>
)
}

export default ConstentOvernightDates