import React, { useEffect, useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { assests } from "../../../../Assets/assets";
import { Copyright, CustomLabelInput, DividerRoot, FormInput, InputField } from "../../../../Components/CustomElements";
import { useNavigate } from "react-router-dom";
import { GoogleAuth } from "../../../../Components/GoogleLogin";
import { FacebookAuth } from "../../../../Components/FacebookLogin";
import { removeItem, setItem } from "../../../../Utils/localStorage";
import Masthead from "../../../../Components/Masthead";
import { isAuthorised } from "../../../../Utils/helper";
import { Chip, Divider } from "@mui/material";

const UserSignUP = () => {
const navigate = useNavigate()
const theme = createTheme();
const handleNavigate = () => {
  removeItem('token')
  removeItem('origin')
  setItem('origin', 'email')
  navigate('/user-verification')
}
  return (
    <>
    <ThemeProvider theme={theme}>
    <Grid sx={{ display: "grid", gridTemplateRows: "auto 1fr", height: "100vh" }}>
    <Grid item sx={{ position: "sticky", top: 0, zIndex: 10 }}>
        <Masthead />
      </Grid>
      <Grid container component="main" sx={{ flexGrow: 1, overflowY: "auto" ,scrollbarWidth: "none", /* Firefox */
    "&::-webkit-scrollbar": { display: "none" } /* Chrome, Safari, Edge */}}>        <CssBaseline />
        <Grid item xs={false} sm={4} md={6} sx={{
            backgroundImage: `url(${assests.newBgImg})`,
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
            height:"100vh"
          }}
          >
          <Grid className="welcome-wrapper">
            <p className="welcome-text">Welcome to Dogstays</p>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={8} md={6} component={Paper} elevation={6} sx={{boxShadow: 'none'}}>
          <Box sx={{my: 1, mx: 4}}>
            <Box
              sx={{
                mt: 5,
                // display: "flex",
                // flexDirection: "column",
                // alignItems: "center",
                // justifyContent: "center",
              }}
            >
              <Grid container sx={{  display: 'flex', justifyContent: 'center', gap:'10px'}}>
              <Grid item xs={12}>
                <Box sx={{width:'100%', textAlign: 'center', mb:5}}>
                <Typography component='h1' sx={{fontFamily: 'brandon', fontSize:'24px', fontWeight:'bold'}}>
                  Register & Send Us Your First Request Now
                </Typography>
                <Typography component='h3' sx={{fontFamily: 'brandon', fontSize:'16px'}}>
                  The next time you need to send us a request, you will be able to do this by logging into your DogStays dashboard
                </Typography>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Grid item xs={12} sx={{ pr: 2, mb: 3}}>
                  <Button variant="contained" onClick={GoogleAuth} sx={{ width: "100%", background:'#0f4fbd', display:'flex', gap:'10px'}}>
                  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="18px" height="18px" viewBox="0 0 48 48" class="abcRioButtonSvg"><g><path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path><path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path><path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path><path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"></path><path fill="none" d="M0 0h48v48H0z"></path></g></svg>
                  Google
                  </Button>
                </Grid>
                {/* <Grid item xs={12} sx={{ pr: 2 }}>
                  <Button variant="contained" onClick={FacebookAuth} sx={{ width: "100%", background:'#4267B2'}}>
                  Facebook
                  </Button>
                </Grid> */}
              </Grid>

              <DividerRoot sx={{marginTop: "25px"}}>
                <Divider>
                  <Chip label="OR" />
                </Divider>
              </DividerRoot> 

              <Grid item xs={6}>
                <Grid item xs={12} sx={{ pr: 2, mt:3 }}>
                  <Button 
                                  className="default-btn"

                  variant="contained" onClick={handleNavigate} sx={{ width: "100%", display:'flex', gap:'10px'}}>
                    Create Account With Email
                  </Button>
                </Grid>
                
              </Grid>
                <Typography component='h3' sx={{fontFamily: 'brandon', fontSize:'16px'}}>
                  By Signing up, You agree to the Privacy policy, including Cookie Use.
                </Typography>

                <Grid item xs={6} sx={{mt:5}}>
                  <Typography component='h3' sx={{fontFamily: 'brandon', fontSize:'16px'}}>
                    Already have an account?
                  </Typography>
                  <Grid item xs={12} sx={{ pr: 2 }}>
                  <Button variant="contained"                                   className="default-btn"
 onClick={() => navigate('/login')} sx={{ width: "100%", background:'#0f4fbd'}}>
                  Sign In
                  </Button>
                </Grid>
                </Grid>
                
              </Grid>

              <Copyright sx={{ mt: 5 , position: 'fixed', bottom: '20px', right: '20px'}}/>
            </Box>
          </Box>
        </Grid>
      </Grid>
      </Grid>
    </ThemeProvider>
    </>
  );
};

export default UserSignUP;
