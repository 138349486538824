import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

 const DynamicAlertPopup = ({handleCloseDialog, confirmBtnTxt, isOpen, title, handleConfirm}) => {

  return (
    <div>
      <Dialog
        maxWidth='xs'
        fullWidth={true}
        open={isOpen}
        onClose={handleCloseDialog}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {/* {"Alert"} */}
        </DialogTitle>
        <DialogContent>
          <DialogContentText sx={{fontFamily: 'Brandon'}}>
            {title}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button sx={{fontFamily: 'Brandon'}} variant='contained'  autoFocus onClick={handleCloseDialog}>
            Cancel
          </Button>
          <Button sx={{fontFamily: 'Brandon'}} variant='contained' color='error' onClick={handleConfirm} autoFocus>
            {confirmBtnTxt}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default DynamicAlertPopup