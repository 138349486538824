import React, { useState } from "react";
import { Box, Button, Grid, NativeSelect, Typography } from "@mui/material";
import Masthead from "../../../../../../Components/Masthead";
import "../style.css";
import {
  CustomLabelInput,
  FormInput,
  InputField,
  InputTeaxtArea,
} from "../../../../../../Components/CustomElements";
import MultiUpload from "../../../../../../Components/MutipleUpload";
import { ImageUpload, addDog } from "../../../../../../Utils/Api";
import { useNavigate } from "react-router-dom";
import SnackBar from "../../../../../../Components/Snackbar";
import { BackBtn } from "../../../../../../Assets/assets";

const CreateDog = () => {
    const navigate = useNavigate()
    const [images, setImages] = useState([])
    const [imagesLength, setImagesLength] = useState(0)
    const [dogFormData, setDogFormData] = useState({
        name: null,
        breed: null,
        gender: null,
        photos: [],
        dob: null,
        is_neutered: null,
        age_category: null,
        size_category: null,
        is_friendly_with_other_dogs: null,
        is_friendly_with_other_dogs_text: null,
        is_comfortable_around_cat: null,
        is_comfortable_around_cat_text: null,
        chew_or_damage_things: null,
        chew_or_damage_things_text: null,
        can_left_alone: null,
        can_left_how_long: null,
        can_left_how_long_text: null,
        reaction_toward_children: null,
        reaction_toward_stranger: null,
        is_toilet_trained: null,
        is_toilet_trained_text: null,
        is_aggressive: null,
        is_aggressive_text: null,
        is_vaccinated: null,
        is_vaccinated_text: null,
        additional_info: null,
      });
      const {
        name,
        breed,
        gender,
        photos,
        dob,
        is_neutered,
        age_category,
        size_category,
        is_friendly_with_other_dogs,
        is_friendly_with_other_dogs_text,
        is_comfortable_around_cat,
        is_comfortable_around_cat_text,
        chew_or_damage_things,
        chew_or_damage_things_text,
        can_left_alone,
        can_left_how_long,
        can_left_how_long_text,
        reaction_toward_children,
        reaction_toward_stranger,
        is_toilet_trained,
        is_toilet_trained_text,
        is_aggressive,
        is_aggressive_text,
        is_vaccinated,
        is_vaccinated_text,
        additional_info,
      } = dogFormData;
      const [snackbar, setSnackbar] = useState({ isOpen : false, message: '', type: ''})
const inputRefs = React.useRef({
  name: null,
  breed: null,
  gender: null,
  dob:null,
  is_neutered:null,
  age_category:null,
  size_category:null,
  images:null,
  is_friendly_with_other_dogs:null,
  is_friendly_with_other_dogs_text:  null,

  is_comfortable_around_cat:null,
  is_comfortable_around_cat_text:null,
  chew_or_damage_things:null,
  chew_or_damage_things_text:null,
  can_left_alone:null,

  can_left_how_long:null,
  can_left_how_long_text: null,

  reaction_toward_children:null,

  reaction_toward_stranger:null,
  is_toilet_trained:null,
  is_toilet_trained_text:null,
  is_aggressive:null,
  is_aggressive_text:null,
  is_vaccinated:null,
  is_vaccinated_text:null,
});
  const [highlightUpload, setHighlightUpload] = useState(false);
  const showError = (field, message) => {
    const element = inputRefs.current[field];
  
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "center" });
      element.style.backgroundColor = "#FEF2F2"; // Red background for error
    }
  
    setSnackbar({ isOpen: true, message, type: "error" });
};

const clearError = (field) => {
    const element = inputRefs.current[field];
    if (element) {
      element.style.backgroundColor = ""; // Clear background
    }
};
 

  const handleCreateDog = async () => {
 
    if(name === null){
      showError("name", "Please enter your dog's name");
      return;
    } else {
      clearError("name");
    }
    if(breed === null){
      showError("breed", "Please enter your dog's breed");
      return;
    } else {
      clearError("breed");
    }
    if(gender === null){
      showError("gender","Please select a gender")
      return;
    } else {
        clearError("gender")
    }
    if(dob === null){
      showError("dob", "Please select your dog's date of birth");
      return;
    } else {
      clearError("dob");
    }

   if (is_neutered === null) {
      showError("is_neutered", "Please select an option");
      return;
  } else {
      clearError("is_neutered");
  }
  if (age_category === null) {
      showError("age_category", "Please select an option");
      return;
  } else {
      clearError("age_category");
  }
  if (size_category === null) {
      showError("size_category", "Please select an option");
      return;
  } else {
      clearError("size_category");
  }

    if (is_friendly_with_other_dogs === null) {
      showError("is_friendly_with_other_dogs", "Please select an option");
      return;
  } else {
      clearError("is_friendly_with_other_dogs");
  }
  if (is_friendly_with_other_dogs !== "Yes" && !is_friendly_with_other_dogs_text) {
    showError("is_friendly_with_other_dogs_text", "Please provide details about your dog's behavior with other dogs");
    return;
} else {
    clearError("is_friendly_with_other_dogs_text");
}
    if(is_comfortable_around_cat === null){
      showError("is_comfortable_around_cat", "Please select an option");
      return;
    }
    else {
      clearError("is_comfortable_around_cat");
  }
    if (is_comfortable_around_cat !== "Yes" && !is_comfortable_around_cat_text) {
      showError("is_comfortable_around_cat_text", "Please provide details about your dog's behavior with cats");
      return;
    }
    else {
      clearError("is_comfortable_around_cat_text");
  }
    if(chew_or_damage_things === null){
      showError("chew_or_damage_things", "Please select an option");
      return;
    }
    else {
      clearError("chew_or_damage_things");
  }
    if (chew_or_damage_things !== "No" && !chew_or_damage_things_text) {
      showError("chew_or_damage_things_text", "Please provide details about your dog's behavior with chewing or damaging things");
      return;
    }
    else{
      clearError("chew_or_damage_things_text");
    }
    if(can_left_alone === null){
      showError("can_left_alone", "Please select an option");
      return
    }
    else {
      clearError("can_left_alone");
  }
    if(can_left_alone === "Yes" && !can_left_how_long){
      showError("can_left_how_long", "Please select an option");
      return
    }
    else {
      clearError("can_left_how_long");
  }
    if(can_left_alone !== "Yes" &&  !can_left_how_long_text){
      showError("can_left_how_long_text", "Please provide a reason for why your dog cannot be left alone.");
      return
    }
    else {
      clearError("can_left_how_long_text");
  }
  if(can_left_alone === "Yes" &&  can_left_how_long === "Other" && !can_left_how_long_text){
    showError("can_left_how_long_text", "Please specify how long your dog can be left alone.");
    return
  }
  else {
    clearError("can_left_how_long_text");
}
    if(reaction_toward_children === null){
      showError("reaction_toward_children", "Please select an option");
      return
    }
    else {
      clearError("reaction_toward_children");
  }
    if(reaction_toward_stranger === null){
      showError("reaction_toward_stranger", "Please select an option");
      return
    }
    else {
      clearError("reaction_toward_stranger");
  }
    if(is_toilet_trained === null){
      showError("is_toilet_trained", "Please select an option");
      return
    }
    else {
      clearError("is_toilet_trained");
  }
    if(is_toilet_trained !== "Yes" && !is_toilet_trained_text){
      showError("is_toilet_trained_text", "Please provide details about your dog's toilet training");
      return
    }
    else {
      clearError("is_toilet_trained_text");
  }
    if(is_aggressive === null){
      showError("is_aggressive", "Please select an option");
      return
    }
    else {
      clearError("is_aggressive");
  }
    if(is_aggressive !== "No" && !is_aggressive_text){ 
      showError("is_aggressive_text", "Please provide details about your dog's aggressive behavior");
      return
    }
    else {
      clearError("is_aggressive_text");
  }
    if(is_vaccinated === null){
      showError("is_vaccinated", "Please select an option");
      return
    }
    else {
      clearError("is_vaccinated");
  }
    if(is_vaccinated !== "Yes" && !is_vaccinated_text){
      showError("is_vaccinated_text", "Please provide details about your dog's vaccination status");
      return
    }
      else {
      clearError("is_vaccinated_text");
  }
  
    // Validate image uploads separately
    if (images.length === 0) {
      setHighlightUpload(true);
      showError("images", "Oops! Looks like you have missed a question");
      return;
    }
  
    // Reset highlight state
    setHighlightUpload(false);
  
    // Validate name length
    if (!name || name.length < 3) {
      showError("name", "Oops! Name must be at least 3 characters long");
      return;
    }
  
    
    if (!breed || breed.length < 3) {
      
      showError("breed", "Oops! Breed  must be at least 3 characters long");
      return;
    }
    if (!breed || breed.length > 28) {
      
      showError("breed", "Oops! Breed must be at most 28 characters long");
      return;
    }
    // Prepare image upload
    let imgUrls = [];
  
    if (images.length > 0) {
      let formData = new FormData();
      images.forEach((res) => {
        formData.append("image", res);
      });
  
      try {
        const res = await ImageUpload(formData);
        imgUrls = res.data?.data?.url || [];
      } catch (error) {
        console.error("Image upload failed:", error);
        showError("images", "Image upload failed. Please try again.");
        return;
      }
    }
  
    // Submit form with uploaded image URLs
    try {
      const response = await addDog({
        ...dogFormData,
        photos: imgUrls,
      });
  
      if (response.data?.response_code === 80) {
        navigate("/dashboard/dog");
      } else {
        showError("form", "Failed to submit dog details. Please try again.");
      }
    } catch (error) {
      console.error("Failed to add dog:", error);
      showError("form", "An error occurred. Please try again later.");
    }
  };
  

      return (
            <Box sx={{ display: "grid", gridTemplateRows: "auto 1fr", height: "100vh" }}>
<Grid item sx={{ position: "sticky", top: 0, zIndex: 10 }}>
        <Masthead />
      </Grid>
       <Box container component="main" sx={{ flexGrow: 1, overflowY: "auto" ,scrollbarWidth: "none", /* Firefox */
                                    "&::-webkit-scrollbar": { display: "none" } /* Chrome, Safari, Edge */}}>
                <Box className="view-dog-container">
          <Box sx={{display: 'flex', alignItems: 'center'}}>
          <Box className="wrap-back-btn" onClick={() => {navigate('/dashboard/dog')}}>{BackBtn()} Back</Box>
          <Box className="wrap-back-btn" style={{color: '#f48220', marginLeft: "5px"}} onClick={() => { navigate('/dashboard') }}> /Home</Box>
          <Typography className="view-dog-heading" sx={{ mt: 5, mb: 5 }}>
              Add New Dog
            </Typography>
          </Box>

            <Box>
              <Typography
                fontFamily="Brandon"
                sx={{
                  width: "100%",
                  mb: 1,
                  fontSize: "22px",
                  textDecoration: "underline",
                }}
              >
                Basic Information
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} sx={{ display: "block" }}>
                  <Grid item xs={12}>
                    <FormInput fullWidth variant="standard">
                      <CustomLabelInput shrink="true">Dog Name <span style={{color: 'red', fontSize: '20px'}}>*</span></CustomLabelInput>
                      <InputField
                        type="text"
                        size="small"
                        placeholder="Enter Name"
                        value={name}
                        ref={(el) => (inputRefs.current.name = el)}
                        onFocus={() => {
                          if (inputRefs.current.name) {
                            inputRefs.current.name.style.backgroundColor = "";
                          }
                        }}   
                        onChange={(e) => {
                          let newValue = e.target.value.replace(/[^A-Za-z\s]/g, ''); 
                          setSnackbar((prev) => ({
                            ...prev,
                            isOpen:  newValue.length > 10, 
                            message:  newValue.length > 10 
                                ? "Dog name cannot exceed 10 characters." 
                                : "",
                            type: 'error'
                          }));
                            setDogFormData({ ...dogFormData, name: newValue.slice(0, 10) });
                          }}
                      />
                    </FormInput>
                  </Grid>
                  <Grid item xs={12}>
                    <FormInput fullWidth variant="standard">
                      <CustomLabelInput shrink="true">Dog Breed <span style={{color: 'red', fontSize: '20px'}}>*</span></CustomLabelInput>
                      <InputField
                        type="text"
                        size="small"
                        placeholder="Enter "
                        value={breed}
                        ref={(el) => (inputRefs.current.breed = el)}
                        onFocus={() => {
                          if (inputRefs.current.breed) {
                            inputRefs.current.breed.style.backgroundColor = "";
                          }
                        }}   
                        onChange={(e) => {
                          const newValue = e.target.value.replace(/[^A-Za-z\s]/g, '')
                          setDogFormData({ ...dogFormData, breed: newValue.slice(0, 28) })
                        }}
                      />
                    </FormInput>
                  </Grid>
                  <Grid item xs={12}>
                    <FormInput fullWidth variant="standard">
                      <CustomLabelInput shrink="true">Dog Gender <span style={{color: 'red', fontSize: '20px'}}>*</span></CustomLabelInput>
                      <NativeSelect
                        disableUnderline
                        defaultValue=""
                        value={gender}
                        onChange={(e) =>
                          setDogFormData({ ...dogFormData, gender: e.target.value })
                        }
                        ref={(el) => (inputRefs.current.gender = el)}
                        onFocus={() => {
                          if (inputRefs.current.gender) {
                            inputRefs.current.gender.style.backgroundColor = "";
                          }
                        }}   
                        inputProps={{
                          style: {
                            fontFamily: "Brandon",
                            padding: "8.5px 14px",
                          },
                        }}
                        sx={{
                          border: "1px solid #ced4d9",
                          borderRadius: "3px",
                          marginTop: "5px",
                        }}
                      >
                        {" "}
                        <option selected disabled value="">
                          Select Gender
                        </option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                      </NativeSelect>
                    </FormInput>
                  </Grid>
                  <Grid item xs={12}>
                    <FormInput fullWidth variant="standard">
                      <CustomLabelInput shrink="true" htmlFor="date_of_birth">
                        Date of Birth <span style={{color: 'red', fontSize: '20px'}}>*</span>
                      </CustomLabelInput>
                      <InputField
                        name="date_of_birth"
                        type="date"
                        id="date_of_birth"
                        size="small"
                        ref={(el) => (inputRefs.current.dob = el)}
                        onFocus={() => {
                          if (inputRefs.current.dob) {
                            inputRefs.current.dob.style.backgroundColor = "";
                          }
                        }}   
                        placeholder="Enter DOB"
                        InputProps={{ inputProps: { max: new Date().toISOString().substring(0,10)} }}
                        value={dob}
                        onChange={(e) =>
                          setDogFormData({ ...dogFormData, dob: e.target.value })
                        }
                      />
                    </FormInput>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={6} sx={{ display: "block" }}>
                  <Grid item xs={12}>
                    <FormInput fullWidth variant="standard">
                      <CustomLabelInput shrink="true">Neutered <span style={{color: 'red', fontSize: '20px'}}>*</span></CustomLabelInput>
                      <NativeSelect
                        disableUnderline
                        defaultValue=""
                        value={is_neutered}
                        onChange={(e) =>
                          setDogFormData({
                            ...dogFormData,
                            is_neutered: e.target.value,
                          })
                        }
                        ref={(el) => (inputRefs.current.is_neutered = el)}
                        onFocus={() => {
                          if (inputRefs.current.is_neutered) {
                            inputRefs.current.is_neutered.style.backgroundColor = "";
                          }
                        }}   
                        inputProps={{
                          style: {
                            fontFamily: "Brandon",
                            padding: "8.5px 14px",
                          },
                        }}
                        sx={{
                          border: "1px solid #ced4d9",
                          borderRadius: "3px",
                          marginTop: "5px",
                        }}
                      >
                        {" "}
                        <option selected disabled value="">
                          Select
                        </option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </NativeSelect>
                    </FormInput>
                  </Grid>
                  <Grid item xs={12}>
                    <FormInput fullWidth variant="standard">
                      <CustomLabelInput shrink="true" htmlFor=" ">
                        What age category is your dog? <span style={{color: 'red', fontSize: '20px'}}>*</span>
                      </CustomLabelInput>
                      <NativeSelect
                        disableUnderline
                        defaultValue=""
                        value={age_category}
                        ref={(el) => (inputRefs.current.age_category = el)}
                        onFocus={() => {
                          if (inputRefs.current.age_category) {
                            inputRefs.current.age_category.style.backgroundColor = "";
                          }
                        }}   
                        onChange={(e) =>
                          setDogFormData({
                            ...dogFormData,
                            age_category: e.target.value,
                          })
                        }
                        inputProps={{
                          style: {
                            fontFamily: "Brandon",
                            padding: "8.5px 14px",
                          },
                        }}
                        sx={{
                          border: "1px solid #ced4d9",
                          borderRadius: "3px",
                          marginTop: "5px",
                        }}
                      >
                        {" "}
                        <option selected disabled value="">
                          Select
                        </option>
                        <option value="young_puppy">
                          Very Young Puppy (less than 1 year old)
                        </option>
                        <option value="puppy">Puppy (less than 2 years old)</option>
                        <option value="adult_dog">
                          Adult dog (over 2 years old)
                        </option>
                        <option value="senior_dog">
                          Senior dog (above 7 years old)
                        </option>
                      </NativeSelect>
                    </FormInput>
                  </Grid>
                  <Grid item xs={12}>
                    <FormInput fullWidth variant="standard">
                      <CustomLabelInput shrink="true" htmlFor=" ">
                        What size category is your dog? <span style={{color: 'red', fontSize: '20px'}}>*</span>
                      </CustomLabelInput>
                      <NativeSelect
                        disableUnderline
                        defaultValue=""
                        value={size_category}
                        ref={(el) => (inputRefs.current.size_category = el)}
                        onFocus={() => {
                          if (inputRefs.current.size_category) {
                            inputRefs.current.size_category.style.backgroundColor = "";
                          }
                        }}   
                        onChange={(e) =>
                          setDogFormData({
                            ...dogFormData,
                            size_category: e.target.value,
                          })
                        }
                        inputProps={{
                          style: {
                            fontFamily: "Brandon",
                            padding: "8.5px 14px",
                          },
                        }}
                        sx={{
                          border: "1px solid #ced4d9",
                          borderRadius: "3px",
                          marginTop: "5px",
                        }}
                      >
                        {" "}
                        <option selected disabled value="">
                          Select
                        </option>
                        <option value="small">Small (less than 14 kilos)</option>
                        <option value="medium">
                          Medium (between 14 and 25 kilos)
                        </option>
                        <option value="large">Large (more than 25 kilos)</option>
                      </NativeSelect>
                    </FormInput>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
            <Box>
              <Typography
                fontFamily="Brandon"
                sx={{
                  width: "100%",
                  mb: 1,
                  mt: 3,
                  fontSize: "22px",
                  textDecoration: "underline",
                }}
              >
                Additional Information
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} sx={{ display: "block" }}>
                  <Grid item xs={12}>
                    <FormInput fullWidth variant="standard">
                      <CustomLabelInput shrink="true" htmlFor=" ">
                        Is your dog friendly with other dogs? <span style={{color: 'red', fontSize: '20px'}}>*</span>
                      </CustomLabelInput>
                      <NativeSelect
                        disableUnderline
                        defaultValue=""
                        ref={(el) => {
                          if (el) inputRefs.current.is_friendly_with_other_dogs = el;
                        }}
                        onFocus={() => {
                          if (inputRefs.current.is_friendly_with_other_dogs) {
                            inputRefs.current.is_friendly_with_other_dogs.style.backgroundColor = "";
                          }
                        }}  
                        value={is_friendly_with_other_dogs}
                        onChange={(e) =>
                          setDogFormData({
                            ...dogFormData,
                            is_friendly_with_other_dogs: e.target.value,
                          })
                        }
                        inputProps={{
                          style: {
                            fontFamily: "Brandon",
                            padding: "8.5px 14px",
                          },
                        }}
                        sx={{
                          border: "1px solid #ced4d9",
                          borderRadius: "3px",
                          marginTop: "5px",
                        }}
                      >
                        <option selected disabled value="">
                          Select
                        </option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                        <option value="other">It Depends</option>
                      </NativeSelect>
                    </FormInput>
                  </Grid>
    
                  {is_friendly_with_other_dogs && (
                    <Grid item xs={12}>
                      <FormInput fullWidth variant="standard">
                        <CustomLabelInput shrink="true">
                          Please share additional details here:
                        </CustomLabelInput>
                        <InputTeaxtArea
                          size="small"
                          placeholder="Enter Details"
                          minRows={2}
                          sx={{
                            fontFamily: "Brandon",
                            height: "40px",
                            fontSize: "1rem",
                            border: "1px solid #ced4d9",
                            borderRadius: "3px",
                            padding: "8.5px 14px",
                            marginTop: "5px",
                          }}
                          ref={(el) => (inputRefs.current.is_friendly_with_other_dogs_text = el)}

                          value={is_friendly_with_other_dogs_text}
                          onChange={(e) =>
                            setDogFormData({
                              ...dogFormData,
                              is_friendly_with_other_dogs_text: e.target.value,
                            })
                          }
                        />
                      </FormInput>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <FormInput fullWidth variant="standard">
                      <CustomLabelInput shrink="true">
                        Is your dog comfortable around cats? <span style={{color: 'red', fontSize: '20px'}}>*</span>
                      </CustomLabelInput>
                      <NativeSelect
                        disableUnderline
                        defaultValue=""
                        value={is_comfortable_around_cat}
                        ref={(el) => (inputRefs.current.is_comfortable_around_cat = el)}
                        onFocus={() => {
                          if (inputRefs.current.is_comfortable_around_cat) {
                            inputRefs.current.is_comfortable_around_cat.style.backgroundColor = "";
                          }
                        }}   
                        onChange={(e) =>
                          setDogFormData({
                            ...dogFormData,
                            is_comfortable_around_cat: e.target.value,
                          })
                        }
                        inputProps={{
                          style: {
                            fontFamily: "Brandon",
                            padding: "8.5px 14px",
                          },
                        }}
                        sx={{
                          border: "1px solid #ced4d9",
                          borderRadius: "3px",
                          marginTop: "5px",
                        }}
                      >
                        <option selected disabled value="">
                          Select
                        </option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                        <option value="other">Not Sure</option>
                      </NativeSelect>
                    </FormInput>
                  </Grid>
                  {is_comfortable_around_cat && (
                    <Grid item xs={12}>
                      <FormInput fullWidth variant="standard">
                        <CustomLabelInput shrink="true">
                          Please share additional details here:
                        </CustomLabelInput>
                        <InputTeaxtArea
                          size="small"
                          placeholder="Enter Details"
                          minRows={2}
                          sx={{
                            fontFamily: "Brandon",
                            height: "40px",
                            fontSize: "1rem",
                            border: "1px solid #ced4d9",
                            borderRadius: "3px",
                            padding: "8.5px 14px",
                            marginTop: "5px",
                          }}
                          ref={(el) => (inputRefs.current.is_comfortable_around_cat_text = el)}

                          value={is_comfortable_around_cat_text}
                          onChange={(e) =>
                            setDogFormData({
                              ...dogFormData,
                              is_comfortable_around_cat_text: e.target.value,
                            })
                          }
                        />
                      </FormInput>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <FormInput fullWidth variant="standard">
                      <CustomLabelInput shrink="true">
                        Does your dog chew or damage things? <span style={{color: 'red', fontSize: '20px'}}>*</span>
                      </CustomLabelInput>
                      <NativeSelect
                        disableUnderline
                        defaultValue=""
                        value={chew_or_damage_things}
                        ref={(el) => (inputRefs.current.chew_or_damage_things = el)}
                        onFocus={() => {
                          if (inputRefs.current.chew_or_damage_things) {
                            inputRefs.current.chew_or_damage_things.style.backgroundColor = "";
                          }
                        }}   
                        onChange={(e) =>
                          setDogFormData({
                            ...dogFormData,
                            chew_or_damage_things: e.target.value,
                          })
                        }
                        inputProps={{
                          style: {
                            fontFamily: "Brandon",
                            padding: "8.5px 14px",
                          },
                        }}
                        sx={{
                          border: "1px solid #ced4d9",
                          borderRadius: "3px",
                          marginTop: "5px",
                        }}
                      >
                        <option selected disabled value="">
                          Select
                        </option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                        <option value="other">others</option>
                      </NativeSelect>
                    </FormInput>
                  </Grid>
                  {chew_or_damage_things && (
                    <Grid item xs={12}>
                      <FormInput fullWidth variant="standard">
                        <CustomLabelInput shrink="true">
                          Please share additional details here:
                        </CustomLabelInput>
                        <InputTeaxtArea
                          size="small"
                          placeholder="Enter Details"
                          minRows={2}
                          sx={{
                            fontFamily: "Brandon",
                            height: "40px",
                            fontSize: "1rem",
                            border: "1px solid #ced4d9",
                            borderRadius: "3px",
                            padding: "8.5px 14px",
                            marginTop: "5px",
                          }}
                          ref={(el) => (inputRefs.current.chew_or_damage_things_text = el)}

                          value={chew_or_damage_things_text}
                          onChange={(e) =>
                            setDogFormData({
                              ...dogFormData,
                              chew_or_damage_things_text: e.target.value,
                            })
                          }
                        />
                      </FormInput>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <FormInput fullWidth variant="standard">
                      <CustomLabelInput shrink="true" htmlFor=" ">
                        Can your dog be left alone at home for some time?
                      </CustomLabelInput>
                      <NativeSelect
                        disableUnderline
                        defaultValue=""
                        value={can_left_alone}
                        ref={(el) => (inputRefs.current.can_left_alone = el)}
                        onFocus={() => {
                          if (inputRefs.current.can_left_alone) {
                            inputRefs.current.can_left_alone.style.backgroundColor = "";
                          }
                        }}   
                        onChange={(e) =>
                          setDogFormData({
                            ...dogFormData,
                            can_left_alone: e.target.value,
                          })
                        }
                        inputProps={{
                          style: {
                            fontFamily: "Brandon",
                            padding: "8.5px 14px",
                          },
                        }}
                        sx={{
                          border: "1px solid #ced4d9",
                          borderRadius: "3px",
                          marginTop: "5px",
                        }}
                      >
                        <option selected disabled value="">
                          Select
                        </option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                        <option value="other">others</option>
                      </NativeSelect>
                    </FormInput>
                  </Grid>
                  {can_left_alone  &&  can_left_alone === "Yes" &&  (
                    <Grid item xs={12}>
                      <FormInput fullWidth variant="standard">
                        <CustomLabelInput shrink="true">
                        For how long? <span style={{color: 'red'}}>*</span>
                        </CustomLabelInput>
                        <NativeSelect
                          disableUnderline
                          defaultValue=""
                          ref={(el) => (inputRefs.current.can_left_how_long = el)}
                          onFocus={() => {
                            if (inputRefs.current.can_left_how_long) {
                              inputRefs.current.can_left_how_long.style.backgroundColor = "";
                            }
                          }}   
                          value={can_left_how_long}
                          onChange={(e) =>
                            setDogFormData({
                              ...dogFormData,
                              can_left_how_long: e.target.value,
                            })
                          }
                          inputProps={{
                            style: {
                              fontFamily: "Brandon",
                              padding: "8.5px 14px",
                            },
                          }}
                          sx={{
                            border: "1px solid #ced4d9",
                            borderRadius: "3px",
                            marginTop: "5px",
                          }}
                        >
                          {" "}
                          <option selected disabled value="">
                            Select
                          </option>
                          <option value="1-2 hours">1-2 hours</option>
                          <option value="2-4 hours">2-4 hours</option>
                          <option value="4-6 hours"> 4-6 hours</option>
                          <option value="6-8 hours"> 6-8 hours</option>
                          <option value="As long as needed">
                            {" "}
                            As long as needed
                          </option>
                          <option value="Other"> other</option>
                        </NativeSelect>
                      </FormInput>
                    </Grid>
                  )}
                  {can_left_alone && 
                        <Grid item xs={12} md={10}>
                        <FormInput fullWidth variant="standard">
                          <CustomLabelInput
                            shrink="true"
                          >
                            Any additional details?
                          </CustomLabelInput>
                          <InputTeaxtArea
                            size="small"
                            placeholder="Enter Details"
                            ref={(el) => (inputRefs.current.can_left_how_long_text = el)}

                            minRows={2}
                            sx={{fontFamily: 'Brandon', height:"40px", fontSize: "1rem", border: "1px solid #ced4d9", borderRadius:'3px', padding: "8.5px 14px", marginTop:"5px"}}
                            value={can_left_how_long_text}
                            onChange={(e) => setDogFormData({...dogFormData, can_left_how_long_text: e.target.value})}
                          />
                        </FormInput>
                      </Grid>
                        }
                </Grid>
                <Grid item xs={12} md={6} sx={{ display: "block" }}>
                  <Grid item xs={12}>
                    <FormInput fullWidth variant="standard">
                      <CustomLabelInput shrink="true" htmlFor="street_name">
                        How does your dog react to children? <span style={{color: 'red', fontSize: '20px'}}>*</span>
                      </CustomLabelInput>
                      <InputTeaxtArea
                        maxRows={2}
                        value={reaction_toward_children}
                        ref={(el) => (inputRefs.current.reaction_toward_children = el)}
                        onFocus={() => {
                          if (inputRefs.current.reaction_toward_children) {
                            inputRefs.current.reaction_toward_children.style.backgroundColor = "";
                          }
                        }}   
                        onChange={(e) =>
                          setDogFormData({
                            ...dogFormData,
                            reaction_toward_children: e.target.value,
                          })
                        }
                        size="small"
                        placeholder="Enter Details"
                        sx={{
                          fontFamily: "Brandon",
                          height: "40px",
                          fontSize: "1rem",
                          border: "1px solid #ced4d9",
                          borderRadius: "3px",
                          padding: "8.5px 14px",
                          marginTop: "5px",
                        }}
                      />
                    </FormInput>
                  </Grid>
                  <Grid item xs={12}>
                    <FormInput fullWidth variant="standard">
                      <CustomLabelInput shrink="true" htmlFor="street_name">
                        How does your dog react to strangers? <span style={{color: 'red', fontSize: '20px'}}>*</span>
                      </CustomLabelInput>
                      <InputTeaxtArea
                        maxRows={2}
                        size="small"
                        value={reaction_toward_stranger}
                        ref={(el) => (inputRefs.current.reaction_toward_stranger = el)}
                        onFocus={() => {
                          if (inputRefs.current.reaction_toward_stranger) {
                            inputRefs.current.reaction_toward_stranger.style.backgroundColor = "";
                          }
                        }}   
                        onChange={(e) =>
                          setDogFormData({
                            ...dogFormData,
                            reaction_toward_stranger: e.target.value,
                          })
                        }
                        placeholder="Enter Details"
                        sx={{
                          fontFamily: "Brandon",
                          height: "40px",
                          fontSize: "1rem",
                          border: "1px solid #ced4d9",
                          borderRadius: "3px",
                          padding: "8.5px 14px",
                          marginTop: "5px",
                        }}
                      />
                    </FormInput>
                  </Grid>
                  <Grid item xs={12}>
                    <FormInput fullWidth variant="standard">
                      <CustomLabelInput shrink="true" htmlFor=" ">
                        Is your dog completely toilet trained? <span style={{color: 'red', fontSize: '20px'}}>*</span>
                      </CustomLabelInput>
                      <NativeSelect
                        disableUnderline
                        defaultValue=""
                        value={is_toilet_trained}
                        ref={(el) => (inputRefs.current.is_toilet_trained = el)}
                        onFocus={() => {
                          if (inputRefs.current.is_toilet_trained) {
                            inputRefs.current.is_toilet_trained.style.backgroundColor = "";
                          }
                        }}   
                        onChange={(e) =>
                          setDogFormData({
                            ...dogFormData,
                            is_toilet_trained: e.target.value,
                          })
                        }
                        inputProps={{
                          style: {
                            fontFamily: "Brandon",
                            padding: "8.5px 14px",
                          },
                        }}
                        sx={{
                          border: "1px solid #ced4d9",
                          borderRadius: "3px",
                          marginTop: "5px",
                        }}
                      >
                        <option selected disabled value="">
                          Select
                        </option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                        <option value="other">others</option>
                      </NativeSelect>
                    </FormInput>
                  </Grid>
                  {is_toilet_trained  && (
                    <Grid item xs={12}>
                      <FormInput fullWidth variant="standard">
                        <CustomLabelInput shrink="true">
                          Please share additional details here:
                        </CustomLabelInput>
                        <InputTeaxtArea
                          size="small"
                          placeholder="Enter Details"
                          minRows={2}
                          ref={(el) => (inputRefs.current.is_toilet_trained_text = el)}

                          sx={{
                            fontFamily: "Brandon",
                            height: "40px",
                            fontSize: "1rem",
                            border: "1px solid #ced4d9",
                            borderRadius: "3px",
                            padding: "8.5px 14px",
                            marginTop: "5px",
                          }}
                          value={is_toilet_trained_text}
                          onChange={(e) =>
                            setDogFormData({
                              ...dogFormData,
                              is_toilet_trained_text: e.target.value,
                            })
                          }
                        />
                      </FormInput>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <FormInput fullWidth variant="standard">
                      <CustomLabelInput shrink="true" htmlFor=" ">
                        Is your dog aggressive in any situation? <span style={{color: 'red', fontSize: '20px'}}>*</span>
                      </CustomLabelInput>
                      <NativeSelect
                        disableUnderline
                        ref={(el) => (inputRefs.current.is_aggressive = el)}
                        onFocus={() => {
                          if (inputRefs.current.is_aggressive) {
                            inputRefs.current.is_aggressive.style.backgroundColor = "";
                          }
                        }}   
                        defaultValue=""
                        value={is_aggressive}
                        onChange={(e) =>
                          setDogFormData({
                            ...dogFormData,
                            is_aggressive: e.target.value,
                          })
                        }
                        inputProps={{
                          style: {
                            fontFamily: "Brandon",
                            padding: "8.5px 14px",
                          },
                        }}
                        sx={{
                          border: "1px solid #ced4d9",
                          borderRadius: "3px",
                          marginTop: "5px",
                        }}
                      >
                        <option selected disabled value="">
                          Select
                        </option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                        <option value="other">others</option>
                      </NativeSelect>
                    </FormInput>
                  </Grid>
                  {is_aggressive  && (
                    <Grid item xs={12}>
                      <FormInput fullWidth variant="standard">
                        <CustomLabelInput shrink="true">
                          Please share additional details here:
                        </CustomLabelInput>
                        <InputTeaxtArea
                          size="small"
                          ref={(el) => (inputRefs.current.is_aggressive_text = el)}

                          placeholder="Enter Details"
                          minRows={2}
                          sx={{
                            fontFamily: "Brandon",
                            height: "40px",
                            fontSize: "1rem",
                            border: "1px solid #ced4d9",
                            borderRadius: "3px",
                            padding: "8.5px 14px",
                            marginTop: "5px",
                          }}
                          value={is_aggressive_text}
                          onChange={(e) =>
                            setDogFormData({
                              ...dogFormData,
                              is_aggressive_text: e.target.value,
                            })
                          }
                        />
                      </FormInput>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <FormInput fullWidth variant="standard">
                      <CustomLabelInput shrink="true" htmlFor=" ">
                        Is your dog fully vaccinated? <span style={{color: 'red', fontSize: '20px'}}>*</span>
                      </CustomLabelInput>
                      <NativeSelect
                        disableUnderline
                        defaultValue=""
                        value={is_vaccinated}
                        ref={(el) => (inputRefs.current.is_vaccinated = el)}
                        onFocus={() => {
                          if (inputRefs.current.is_vaccinated) {
                            inputRefs.current.is_vaccinated.style.backgroundColor = "";
                          }
                        }}   
                        onChange={(e) =>
                          setDogFormData({
                            ...dogFormData,
                            is_vaccinated: e.target.value,
                          })
                        }
                        inputProps={{
                          style: {
                            fontFamily: "Brandon",
                            padding: "8.5px 14px",
                          },
                        }}
                        sx={{
                          border: "1px solid #ced4d9",
                          borderRadius: "3px",
                          marginTop: "5px",
                        }}
                      >
                        <option selected disabled value="">
                          Select
                        </option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                        <option value="other">others</option>
                      </NativeSelect>
                    </FormInput>
                  </Grid>
                  {is_vaccinated  && (
                    <Grid item xs={12}>
                      <FormInput fullWidth variant="standard">
                        <CustomLabelInput shrink="true">
                          Please share additional details here:
                        </CustomLabelInput>
                        <InputTeaxtArea
                          size="small"
                          placeholder="Enter Details"
                          minRows={2}
                          ref={(el) => (inputRefs.current.is_vaccinated_text = el)}

                          sx={{
                            fontFamily: "Brandon",
                            height: "40px",
                            fontSize: "1rem",
                            border: "1px solid #ced4d9",
                            borderRadius: "3px",
                            padding: "8.5px 14px",
                            marginTop: "5px",
                          }}
                          value={is_vaccinated_text}
                          onChange={(e) =>
                            setDogFormData({
                              ...dogFormData,
                              is_vaccinated_text: e.target.value,
                            })
                          }
                        />
                      </FormInput>
                    </Grid>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <FormInput fullWidth variant="standard">
                    <CustomLabelInput shrink="true">
                      Is there anything else you would like us or the dog sitter to
                      know?
                    </CustomLabelInput>
                    <InputTeaxtArea
                      minRows={2}
                      value={additional_info}
                      sx={{
                        fontFamily: "Brandon",
                        height: "40px",
                        fontSize: "1rem",
                        border: "1px solid #ced4d9",
                        borderRadius: "3px",
                        padding: "8.5px 14px",
                        marginTop: "5px",
                      }}
                      size="small"
                      placeholder="Enter Details"
                      onChange={(e) => setDogFormData({...dogFormData, additional_info: e.target.value})}
                    />
                  </FormInput>
                </Grid>
              </Grid>
            </Box>
            <Typography
              fontFamily="Brandon"
              sx={{
                width: "100%",
                mb: 1,
                mt: 3,
                fontSize: "22px",
                textDecoration: "underline",
              }}
            >
              Uploads
            </Typography>
            <MultiUpload 
            text='Please upload at least one and maximum 3 pictures of your dog. We need these to find you
            the right dog sitter. (accepted formats: JPEG, JPG, PNG and file size: up to 5 MB each)'
            imageAssets={(res) => {
              setImages(res)
              setImagesLength(res.length)
            }}
            limit={3}
            highlight={highlightUpload}
            inputRef={inputRefs}
            />
          </Box>
          <Box sx={{textAlign: 'center'}}>
          <Button className="default-btn" onClick={handleCreateDog} variant="contained" sx={{ mt: 3, mb: 5, width: "25%"}}>Add Dog</Button>
          </Box>
          </Box>
          <SnackBar
            open={snackbar.isOpen}
            handleClose={() => setSnackbar({...snackbar, isOpen: false})}
            duration={300000}
            msg={snackbar.message}
            type={snackbar.type}
          />
        </Box>
      );
}

export default CreateDog