import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, Grid, Typography } from '@mui/material';
import { CustomLabelInput, FormInput, InputField } from '../CustomElements';
import { useState } from 'react';
import { forgotPasswordSendLink } from '../../Utils/Api';
import SnackBar from '../Snackbar';
import VerifiedIcon from '@mui/icons-material/Verified';

 const ForgotPasswordDialog = ({handleCloseDialog, isOpen}) => {
  const [snackbar, setSnackbar] = useState({isOpen: false, message: "", type: ""})
  const [email,  setEmail] = useState('')
  const [errorMsg, setErrorMsg] = useState('')
  const [isPasswordLinkSend, setIsPasswordLinkSend] = useState(false)

  const handleResetPassword = () => {
    if(email !== ""){
        forgotPasswordSendLink({email: email}).then((res) => {
            if(res.data.response_code == 80) {
                setIsPasswordLinkSend(true)
                setSnackbar({...snackbar, isOpen: true, message: 'Email sent!', type: 'success'});
                setEmail('')
            }else{
                setErrorMsg(res.data.ui_message)
                setEmail('')
                setTimeout(() => {
                    setErrorMsg('')
                }, 10000);
            }
        })
    }else{
        setSnackbar({...snackbar, isOpen: true, message: 'Please Enter the Email', type: 'error'})
    }
  }

  return (
    <div>
      <Dialog
        maxWidth='sm'
        fullWidth={true}
        open={isOpen}
        onClose={handleCloseDialog}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle sx={{fontFamily: 'Brandon', textAlign: 'center'}} id="responsive-dialog-title">
          {"Forgot Password"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText sx={{fontFamily: 'Brandon'}}>
            {/* Do you want to delete this Address ? */}
          </DialogContentText>
          {!isPasswordLinkSend ? <>
            <Grid xs={12} container>
            <Grid item xs={12}>
                    <FormInput fullWidth variant="standard">
                    <CustomLabelInput shrink="true" htmlFor="date_of_birth">
                        Email
                    </CustomLabelInput>
                    <InputField
                        type='email'
                        size="small"
                        placeholder="Enter Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    </FormInput>
                </Grid>
            </Grid>
            {errorMsg && <Typography variant="caption" color={'red'}>{errorMsg}</Typography>} 
            </>
            :
            <>
            <Box>
                <Box sx={{textAlign: 'center'}}>
                    <VerifiedIcon sx={{color: '#05a005', fontSize: '60px'}}/>
                </Box>
                <Box sx={{display: 'flex', justifyContent: 'center'}}>
                    <Box>
                        <Typography my={0} sx={{mt:2, fontFamily: 'Brandon', fontSize: '18px', textAlign: 'center'}}>Reset password link sent on email! Check email</Typography>
                    </Box>
                </Box>
            </Box>
            </>
            }
        </DialogContent>
        <DialogActions>
          <Button className="default-btn"  onClick={() => {handleCloseDialog()}}>Close</Button>
          {!isPasswordLinkSend && <Button className="default-btn" onClick={handleResetPassword}>Get Link</Button>}
        </DialogActions>
      </Dialog>
      <SnackBar
        open={snackbar.isOpen}
        handleClose={() => setSnackbar({ ...snackbar, isOpen: false })}
        duration={300000}
        msg={snackbar.message}
        type={snackbar.type}
      />
    </div>
  );
}

export default ForgotPasswordDialog