
export const isAuthorised = () => {
  const token = window.localStorage.getItem('token')
  const authStore = window.localStorage.getItem('AuthStore')
  let path;
  if (token && authStore) {
      const serialisedData = JSON.parse(authStore)
      if(serialisedData.profile_status == "pending"){
          if(serialisedData.profile_step == "mobile_verification"){
            path = '/user-verification'
          }else if(serialisedData.profile_step == "user_form"){
            path = '/user-registration'
          }else if(serialisedData.profile_step == "add_dog"){
            path = '/dog-registration'
          }
        }
        if(serialisedData.profile_status == "completed"){
          path = '/dashboard'
        }
      return path
  } else {
    path = '/login'
    localStorage.clear()
    return path
  }
}


export const formatArrayForCountry = (arr) => {
  let updatedArray = []
  for (let i in arr) {
    updatedArray.push({ key: arr, label: arr, value: arr })
  }
  return updatedArray
}

export const calculateOvernightTotalDays = (startDate, endDate) => {
  if (!startDate?.date || !startDate?.time || !endDate?.date || !endDate?.time) {
    return "0 Days"; 
  }

  const dateA = new Date(`${startDate.date}T${startDate.time}`);
  const dateB = new Date(`${endDate.date}T${endDate.time}`);

  if (isNaN(dateA) || isNaN(dateB) || dateA >= dateB) {
    return "0 Days"; 
  }

  const timeDifferenceInMilliseconds = dateB - dateA;
  const timeDifferenceInHours = timeDifferenceInMilliseconds / (1000 * 60 * 60);

  if (timeDifferenceInHours <= 24) {
    return "1 Day"; 
  }

  let fullDays = Math.floor(timeDifferenceInHours / 24); // Count full 24-hour periods
  let remainingHours = timeDifferenceInHours % 24; // Get the leftover hours

  if (remainingHours > 1) { // Ignore the first hour after a full day
    if (remainingHours <= 6) {
      return `${fullDays + 0.5} Days`; // If remaining hours are 2-6, count as half a day
    } else {
      return `${fullDays + 1} Days`; // If more than 6 hours, count as a full day
    }
  }

  return `${fullDays} Days`; // If only 1 hour remains after full days, ignore it
};





export const redirectToUrl = (url) => {
  window.location.assign(url)
}