import { Box, Button, Checkbox, FormControlLabel, Grid, NativeSelect, Switch, Typography } from "@mui/material";
import { FormInput, InputField, CustomLabelInput, InputTeaxtArea } from "../../../../../Components/CustomElements";
import DatePicker from "react-multi-date-picker";
import dayjs from "dayjs"
import { LocalizationProvider, MobileTimePicker } from "@mui/x-date-pickers"
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useEffect, useState } from "react";
import CancelIcon from '@mui/icons-material/Cancel';
import SnackBar from "../../../../../Components/Snackbar";
import { updateBookingById } from "../../../../../Utils/Api";


const ConstentDaycareDates = ({data, updateStep, bookingAt, selectedAddress}) => {
    const [dayCareSelectDates, setDayCareSelectDates] = useState([])
    const [modifiedDaycareData, setModifiedDaycareData] = useState([]);
    const [daycareDataDaysTime, setDaycareDaysTime] = useState(0);
    const [locationType, setLocationType] = useState({ sitterLocation: false, parentLocation: false, otherLocation: false})
    const [snackbar, setSnackbar] = useState({ isOpen : false, message: '', type: ''})
    const [editDates, setEditDates] = useState({ editBtnText: 'Change', isFieldsDisabled: true})
    const [isGlobalChangeDateEnabled, setIsGlobalChangeDateEnabled] = useState(false)
    const [changeGlobalStartDate, setChangeGlobalStartDate] = useState()
    const [changeGlobalEndDate, setChangeGlobalEndDate] = useState()
    
    useEffect(() => {
      if(locationType.sitterLocation){
        selectedAddress('')
      }
    },[locationType])

    function calculateTimeDifference(startTime, endTime) {
        if(startTime && endTime){
        const [hours1, minutes1] = startTime.split(':').map(Number);
        const [hours2, minutes2] = endTime.split(':').map(Number);
        const totalMinutes1 = hours1 * 60 + minutes1;
        const totalMinutes2 = hours2 * 60 + minutes2;
        let timeDifference = totalMinutes2 - totalMinutes1;
        const hoursDifference = Math.floor(timeDifference / 60);
        const minutesDifference = timeDifference % 60;
        return `${hoursDifference}:${minutesDifference >= 10 ? minutesDifference : `0${minutesDifference}`}`
      } else return
      }
    
      const calculateHoursToMins = (hours) => {
        if(hours){
          const [hour, minutes] = hours.split(':').map(Number);
          const totalMinutes = hour * 60 + minutes;
          return totalMinutes
        }
      }
    
      const handledDayCareMultipleDates = (data) => {
        let mappedData = []
        const convertDate = data.map((value) => value.year + '-' + value.month + '-' + value.day)
        const newDateObject = convertDate.filter(date => !modifiedDaycareData.some(item => item.date === date))
        const filteredArr = modifiedDaycareData.filter((item) => convertDate.includes(item.date));
        const modifiedJson = newDateObject.map((item) => {
          const data = {
            date: item,
            startTime: '',
            endTime: '',
            totalHours: '',
            day_type: '' // Full or half day
          }
          mappedData.push(data)
        })
        setDayCareSelectDates(convertDate)
        // setModifiedDaycareData([ ...modifiedDaycareData, ...mappedData ])
        mappedData.length > 0 ? setModifiedDaycareData([ ...modifiedDaycareData, ...mappedData ]) : setModifiedDaycareData(filteredArr)
      }

      const setGlobalStartDate = (time) => {
        let arr = []
        if(isGlobalChangeDateEnabled && time){
          setChangeGlobalStartDate(time.format('HH:mm'))
        modifiedDaycareData.map((item) => {
          return arr.push({
            ...item,
            startTime: time.format('HH:mm'),
            totalHours: calculateTimeDifference(time.format('HH:mm'), changeGlobalEndDate),
            day_type: calculateHoursToMins(calculateTimeDifference(time.format('HH:mm'), changeGlobalEndDate)) == 0 ? '' : calculateHoursToMins(calculateTimeDifference(time.format('HH:mm'), changeGlobalEndDate)) > 601 ? "One Half" : calculateHoursToMins(calculateTimeDifference(time.format('HH:mm'), changeGlobalEndDate)) > 301 ? "Full Day" : "Half Day"})
        })
        setModifiedDaycareData(arr)}
        else return
      }
      const setGlobalEndDate = (time) => {
        let arr = []
        if(isGlobalChangeDateEnabled && time){
          setChangeGlobalEndDate(time.format('HH:mm'))
        modifiedDaycareData.map((item) => {
          return arr.push({
            ...item,
            endTime: time.format('HH:mm'),
            totalHours: calculateTimeDifference(changeGlobalStartDate, time.format('HH:mm')),
             day_type: calculateHoursToMins(calculateTimeDifference(changeGlobalStartDate, time.format('HH:mm'))) == 0 ? '' : calculateHoursToMins(calculateTimeDifference(changeGlobalStartDate, time.format('HH:mm'))) > 601 ? "One Half" : calculateHoursToMins(calculateTimeDifference(changeGlobalStartDate, time.format('HH:mm'))) > 301 ? "Full Day" : "Half Day"
           })
        })
        setModifiedDaycareData(arr)}
        else return
      }

      const dayType = []

      useEffect(() => {
        modifiedDaycareData.map((item) => {
          if(item.day_type !== ''){
            dayType.push(item.day_type)
          }
          const convertedArray = dayType.map(day => {
            if (day === 'Half Day') {return 0.5}
            else if (day === 'Full Day') {return 1}
            else if (day === 'One Half') {return 1.5}
            return day;
          });
          let totalDaysSum = 0;
          convertedArray.forEach( num => {totalDaysSum += num})
          setDaycareDaysTime(totalDaysSum)
        return
        })
      }, [modifiedDaycareData]);


      useEffect(() => {
        const convertDate = data.daycare_dates.map((value) => value.date)
        data.day_care_type == 'one_time' && setModifiedDaycareData(data.daycare_dates)
        data.day_care_type == 'one_time' && setDayCareSelectDates([ ...dayCareSelectDates, ...convertDate ])
        data.daycare_dates.length == 0 &&  data.day_care_type == 'weekly' && setEditDates({...editDates, isFieldsDisabled: false})
      }, [data]);

      const handleUpdateDaycareBooking = () => {
        updateBookingById(data.booking_id, {
            daycare_dates: modifiedDaycareData
        }).then((res) => {
          if(res.data.response_code == 80) {
             setSnackbar({...snackbar, isOpen: true, message: 'Dates Updated', type: 'success'})
             setEditDates({...editDates, isFieldsDisabled: !editDates.isFieldsDisabled})
          }
        }) 
        
      }
      

return(
    <>
      {data.service_type == "Daycare" && data.day_care_type == 'weekly' && <Box>
        <p className="label-title" style={{fontFamily: 'Brandon'}}>Service Type - <span style={{color:'#fe7648'}}>{data.service_type} { data.service_type == "Daycare" && (data.day_care_type == 'one_time' ? "(One Time)" : "(Weekly)")}</span></p>
        {/* <p className="label-title" style={{fontFamily: 'Brandon'}}>Selected Days - <span style={{color:'#fe7648'}}>{data.dates}</span></p> */}
      </Box>}
        <Grid item xs={12}>
          <FormInput fullWidth variant="standard">
            <CustomLabelInput shrink="true">
              Select Dates <span style={{color: 'red', fontSize: '20px'}}>*</span>
            </CustomLabelInput>
              <DatePicker
                className="date-picker-layout"
                multiple
                onChange={handledDayCareMultipleDates}
                value={dayCareSelectDates}
                dateFormat="DD-MM-YYYY"
                disabled={editDates.isFieldsDisabled}
                minDate={new Date()}
              />
              </FormInput>
          </Grid>
          {!editDates.isFieldsDisabled && <div>
            <p style={{fontFamily: 'Brandon', fontSize: '14px', color: 'red', margin: '10px 0px 0px 0px'}}>Please switch this on if you wish to use the same start time and end time for all dates</p>

            <Grid xs={12} sx={{display: 'flex', gap:'10px'}}>
                <Grid xs={1}>
                <FormInput fullWidth variant="standard">
                    <CustomLabelInput shrink="true">
                    Set default Time
                    </CustomLabelInput>
                    <Switch defaultChecked checked={isGlobalChangeDateEnabled} color="warning" onChange={() => {
                      setIsGlobalChangeDateEnabled(!isGlobalChangeDateEnabled)
                      if(isGlobalChangeDateEnabled){
                        setGlobalStartDate()
                        setGlobalEndDate()
                      } else return
                    }}/>
                </FormInput>
                </Grid>
                
                <Grid xs={5}>
                  <FormInput fullWidth variant="standard">
                    <CustomLabelInput shrink="true">
                    Start Time
                    </CustomLabelInput>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <MobileTimePicker
                      sx={{marginTop: '5px'}}
                      // value={item.startTime}
                      ampm={false}
                      closeOnSelect={true}
                      onChange={setGlobalStartDate}
                      />
                    </LocalizationProvider>            
                    </FormInput>
                  </Grid>
                  <Grid xs={5}>
                  <FormInput fullWidth variant="standard">
                    <CustomLabelInput shrink="true">
                    End Time
                    </CustomLabelInput>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <MobileTimePicker
                      sx={{marginTop: '5px'}}
                      // defaultValue={dayjs('2022-04-17T18:30')} 
                      // value={item.startTime}
                      minTime={dayjs(changeGlobalStartDate, "HH:mm")}
                      ampm={false}
                      closeOnSelect={true}
                      onChange={setGlobalEndDate}
                      />
                    </LocalizationProvider>            
                    </FormInput>
                  </Grid>
                </Grid>
            </div>}
            <Grid item xs={12}>
                 {modifiedDaycareData.map((item, index) => {
                   return(
                   <div className="events-wrapper">
                     {!editDates.isFieldsDisabled && <span onClick={() => {
                       const calenderItems = [...dayCareSelectDates];
                       const modifyItems = [...modifiedDaycareData];
                       calenderItems.splice(index, 1);
                       modifyItems.splice(index, 1);
                       setModifiedDaycareData(modifyItems);
                       setDayCareSelectDates(calenderItems);
                     }}><CancelIcon style={{ color: "#fe7648" }}/></span>}
                     <Grid xs={12} sm={12} >
                     <FormInput fullWidth variant="standard">
                       <CustomLabelInput shrink="true">
                       Selected Dates
                       </CustomLabelInput>
                       <InputField
                         type="text"
                         size="small"
                         disabled
                         value={item.date}
                       />
                     </FormInput>
                     </Grid>
                     <Grid xs={12} sm={12} >
                     <FormInput fullWidth variant="standard">
                       <CustomLabelInput shrink="true">
                       Start Time
                       </CustomLabelInput>
                       <LocalizationProvider dateAdapter={AdapterDayjs}>
                         <MobileTimePicker
                          sx={{marginTop: '5px'}}
                          value={dayjs(`${item.date}T${item.startTime}`)}
                          ampm={false}
                          closeOnSelect={true}
                          disabled={editDates.isFieldsDisabled}
                          onChange={(time) => {
                           const updatedData = { ...item, startTime: time.format('HH:mm'), totalHours: calculateTimeDifference(time.format('HH:mm'), item.endTime), day_type: calculateHoursToMins(calculateTimeDifference(time.format('HH:mm'), item.endTime)) == 0 ? '' : calculateHoursToMins(calculateTimeDifference(time.format('HH:mm'), item.endTime)) > 601 ? "One Half" : calculateHoursToMins(calculateTimeDifference(time.format('HH:mm'), item.endTime)) > 301 ? "Full Day" : "Half Day"};
                           setModifiedDaycareData((prevData) => {
                             const newData = [...prevData];
                             newData[index] = updatedData;
                             return newData;
                           });
                          }}
                          />
                       </LocalizationProvider>            
                       </FormInput>
                       </Grid>
                       <Grid xs={12} sm={12} >
                     <FormInput fullWidth variant="standard">
                       <CustomLabelInput shrink="true">
                       End Time
                       </CustomLabelInput>
                       <LocalizationProvider dateAdapter={AdapterDayjs}>
                         <MobileTimePicker
                           sx={{marginTop: '5px'}}
                           value={dayjs(`${item.date}T${item.endTime}`)}
                           ampm={false}
                           disabled={editDates.isFieldsDisabled}
                           onChange={(time) => {
                           const updatedData = { ...item, endTime: time.format('HH:mm'), totalHours: calculateTimeDifference(item.startTime, time.format('HH:mm')), day_type: calculateHoursToMins(calculateTimeDifference(item.startTime, time.format('HH:mm'))) == 0 ? '' : calculateHoursToMins(calculateTimeDifference(item.startTime, time.format('HH:mm'))) > 601 ? "One Half" : calculateHoursToMins(calculateTimeDifference(item.startTime, time.format('HH:mm'))) > 301 ? "Full Day" : "Half Day"};
                           setModifiedDaycareData((prevData) => {
                             const newData = [...prevData];
                             newData[index] = updatedData;
                             return newData;
                           });
                          }}
                         />
                       </LocalizationProvider>
                     </FormInput>
                     </Grid>
                     <Grid xs={12} sm={12} >
                     <FormInput fullWidth variant="standard">
                       <CustomLabelInput shrink="true">
                        No of Hours
                       </CustomLabelInput>
                       <InputField
                         type="text"
                         size="small"
                         placeholder="Hours"
                         disabled
                         value={item.totalHours}
                       />
                     </FormInput>
                     </Grid>
                     <Grid xs={12} sm={12} >
                     <FormInput fullWidth variant="standard">
                       <CustomLabelInput shrink="true">
                        No of Days
                       </CustomLabelInput>
                       <InputField
                         type="text"
                         size="small"
                         placeholder="Days"
                         disabled
                         value={item.day_type}
                       />
                     </FormInput>
                     </Grid>
                   </div>)
                 })}
                 {modifiedDaycareData.length > 0 && 
                 <Grid sx={{display: 'flex', justifyContent:'end', gap:'15px', marginRight:'10px'}}>
                   {/* <Typography component="h4" color="text.secondary" fontWeight='bold' fontFamily='brandon'>{`Total hours: ${hours}`}</Typography> */}
                   <Typography component="h4" color="text.secondary" fontWeight='bold' fontFamily='brandon'>{`Total days: ${daycareDataDaysTime}`}</Typography>
                 </Grid>
                 }
                </Grid>
            
                <Grid item xs={12}>
          <FormInput fullWidth variant="standard">
              <CustomLabelInput htmlFor="hear_from_source">
              Where will the booking happen? You can only choose one option. <span style={{color: 'red', fontSize: '20px'}}>*</span>
              </CustomLabelInput>
              <Box className="location-options">
                {data && data.location_type && data.location_type.length > 0 &&  <FormControlLabel label="At dog sitter's home" control={<Checkbox checked={locationType.sitterLocation} disabled={locationType.parentLocation || locationType.otherLocation} onChange={() => {setLocationType({...locationType, sitterLocation: !locationType.sitterLocation}); bookingAt('sitter')}} sx={{ color: '#fe7648', '&.Mui-checked': { color: '#fe7648' }, }}/>} />}
                {data && data.location_type && data.location_type.length > 0 && <FormControlLabel label="At my home address" control={<Checkbox checked={locationType.parentLocation} disabled={locationType.sitterLocation || locationType.otherLocation} onChange={() => {setLocationType({...locationType, parentLocation: !locationType.parentLocation}); bookingAt('parent')}} sx={{ color: '#fe7648', '&.Mui-checked': { color: '#fe7648' }, }}/>} />}
                {/* {data && data.location_type && data.location_type.length > 0 && <FormControlLabel label="At a new address" control={<Checkbox checked={locationType.otherLocation} disabled={locationType.parentLocation || locationType.sitterLocation} onChange={() => {setLocationType({...locationType, otherLocation: !locationType.otherLocation}); bookingAt('parent')}} sx={{ color: '#fe7648', '&.Mui-checked': { color: '#fe7648' }, }}/>} />} */}
              </Box>
            </FormInput>
        </Grid>
        {(locationType.parentLocation) && <Grid item xs={12}>
          <FormInput fullWidth variant="standard">
              <CustomLabelInput htmlFor="hear_from_source">
                At Parent Address
              </CustomLabelInput>
              <NativeSelect
                disableUnderline
                defaultValue=""
                // value={is_neutered}
                onChange={(e) => selectedAddress(e.target.value)} 
                inputProps={{
                  style: {
                    fontFamily: "Brandon",
                    padding: "8.5px 14px",
                  },
                }}
                sx={{
                  border: "1px solid #ced4d9",
                  borderRadius: "3px",
                  marginTop: "5px",
                }}
              >
                <option selected disabled value="">
                  Select
                </option>
                {data.parent_all_address.map((item) => {
                  return(
                    <option value={item.id}>{item.street_name + ' ' + item.postal_code + ' ' + item.city + ' ' + item.country}</option>
                  )
                })}
              </NativeSelect>
            </FormInput>
        </Grid>}
        {(locationType.otherLocation) && <Grid item xs={12}>
          <FormInput fullWidth variant="standard">
              <CustomLabelInput htmlFor="hear_from_source">
                At Other Address
              </CustomLabelInput>
              <InputTeaxtArea
                value={`${data?.manual_address?.street_name || ''} ${data?.manual_address?.city || ''} ${data?.manual_address?.country || ''} ${data?.manual_address?.postal_code || ''}`}
                disabled
                minRows={2}
                sx={{fontFamily: 'Brandon', height:"40px", fontSize: "1rem", border: "1px solid #ced4d9", borderRadius:'3px', padding: "8.5px 14px", marginTop:"5px"}}
                size="small"
                placeholder="Enter Details"
              />
            </FormInput>
        </Grid>}

        <Grid item xs= {12} sx={{display: 'flex', justifyContent: 'center', gap: '10px'}}> 
            <Button
              type="submit"
              className={editDates.isFieldsDisabled  ? "default-btn" : 'cancel-action-btn'}
              variant="contained"
              sx={{ mt: 3, mb: 2, width: "auto" }}
              onClick={() =>  {setEditDates({...editDates, isFieldsDisabled: !editDates.isFieldsDisabled})}}
            >
              {editDates.isFieldsDisabled ? 'Change' : 'Undo'}
            </Button>
            {!editDates.isFieldsDisabled && <Button
              type="submit"
              className="default-btn"
              variant="contained"
              sx={{ mt: 3, mb: 2, width: "auto" }}
              onClick={handleUpdateDaycareBooking}
            >
              Update Dates
            </Button>}
            </Grid>

        <Grid item xs= {12} sx={{display: 'flex', justifyContent: 'center'}}> 
            <Button
              type="submit"
              className="default-btn"
              variant="contained"
              sx={{ mt: 3, mb: 2, width: "auto" }}
              onClick={() => {(locationType.sitterLocation || locationType.parentLocation || locationType.otherLocation) && modifiedDaycareData.length != 0 ?
                updateStep(2) : setSnackbar({...snackbar, isOpen: true, message: 'Please Select Dates And Confirm The Location', type: 'error'})
              }}
            >
              Confirm and continue
            </Button>
        </Grid> 
        <SnackBar
              open={snackbar.isOpen}
              handleClose={() => setSnackbar({...snackbar, isOpen: false})}
              duration={300000}
              msg={snackbar.message}
              type={snackbar.type}
            />
    </>
)
}

export default ConstentDaycareDates