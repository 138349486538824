import React, { useEffect } from "react";
import { useState } from "react";
import Grid from "@mui/material/Grid";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { assests } from "../../../../Assets/assets";
import SnackBar from "../../../../Components/Snackbar";
import BookingRequestForm from "../../../../Components/BookingRequestForm";
import { isAuthorised } from "../../../../Utils/helper";
import Masthead from "../../../../Components/Masthead";

const DogBookingRequestLayout = () => {
  const theme = createTheme() 
  const [snackbar, setSnackbar] = useState({ isOpen: false, message: "", type: ""})

  useEffect(() => {
    // isAuthorised()
  }, [])

  return (
    <>
      <ThemeProvider theme={theme}>
      <Grid sx={{ display: "grid", gridTemplateRows: "auto 1fr", height: "100vh" }}>
      <Grid item sx={{ position: "sticky", top: 0, zIndex: 10 }}>
        <Masthead/>
        </Grid>
        <Grid container component="main" sx={{ flexGrow: 1, overflowY: "auto" ,scrollbarWidth: "none", /* Firefox */
    "&::-webkit-scrollbar": { display: "none" } /* Chrome, Safari, Edge */}}>          <Grid
            item
            xs={false}
            sm={4}
            md={4}
            sx={{
              backgroundImage: `url(${assests.newBgImg})`,
              backgroundRepeat: "no-repeat",
              backgroundColor: (t) =>
                t.palette.mode === "light"
                  ? t.palette.grey[50]
                  : t.palette.grey[900],
              backgroundSize: "cover",
              backgroundPosition: "center",
              height: '100vh'
            }}
            >
            <Grid className="welcome-wrapper">
              <p className="welcome-text">Welcome to Dogstays</p>
            </Grid>
          </Grid>
          <Grid
            className="booking-wrapper"
            item
            xs={12}
            sm={8}
            md={8}
            elevation={6}
            sx={{ backgroundColor: "#ffff", height:'100%', overflowY: 'scroll'}}
          >
            <div
              className="wrapper"
              style={{
                backgroundColor: "white",
                padding: "15px 20px",
                borderRadius: "10px",
              }}
            >
              <BookingRequestForm />
            </div>
          </Grid>
        </Grid>
        </Grid>
      </ThemeProvider>
      <SnackBar
        open={snackbar.isOpen}
        handleClose={() => setSnackbar({ ...snackbar, isOpen: false })}
        duration={300000}
        msg={snackbar.message}
        type={snackbar.type}
      />
    </>
  );
};
export default DogBookingRequestLayout;
