import React, { useEffect, useState } from "react"
import Masthead from "../../../../../Components/Masthead"
import { Avatar, Box, Button, Grid, Pagination, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material"
import { useNavigate } from "react-router-dom"
import { BackBtn } from "../../../../../Assets/assets"
import ConfirmationTextDialog from "../../../../../Components/Dialogs/confirmationTxt"
import { getPaymentList } from "../../../../../Utils/Api"

const MyPayments = () => {
    const navigate = useNavigate()
    const [paymentList, setPaymentList] = useState([])
    const [count, setCount] = useState()
    const [page, setPage] = useState(1)
    const [loading, setLoading] = useState(false); // Added loading state


    useEffect(() => {
        setLoading(true);

        getPaymentList(1).then((res) => {
            setPaymentList(res.data.data.results)
            setCount(res.data.data.count)
            setLoading(false); // Stop loading

        }).catch(() => setLoading(false));
    }, [])

    const handleChangePage = (e, val) => {
        getPaymentList(val).then((res) => {
            setPaymentList(res.data.data.results)
        })
        setPage(val)
    }

    return (
        <Box  sx={{ display: "grid", gridTemplateRows: "auto 1fr", height: "100vh" }}>
         <Grid item sx={{ position: "sticky", top: 0, zIndex: 10 }}>
        <Masthead />
      </Grid>  
      <Box container component="main" sx={{ flexGrow: 1, overflowY: "auto" ,scrollbarWidth: "none", /* Firefox */
            "&::-webkit-scrollbar": { display: "none" } /* Chrome, Safari, Edge */}}>
            <Box className='profile-container' sx={{ maxWidth: "85%", margin: "auto", mb: 5 }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box className="wrap-back-btn" onClick={() => { navigate('/dashboard') }}>{BackBtn()} Back</Box>
                    <Box className="wrap-back-btn" style={{ color: '#f48220', marginLeft: "5px" }} onClick={() => { navigate('/dashboard') }}> /Home</Box>
                    <Typography className="view-dog-heading" sx={{ mt: 5, mb: 5 }}>
                        My Payments
                    </Typography>
                </Box>
                {loading ? (
    <Typography className="view-dog-heading" sx={{ mt: 5, mb: 5, color: '#f48221' }}>
        Loading...
    </Typography>
) : paymentList.length > 0 ? (
    <Grid container xs={12}>
        <TableContainer component={Paper} sx={{ background: '#f4822021' }}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell sx={{ fontWeight: 'bold' }}>Booking Id</TableCell>
                        <TableCell sx={{ fontWeight: 'bold' }}>Payment Date</TableCell>
                        <TableCell sx={{ fontWeight: 'bold' }}>Amount</TableCell>
                        <TableCell sx={{ fontWeight: 'bold' }}>Payment Status</TableCell>
                        <TableCell sx={{ fontWeight: 'bold' }}>View/Download</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {paymentList.map((res) => (
                        <TableRow key={res.booking_id}>
                            <TableCell>{res.booking_id}</TableCell>
                            <TableCell>{res.date}</TableCell>
                            <TableCell>€{res.amount}</TableCell>
                            <TableCell>{res.status}</TableCell>
                            <TableCell>
                                <a href={res.invoice_link} download target="_blank" >
                                    <Button className="default-btn" variant="contained">Download</Button>
                                </a>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    </Grid>
) : (
    <Typography className="view-dog-heading" sx={{ mt: 5, mb: 5, color: '#f48221' }}>
        No Payments Available
    </Typography>
)}

                <Box className="pagination-data" sx={{display: 'flex', justifyContent: 'center', mt: 4}}>
                        <Stack spacing={2}>
                            <Pagination count={Math.ceil(count/10)} rowsPerPage={10} color="primary" onChange={handleChangePage}/>
                        </Stack>    
                    </Box>
            </Box>
            </Box>
        </Box>
    )
}

export default MyPayments