import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Grid } from '@mui/material';
import { CustomLabelInput, FormInput, InputField } from '../CustomElements';
import { useState } from 'react';
import { addNewAddress, changePassword } from '../../Utils/Api';
import SnackBar from '../Snackbar';
import countryList from 'react-select-country-list';
import Select from 'react-select'


const AddNewAddress = ({handleCloseDialog, isOpen, refresh}) => {
const theme = useTheme();
const [snackbar, setSnackbar] = useState({isOpen: false, message: "", type: ""})
const countryOptions = React.useMemo(() => countryList().getData(), [])
const [address, setAddress] = useState({ street_name: '', city: '', country: '', postal_code: '' })
const [alertMsg, setAlertMsg] = useState('')
const {street_name, city, country, postal_code } = address

const handleAddAddress = () => {
if(street_name == '' || city == '' || postal_code == '' || country == '' ){
setAlertMsg('Oops! Looks like you have missed a question.')
} 
else if (street_name.length < 4) {
  setAlertMsg('Street name must be at least 4 characters long.');
}
else{
  addNewAddress({
    street_name,
    city,
    postal_code,
    country: country.label
  }).then(() => {
    setAlertMsg('')
    handleCloseDialog()
    refresh()
  })
}
}


  return (
    <div>
      <Dialog
        maxWidth='sm'
        fullWidth={true}
        open={isOpen}
        onClose={handleCloseDialog}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle sx={{fontFamily: 'Brandon', textAlign: 'center'}} id="responsive-dialog-title">
          {"Add New Address"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText sx={{fontFamily: 'Brandon', textAlign: 'center', color: 'red'}}>
            {alertMsg}
          </DialogContentText>
            <Grid containern>
              <Grid item xs={12}>
              <FormInput fullWidth variant="standard">
                <CustomLabelInput shrink="true" htmlFor="date_of_birth" sx={{marginBottom: '5px'}}>
                  Country
                </CustomLabelInput> 
                <Select
                className='select-font'
                options={countryOptions}
                value={country}
                onChange={(value) => setAddress({
                  ...address,
                  country: value
                })}
              />
              </FormInput>
            </Grid>
            <Grid item xs={12}>
                <FormInput fullWidth variant="standard">
                  <CustomLabelInput shrink="true" htmlFor="date_of_birth">
                  Area Name + City (Example: Merl, Luxembourg City)
                  </CustomLabelInput> 
                  <InputField
                    type="text"
                    size="small"
                    value={city}
                    onChange={(e) => {
                      const value = e.target.value.replace(/[^A-Za-z\s]/g, ''); 
                      setAddress({...address, city: value})
                    }}
                    placeholder="Enter City"
                  />
                </FormInput>
              </Grid>
            <Grid item xs={12}>
                <FormInput fullWidth variant="standard">
                  <CustomLabelInput shrink="true" htmlFor="date_of_birth">
                    Street Name and Number
                  </CustomLabelInput> 
                  <InputField
                    type="text"
                    size="small"
                    value={street_name}
                    onChange={(e) => {
                      let newValue = e.target.value.replace(/[^A-Za-z0-9\s]/g, ''); // Allow only letters, numbers, and spaces
                      setSnackbar((prev) => ({
                        ...prev,
                        isOpen: newValue.length >50 , 
                        message: "Street name cannot exceed 50 characters." ,
                        type: 'error'
                      }));
                      setAddress({ ...address, street_name: newValue.slice(0, 50)  });
                    }}
                    placeholder="Enter Street Name and Number"
                  />
                </FormInput>
              </Grid>
              <Grid item xs={12}>
                <FormInput fullWidth variant="standard">
                  <CustomLabelInput shrink="true" htmlFor="date_of_birth">
                    Postal Code
                  </CustomLabelInput> 
                  <InputField
                    type="text"
                    size="small"
                    value={postal_code}
                    onChange={(e) => {
                      const maxLength = 10;
                      const numericValue = e.target.value.replace(/\D/g, ""); 
                      if (numericValue.length <= maxLength) {
                        setAddress({...address, postal_code: numericValue})                      }
                    }}
                    placeholder="Enter Postal Code"
                  />
                </FormInput>
              </Grid>


            </Grid>
        </DialogContent>
        <DialogActions>
          <Button className="default-btn" onClick={handleCloseDialog}>Close</Button>
          <Button className="default-btn" onClick={handleAddAddress}>Add</Button>
        </DialogActions>
      </Dialog>
      <SnackBar
        open={snackbar.isOpen}
        handleClose={() => setSnackbar({ ...snackbar, isOpen: false })}
        duration={300000}
        msg={snackbar.message}
        type={snackbar.type}
      />
    </div>
  );
}

export default AddNewAddress