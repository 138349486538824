import { Box, Button, Checkbox, FormControlLabel, FormGroup, Grid, NativeSelect, Switch, Typography } from "@mui/material"
import { CustomLabelInput, FormInput, InputField, InputTeaxtArea, StyledToggle, StyledToggleButtonGroup } from "../CustomElements"
import { useEffect, useRef, useState } from "react"
import { BackBtn } from "../../Assets/assets"
import './style.css'
import DatePicker from "react-multi-date-picker"
import CancelIcon from '@mui/icons-material/Cancel';
import { createBookingReq, getDogList, getPastSittersList } from "../../Utils/Api"
import { useNavigate } from "react-router-dom"
import { LocalizationProvider, MobileTimePicker } from "@mui/x-date-pickers"
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from "dayjs"
import AddressModule from "../BookingAddress"
import SnackBar from "../Snackbar"
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import { weekDaysArr } from "../../helper"
import { calculateOvernightTotalDays } from "../../Utils/helper"
import Select from 'react-select'
import AddDogDialog from "../Dialogs/addDogDialog"

const BookingRequestForm = () => {
  const [activeModule, setActiveModule] = useState(0)
  const [dayCareSelectDates, setDayCareSelectDates] = useState([])
  const [dogList, setDogList] = useState([])
  const [modifiedDaycareData, setModifiedDaycareData] = useState([]);
  const [daycareDataDaysTime, setDaycareDaysTime] = useState(0);
  const navigate = useNavigate()
  const [addressData, setAddressData] = useState()
  const [snackbar, setSnackbar] = useState({ isOpen : false, message: '', type: ''})
  const [changeGlobalStartDate, setChangeGlobalStartDate] = useState()
  const [changeGlobalEndDate, setChangeGlobalEndDate] = useState()
  const [isGlobalChangeDateEnabled, setIsGlobalChangeDateEnabled] = useState(false)
  const [preferedSittersList, setPreferedSittersList] = useState([]);
    const [isInfoDialogOpen, setIsInfoDialogOpen] = useState(false)

  const [basicDetails, setBasicDetails] = useState({
    dogs: [], //ids of dogs
    preferred_sitters: [], // ids
    preferred_sitters_text: '',
    available_for_video_call: '',
    service_type: '', // overnight_boarding daycare home_visit
    other_details: '',
    comfortable_splitting_booking: '',
    sitter_caring_for_another_dog: ''
  })
  const { dogs, preferred_sitters, preferred_sitters_text, available_for_video_call, service_type,sitter_caring_for_another_dog} = basicDetails
    const inputRefs = useRef({
      dogs: null,
      available_for_video_call: null,
      comfortable_splitting_booking: null,
      sitter_caring_for_another_dog:null,
      daycare_dates_flexible:null,
      comfortable_with_sitter_dog:null,
      comfortable_with_sitter_cat:null,
      comfortable_with_sitter_children:null,
      ideal_start_date:null,
      parent_address: null, // Address fields
    street_name: null,
    city: null,
    postal_code: null,
    country: null,
    dayCareSelectDates: null,
    start_time:null,
    end_time:null,
    overnight_start_date:null,
    overnight_start_time:null,
    overnight_end_date:null,
    overnight_end_time:null,
    selectedAction:null
    });
    const [daycareData, setDaycareData] = useState({
      daycare_dates: modifiedDaycareData,
      daycare_dates_flexible: '',
      comfortable_with_sitter_dog: '',
      comfortable_with_sitter_dog_text: '',
      comfortable_with_sitter_cat: '',
      comfortable_with_sitter_cat_text: '',
      comfortable_with_sitter_children: '',
      comfortable_with_sitter_children_text: '',
      ideal_start_date: ''
    })

  const [overnightData, setOvernightData] = useState({
    comfortable_with_sitter_dog: '',
    comfortable_with_sitter_dog_text: '',
    comfortable_with_sitter_cat: '',
    comfortable_with_sitter_cat_text: '',
    comfortable_with_sitter_children: '',
    comfortable_with_sitter_children_text: '',
    overnight_start_date_time: {
      date: '',
      time: ''
    },
    overnight_end_date_time: {
      date: '',
      time: ''
    }
  })


  const [homeVistData, setHomeVistData] = useState({
    number_of_home_care_visits: '',
    home_care_activities: '',
  })

  const {number_of_home_care_visits, home_care_activities} = homeVistData

  const [overnightTimeDiff, setOvernightTimeDiff] = useState('')
  const [selectedWeekdays, setSelectedWeekdays] = useState([]);
  const [selectedAction, setSelectedAction] = useState(0);

  const resetOvernightData = () => {
    setActiveModule(0)
    setOvernightData({
      comfortable_with_sitter_dog: '',
      comfortable_with_sitter_dog_text: '',
      comfortable_with_sitter_cat: '',
      comfortable_with_sitter_cat_text: '',
      comfortable_with_sitter_children: '',
      comfortable_with_sitter_children_text: '',
      overnight_start_date_time: {
        date: '',
        time: ''
      },
      overnight_end_date_time: {
        date: '',
        time: ''
      }
    })
  }

  const resetDaycareData = () => {
    setActiveModule(0)
    setModifiedDaycareData([])
    setDaycareDaysTime(0)
    setDayCareSelectDates([])
    setDaycareData({
      daycare_dates: modifiedDaycareData,
      daycare_dates_flexible: '',
      comfortable_with_sitter_dog: '',
      comfortable_with_sitter_dog_text: '',
      comfortable_with_sitter_cat: '',
      comfortable_with_sitter_cat_text: '',
      comfortable_with_sitter_children: '',
      comfortable_with_sitter_children_text: '',
      ideal_start_date: ''
    })
  }

 
  useEffect(() => {
    getDogList().then((res) => {
      setDogList(res.data.data)
    })
    getPastSittersList().then((res) => {
      let sitterList = res.data.data
      const modifiedArr = []
      sitterList.map((item) => {
        modifiedArr.push({value: item.sitter_id, label: item.sitter_name})
      })
      setPreferedSittersList(modifiedArr)
      console.log(modifiedArr, 'modifiedArr')
    })
  }, [])

  useEffect(() => {
    setBasicDetails({...basicDetails, dogs: basicDetails.dogs})
  }, [dogs]);

  useEffect(() => {
  const time = calculateOvernightTotalDays(overnightData.overnight_start_date_time, overnightData.overnight_end_date_time)
  setOvernightTimeDiff(time)
  }, [overnightData]);

  const dayType = []

  useEffect(() => {
    modifiedDaycareData.map((item) => {
      if(item.day_type !== ''){
        dayType.push(item.day_type)
      }
      const convertedArray = dayType.map(day => {
        if (day === 'Half Day') {return 0.5}
        else if (day === 'Full Day') {return 1}
        else if (day === 'One Half') {return 1.5}
        return day;
      });
      let totalDaysSum = 0;
      convertedArray.forEach( num => {totalDaysSum += num})
      setDaycareDaysTime(totalDaysSum)
    return
    })
  }, [modifiedDaycareData]);

  function calculateTimeDifference(startTime, endTime) {
    if(startTime && endTime){
    const [hours1, minutes1] = startTime.split(':').map(Number);
    const [hours2, minutes2] = endTime.split(':').map(Number);
    const totalMinutes1 = hours1 * 60 + minutes1;
    const totalMinutes2 = hours2 * 60 + minutes2;
    let timeDifference = totalMinutes2 - totalMinutes1;
    const hoursDifference = Math.floor(timeDifference / 60);
    const minutesDifference = timeDifference % 60;
   if(hoursDifference <= 0){
    setSnackbar({...snackbar, isOpen: true, message: 'Please select the proper time', type: 'error'})
   }else{
    return `${hoursDifference}:${minutesDifference >= 10 ? minutesDifference : `0${minutesDifference}`}`
   }
  } else return
  }

  const calculateHoursToMins = (hours) => {
    if(hours){
      const [hour, minutes] = hours.split(':').map(Number);
      const totalMinutes = hour * 60 + minutes;
      return totalMinutes
    }
  }

  const handledDayCareMultipleDates = (data) => {
    let mappedData = []
    const convertDate = data.map((value) => value.year + '-' + value.month + '-' + value.day)
    const newDateObject = convertDate.filter(date => !modifiedDaycareData.some(item => item.date === date))
    const filteredArr = modifiedDaycareData.filter((item) => convertDate.includes(item.date));
    const modifiedJson = newDateObject.map((item) => {
      const data = {
        date: item,
        startTime: '',
        endTime: '',
        totalHours: '',
        day_type: '' // Full or half day
      }
      mappedData.push(data)
    })
    setDayCareSelectDates(convertDate)
    // setModifiedDaycareData([ ...modifiedDaycareData, ...mappedData ])
    mappedData.length > 0 ? setModifiedDaycareData([ ...modifiedDaycareData, ...mappedData ]) : setModifiedDaycareData(filteredArr)
  }
  const showError = (field, message) => {
    const element = inputRefs.current[field] || inputRefs.current[overnightData[field]]||  inputRefs.current[basicDetails[field]] ||  inputRefs.current[daycareData[field]] 
   
    
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "center" });
      element.style.backgroundColor = "#FEF2F2"; 
      setTimeout(() => {
        element.style.backgroundColor = ""; 
      }, 2000);
    }
  
    setSnackbar({ isOpen: true, message, type: "error" });
  };
  
  const handleCreateDaycareBooking = () => {
    try {
      if (selectedAction === 0) {
        showError("selectedAction","Choose the options")
        return;
      }
      if (selectedAction === 1) {
        // Check if weekdays are selected
        if (selectedWeekdays.length === 0) {
          showError("dayCareSelectDates", "Please select at least one weekday.");
          return;
        }
  
        // Check if ideal start date is provided
        if (!daycareData.ideal_start_date) {
          showError("ideal_start_date", "Please enter an ideal start date.");
          return;
        }
      }
  
      const requiredFields = ["daycare_dates_flexible"];
      for (const field of requiredFields) {
        if (!daycareData?.[field]) {
          showError(field, "Oops! Looks like you have missed a question");
          return;
        }
      }
  
      // Validate sitter-specific fields if location type is "sitter_location"
      if (addressData?.location_type?.includes("sitter_location")) {
        const sitterFields = [
          "comfortable_with_sitter_dog",
          "comfortable_with_sitter_cat",
          "comfortable_with_sitter_children",
        ];
        for (const field of sitterFields) {
          if (!daycareData?.[field]) {
            showError(field, "Oops! Looks like you have missed a question");
            return;
          }
        }
      }
  
      // Validate location fields
      if (!addressData?.location_type || addressData?.location_type.length === 0) {
        showError("parent_address", "Please select a location type");
        return;
      }
  
      if (addressData?.location_type.includes("parent_location") && !addressData?.parent_address) {
        showError("parent_address", "Please enter parent address");
        return;
      }
  
      if (addressData?.location_type.includes("other_address")) {
        const manualAddressFields = {
          street_name: "Please enter street name",
          city: "Please enter city",
          country: "Please enter country",
          postal_code: "Please enter postal code",
        };
  
        for (const [field, errorMessage] of Object.entries(manualAddressFields)) {
          if (!addressData?.manual_address?.[field]) {
            showError(field, errorMessage);
            return;
          }
        }
      }
  
      // Validate daycare dates
      if (selectedAction === 2 && modifiedDaycareData?.length === 0) {
        showError("dayCareSelectDates", "Please Select The Dates");
        return;
      }
      for (let i = 0; i < modifiedDaycareData.length; i++) {
        const { startTime, endTime, date } = modifiedDaycareData[i];
        if (!startTime) {
          showError("start_time", `Start time is required for ${date}.`);
          return;
        }
        if (!endTime) {
          showError("end_time", `End time is required for ${date}.`);
          return;
        }
      }
    
      // Construct booking request payload
      const bookingPayload = {
        dogs,
        preferred_sitters,
        preferred_sitters_text,
        available_for_video_call,
        service_type: "daycare",
        location_type: addressData?.location_type,
        manual_address: addressData?.manual_address,
        parent_address: addressData?.parent_address,
        daycare_dates_flexible: daycareData?.daycare_dates_flexible,
        comfortable_with_sitter_dog: daycareData?.comfortable_with_sitter_dog,
        comfortable_with_sitter_dog_text: daycareData?.comfortable_with_sitter_dog_text,
        comfortable_with_sitter_cat: daycareData?.comfortable_with_sitter_cat,
        comfortable_with_sitter_cat_text: daycareData?.comfortable_with_sitter_cat_text,
        comfortable_with_sitter_children: daycareData?.comfortable_with_sitter_children,
        comfortable_with_sitter_children_text: daycareData?.comfortable_with_sitter_children_text,
        comfortable_splitting_booking: basicDetails?.comfortable_splitting_booking,
        sitter_caring_for_another_dog: basicDetails?.sitter_caring_for_another_dog,
        other_details: basicDetails?.other_details,
        ...(selectedAction === 2
          ? { daycare_dates: modifiedDaycareData }
          : { week_days: selectedWeekdays, ideal_start_date: daycareData?.ideal_start_date }),
      };
  
      // API Call
      createBookingReq(bookingPayload).then((res) => {
        if (res?.data?.response_code === 80) {
          navigate("/thanks");
          window.localStorage.setItem("consentType", "booking");
        } else {
          setSnackbar({ ...snackbar, isOpen: true, message: res?.data?.developer_message, type: "error" });
        }
      });
    } catch (error) {
      console.error("Booking Error:", error);
    }
  };
  
  
  const handleCreateOvernightBooking = () => {
    try {
      // Validate location type
      if (!addressData?.location_type || addressData.location_type.length === 0) {
        showError("parent_address", "Please select a location type.");
        return;
      }
  
      // Validate overnight start and end date/time
      if (!overnightData.overnight_start_date_time.date) {
        showError("overnight_start_date", "Please enter an overnight start date.");
        return;
      }
      if (!overnightData.overnight_start_date_time.time) {
        showError("overnight_start_time", "Please enter an overnight start time.");
        return;
      }
      if (!overnightData.overnight_end_date_time.date) {
        showError("overnight_end_date", "Please enter an overnight end date.");
        return;
      }
      if (!overnightData.overnight_end_date_time.time) {
        showError("overnight_end_time", "Please enter an overnight end time.");
        return;
      }
  
      // Validate sitter location-specific fields
      if (addressData?.location_type.includes("sitter_location")) {
        const sitterFields = [
          "comfortable_with_sitter_dog",
          "comfortable_with_sitter_cat",
          "comfortable_with_sitter_children",
        ];
        for (const field of sitterFields) {
          if (!overnightData?.[field]) {
            showError(field, "Oops! Looks like you have missed a question.");
            return;
          }
        }
      }
  
      // Validate parent location address
      if (addressData?.location_type.includes("parent_location") && !addressData?.parent_address) {
        showError("parent_address", "Please enter parent address.");
        return;
      }
  
      // Validate manual address fields if location type is "other_address"
      if (addressData?.location_type.includes("other_address")) {
        const manualAddressFields = {
          street_name: "Please enter street name.",
          city: "Please enter city.",
          country: "Please enter country.",
          postal_code: "Please enter postal code.",
        };
  
        for (const [field, errorMessage] of Object.entries(manualAddressFields)) {
          if (!addressData?.manual_address?.[field]) {
            showError(field, errorMessage);
            return;
          }
        }
      }
  
      // Construct booking payload
      const bookingPayload = {
        dogs,
        preferred_sitters,
        preferred_sitters_text,
        available_for_video_call,
        service_type: "overnight_boarding",
        location_type: addressData?.location_type,
        manual_address: addressData?.manual_address,
        parent_address: addressData?.parent_address,
        comfortable_with_sitter_dog: overnightData?.comfortable_with_sitter_dog,
        comfortable_with_sitter_dog_text: overnightData?.comfortable_with_sitter_dog_text,
        comfortable_with_sitter_cat: overnightData?.comfortable_with_sitter_cat,
        comfortable_with_sitter_cat_text: overnightData?.comfortable_with_sitter_cat_text,
        comfortable_with_sitter_children: overnightData?.comfortable_with_sitter_children,
        comfortable_with_sitter_children_text: overnightData?.comfortable_with_sitter_children_text,
        overnight_start_date_time: {
          date: overnightData.overnight_start_date_time.date,
          time: overnightData.overnight_start_date_time.time,
        },
        overnight_end_date_time: {
          date: overnightData.overnight_end_date_time.date,
          time: overnightData.overnight_end_date_time.time,
        },
        comfortable_splitting_booking: basicDetails?.comfortable_splitting_booking,
        sitter_caring_for_another_dog: basicDetails?.sitter_caring_for_another_dog,
        other_details: basicDetails?.other_details,
      };
  
      // API Call
      createBookingReq(bookingPayload).then((res) => {
        if (res?.data?.response_code === 80) {
          navigate("/thanks");
          window.localStorage.setItem("consentType", "booking");
        } else {
          setSnackbar({ ...snackbar, isOpen: true, message: res?.data?.developer_message, type: "error" });
        }
      });
    } catch (error) {
      console.error("Booking Error:", error);
    }
  };
  
  const handleCreateHomeVisitBooking = () => {
    try {
      addressData.location_type.length == 0 || number_of_home_care_visits == ''  || home_care_activities == '' ? 
      setSnackbar({...snackbar, isOpen: true, message: 'Please fill required fields', type: 'error'})
      :
      createBookingReq({
        dogs: dogs,
        preferred_sitters: [],
        preferred_sitters_text: preferred_sitters_text,
        available_for_video_call: available_for_video_call,
        service_type: 'home_visit',
        location_type: addressData.location_type,
        manual_address: addressData.manual_address,
        parent_address: addressData.parent_address,
        number_of_home_care_visits: number_of_home_care_visits,
        home_care_activities: home_care_activities,
      }).then((res) => {
        res.data.response_code == 80 ?  (() => {navigate('/thanks'); window.localStorage.setItem('consentType', 'booking')})() : setSnackbar({...snackbar, isOpen: true, message: res.data.developer_message, type: 'error'})

      })
    } catch (error) {
      console.log(error)
    }
  }

  const setGlobalStartDate = (time) => {
    let arr = []
    if(isGlobalChangeDateEnabled && time){
      setChangeGlobalStartDate(time.format('HH:mm'))
    modifiedDaycareData.map((item) => {
      return arr.push({
        ...item,
        startTime: time.format('HH:mm'),
        totalHours: calculateTimeDifference(time.format('HH:mm'), changeGlobalEndDate),
        day_type: calculateHoursToMins(calculateTimeDifference(time.format('HH:mm'), changeGlobalEndDate)) == 0 ? '' : calculateHoursToMins(calculateTimeDifference(time.format('HH:mm'), changeGlobalEndDate)) > 601 ? "One Half" : calculateHoursToMins(calculateTimeDifference(time.format('HH:mm'), changeGlobalEndDate)) > 301 ? "Full Day" : "Half Day"})
    })
    setModifiedDaycareData(arr)}
    else return
  }
  const setGlobalEndDate = (time) => {
    let arr = []
    if(isGlobalChangeDateEnabled && time){
      setChangeGlobalEndDate(time.format('HH:mm'))
    modifiedDaycareData.map((item) => {
      return arr.push({
        ...item,
        endTime: time.format('HH:mm'),
        totalHours: calculateTimeDifference(changeGlobalStartDate, time.format('HH:mm')),
         day_type: calculateHoursToMins(calculateTimeDifference(changeGlobalStartDate, time.format('HH:mm'))) == 0 ? '' : calculateHoursToMins(calculateTimeDifference(changeGlobalStartDate, time.format('HH:mm'))) > 601 ? "One Half" : calculateHoursToMins(calculateTimeDifference(changeGlobalStartDate, time.format('HH:mm'))) > 301 ? "Full Day" : "Half Day"
       })
    })
    setModifiedDaycareData(arr)}
    else return
  }

  const getNextDay = (date) => {
    if (!date) return "";
    const nextDay = new Date(date);
    nextDay.setDate(nextDay.getDate() + 1);
    return nextDay.toISOString().split("T")[0]; 
  };
 const convertTimeToDecimal = (timeString) => {
  if (!timeString) return 0; // Handle empty or invalid values
  const [hours, minutes] = timeString.split(":").map(Number);
  return hours + minutes / 60; // Convert minutes to fraction
};

const handleStartDateChange = (e) => {
  const newStartDate = e.target.value;
  
  setOvernightData(prevData => {
    // Only calculate new end date if there's already an end date
    const shouldUpdateEndDate = prevData.overnight_end_date_time.date;
    const newEndDate = shouldUpdateEndDate ? getNextDay(newStartDate) : prevData.overnight_end_date_time.date;
    
    return {
      ...prevData,
      overnight_start_date_time: {
        ...prevData.overnight_start_date_time, 
        date: newStartDate
      },
      overnight_end_date_time: {
        ...prevData.overnight_end_date_time,
        date: newEndDate
      }
    };
  });
};
  
return(
    <>
    {activeModule == 0 && 
      <>
        {window.location.pathname == '/dashboard/booking/create' && <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box className="wrap-back-btn" onClick={() => { navigate('/dashboard') }}>{BackBtn()} Back</Box>
          <Box className="wrap-back-btn" style={{color: '#f48220', marginLeft: "5px"}} onClick={() => { navigate('/dashboard') }}> /Home</Box>
        </Box>}
    <h1 style={{ fontFamily: "Amatic", textAlign: "center", margin: "0",paddingBottom:"15px"}}> NEW BOOKING REQUEST </h1> 
          <Grid 
          {...(window.location.pathname != "/booking-request" ? { container: true } : {})} 
          spacing={5}>
            <Grid item xs={12} 
            md={window.location.pathname != "/booking-request" ? 6 : undefined}
            sx={{ display: "block" }}>
        <Grid item xs={12}>
        <Grid item xs={12}                 
 >
              <FormInput fullWidth variant="standard"   >
                  <CustomLabelInput htmlFor="hear_from_source" 
                  >
                  Which dog is this request is for? <span style={{color: 'red', fontSize: '20px'}}>*</span>
                  </CustomLabelInput>
                  <FormGroup sx={{flexDirection: 'row'}} ref={(el) => (inputRefs.current.dogs = el)}>
                    {dogList.map((item) => {
                      return <FormControlLabel label={item.name}
        control={
          <Checkbox
            value={item.dog_id}
                        checked={dogs.includes(item.dog_id) ? true : false}
                        onChange={() => {dogs.includes(item.dog_id) ? setBasicDetails({...basicDetails, dogs: dogs.filter((value) => value != item.dog_id)}) : setBasicDetails({...basicDetails, dogs: [...dogs, item.dog_id]})}}
                        sx={{ color: '#fe7648', '&.Mui-checked': { color: '#fe7648' }, }}/>} />
                    })}
                  </FormGroup>
              </FormInput>
            </Grid>
            <Grid item xs={12}>
              <FormInput fullWidth variant="standard">
                <CustomLabelInput htmlFor="hear_from_source">
                Here is a list of dog sitters you have booked with. Please select all the sitters you are comfortable with for this request.
                </CustomLabelInput>
                <Select
                  className='select-font'
                  isMulti
                  options={preferedSittersList}
                  onChange={(value) => {
                    setBasicDetails((prevDetails) => {
                      const selectedSitterId = value.map((res) => { return res.value})
                      return { ...prevDetails, preferred_sitters: selectedSitterId };
                    });
                  }}
                />
              </FormInput>
            </Grid>
            <Grid item xs={12}>
            <FormInput fullWidth variant="standard">
              <CustomLabelInput shrink="true">
                Sitter Names (If you don’t see the name of a dog sitter you want us to check for, please type in the name of the dog sitter. You can enter multiple names separated by a comma (,))
              </CustomLabelInput>
                <InputField
                    type="text"
                    size="small"
                    placeholder="Enter Sitter's Name"
                    value={basicDetails.preferred_sitters_text}
                    onChange={(e) => setBasicDetails({
                      ...basicDetails,
                      preferred_sitters_text: e.target.value
                    })}
                  />
                </FormInput>
            </Grid>
        </Grid>
        <Grid item xs={6}>
        </Grid>
        <Grid item xs={12}>
              <FormInput fullWidth variant="standard">
                <CustomLabelInput htmlFor="hear_from_source">
                 We always try and arrange a face-to-face meeting for you and your dog to meet the dog sitter but in case this is difficult for some reason, 
                 would a video call be OK for you? <span style={{color: 'red', fontSize: '20px'}}>*</span>
                </CustomLabelInput>
                <NativeSelect
                  disableUnderline
                  defaultValue=''
                  name="hear_from_source"
                  id="hear_from_source"
                  ref={(el) => (inputRefs.current.available_for_video_call = el)}
                  onFocus={() => {
                    if (inputRefs.current.available_for_video_call) {
                      inputRefs.current.available_for_video_call.style.backgroundColor = "";
                    }
                  }}  
                  inputProps={{style:{fontFamily: 'Brandon', padding:'8.5px 14px'}}}
                  value={available_for_video_call}
                  onChange={(e) => setBasicDetails({
                    ...basicDetails,
                    available_for_video_call: e.target.value
                  })}
                  sx={{ border: "1px solid #ced4d9", borderRadius: '3px', marginTop: '5px'}}
                >   <option selected disabled value=''>Select</option>
                    <option value='Yes'>Yes</option>
                    <option value='No'>No</option>
                    <option value='Maybe'>Maybe</option>
                </NativeSelect>
              </FormInput>
            </Grid>
        </Grid>
            <Grid item xs={12}   md={window.location.pathname != "/booking-request" ? 6 : undefined} sx={{ display: "block" }}>
        
            <Grid item xs={12}>
             <FormInput fullWidth variant="standard">
               <CustomLabelInput shrink="true">
               For very long overnight bookings we sometimes need to use the services of more than one dog sitter. Are you comfortable with this option? <span style={{color: 'red', fontSize: '20px'}}>*</span>
               </CustomLabelInput>
               <NativeSelect
                 disableUnderline
                 defaultValue=""
                 ref={(el) => (inputRefs.current.comfortable_splitting_booking = el)}
                  onFocus={() => {
                    if (inputRefs.current.comfortable_splitting_booking) {
                      inputRefs.current.comfortable_splitting_booking.style.backgroundColor = "";
                    }
                  }}  
                 value={basicDetails.comfortable_splitting_booking}
                 onChange={(e) => {setBasicDetails({...basicDetails, comfortable_splitting_booking: e.target.value})}}
                 inputProps={{style: {fontFamily: "Brandon", padding: "8.5px 14px",}}}
                 sx={{ border: "1px solid #ced4d9", borderRadius: "3px", marginTop: "5px",}}
                 >
                 <option selected disabled value="">
                   Select
                 </option>
                 <option value="Yes">Yes</option>
                 <option value="No">No</option>
                 <option value="Maybe">Maybe</option>
               </NativeSelect>
             </FormInput>
           </Grid>
           <Grid item xs={12}>
             <FormInput fullWidth variant="standard">
               <CustomLabelInput shrink="true">
                Is it OK if the dog sitter is caring for another dog on some of your dates? <span style={{color: 'red', fontSize: '20px'}}>*</span>
               </CustomLabelInput>
               <NativeSelect
                 disableUnderline
                 defaultValue=""
                 ref={(el) => (inputRefs.current.sitter_caring_for_another_dog = el)}
                 onFocus={() => {
                   if (inputRefs.current.sitter_caring_for_another_dog) {
                     inputRefs.current.sitter_caring_for_another_dog.style.backgroundColor = "";
                   }
                 }}  
                 value={basicDetails.sitter_caring_for_another_dog}
                 onChange={(e) => {setBasicDetails({...basicDetails, sitter_caring_for_another_dog: e.target.value})}}
                 inputProps={{style: {fontFamily: "Brandon", padding: "8.5px 14px",}}}
                 sx={{ border: "1px solid #ced4d9", borderRadius: "3px", marginTop: "5px",}}
                 >
                 <option selected disabled value="">
                   Select
                 </option>
                 <option value="Yes">Yes</option>
                 <option value="No">No</option>
                 <option value="Maybe">Maybe</option>
               </NativeSelect>
             </FormInput>
           </Grid>
           <Grid item xs={12}>
           <FormInput fullWidth variant="standard">
                <CustomLabelInput htmlFor="hear_from_source">
                  Is there anything else you would like us or the dog sitter to know?
                </CustomLabelInput>
                <InputTeaxtArea
                value={basicDetails.other_details}
                onChange={(e) => {setBasicDetails({...basicDetails, other_details: e.target.value})}}
                minRows={2}
                sx={{fontFamily: 'Brandon', height:"40px", fontSize: "1rem", border: "1px solid #ced4d9", borderRadius:'3px', padding: "8.5px 14px", marginTop:"5px"}}
                size="small"
                placeholder="Enter Details"
              />
              </FormInput>
           </Grid>
            <Grid item xs={12}>
              <FormInput fullWidth variant="standard">
                <CustomLabelInput htmlFor="hear_from_source">
                Which service would you like to book?
                </CustomLabelInput>
                <Box sx={{display: 'flex', gap: "10px", marginTop:'5px'}}>
                <Button
  className="default-btn"
  variant="contained"
  sx={{ marginBottom: '2px' }}
  onClick={() => {
    const requiredFields = [
      "available_for_video_call",
      "comfortable_splitting_booking",
      "sitter_caring_for_another_dog"
    ];
  
    // If dogList has items, make "dogs" required
    if (dogList.length > 0) {
      requiredFields.push("dogs");
    }
  
    for (const field of requiredFields) {
      if (!basicDetails[field] || basicDetails[field].length === 0) {
        showError(field, "Please fill required fields");
        return;
      }
    }
  
    if (dogList.length === 0) {
      setIsInfoDialogOpen(true);
    } else {
      setActiveModule(1);
    }
  }}
  
>
  Day Care
</Button>



<Button
  className="default-btn"
  variant="contained"
  sx={{ marginBottom: '2px' }}
  onClick={() => {
    const requiredFields = ["dogs", "available_for_video_call", "comfortable_splitting_booking","sitter_caring_for_another_dog"];
    for (const field of requiredFields) {
      if (!basicDetails[field] || basicDetails[field].length === 0) {
        showError(field, "Please fill required fields");
        return;
      }
    }
    if (dogList.length > 0 && basicDetails.dogs.length === 0) {
      showError("dog", "Please fill required fields");
      return;
    }
    if (dogList.length === 0) {
      setIsInfoDialogOpen(true);
    } else {
      setActiveModule(2);
    }
  }}
>
  Overnight Booking
</Button>
                {/* <Button className="default-btn" variant="contained" sx={{marginBottom:'2px'}} onClick={() => {dogs.length == 0 || available_for_video_call == '' ? setSnackbar({...snackbar, isOpen: true, message: 'Please fill required fields', type: 'error'}) : setActiveModule(3)}}> Home Visits </Button> */}
                </Box>
              </FormInput>
            </Grid> 
            <Grid xs={12}>
              <ul className="enquiry-links">
                <li>You can see details of our pricing for each service <a href="https://www.dogstays.lu/pricing" target="blank">here</a></li>
                <li>Read answers to some common questions in our FAQs <a href="https://www.dogstays.lu/faqs-parents" target="blank">here</a></li>
              </ul>
            </Grid>
            </Grid>
    </Grid>
      </>
    }
    {activeModule == 1 && 
    <>
    <div>
    <Box className="wrap-back-btn" onClick={() => {resetDaycareData()}}>{BackBtn()} Back</Box>
      <h2 className="request-type-heading"> Booking Request - DayCare</h2>
    </div>
      <Grid {...(window.location.pathname != "/booking-request" ? { container: true } : {})}  sx={{marginTop:'10px'}} spacing={5}>
      <Grid item xs={12}   md={window.location.pathname != "/booking-request" ? 6 : undefined} sx={{ display: "block" }}>

        <AddressModule data={(data) => setAddressData(data)} inputRefs={inputRefs}/>
        {addressData?.location_type.includes('sitter_location') && <Grid container xs={false}  md={12} sx={{gap: '10px'}}>
            <Grid item xs={12}>
             <FormInput fullWidth variant="standard">
               <CustomLabelInput shrink="true">
               Is it OK if the sitter's home has a dog?<span style={{color: 'red', fontSize: '20px'}}>*</span>
               </CustomLabelInput>
               <NativeSelect
                 disableUnderline
                 defaultValue=""
                 ref={(el) => (inputRefs.current.comfortable_with_sitter_dog = el)}
                 onFocus={() => {
                   if (inputRefs.current.comfortable_with_sitter_dog) {
                     inputRefs.current.comfortable_with_sitter_dog.style.backgroundColor = "";
                   }
                 }}  
                 inputProps={{style: {fontFamily: "Brandon", padding: "8.5px 14px",}}}
                 sx={{ border: "1px solid #ced4d9", borderRadius: "3px", marginTop: "5px",}}
                 value={daycareData.comfortable_with_sitter_dog}
                 onChange={(e) => {setDaycareData({...daycareData, comfortable_with_sitter_dog: e.target.value})}}
                 >
                 <option selected disabled value="">
                   Select
                 </option>
                 <option value="Yes">Yes</option>
                 <option value="No">No</option>
                 <option value="Maybe">Maybe</option>
               </NativeSelect>
             </FormInput>
           </Grid>
           {daycareData.comfortable_with_sitter_dog == 'Maybe' && <Grid item xs={12}>
            <FormInput fullWidth variant="standard">
              <CustomLabelInput shrink="true">
              Please share additional details here:
              </CustomLabelInput>
                <InputTeaxtArea
                    minRows={2}
                    sx={{fontFamily: 'Brandon', height:"40px", fontSize: "1rem", border: "1px solid #ced4d9", borderRadius:'3px', padding: "8.5px 14px", marginTop:"5px"}}
                    size="small"
                    placeholder="Enter Details"
                    value={daycareData.comfortable_with_sitter_dog_text}
                    onChange={(e) => {setDaycareData({...daycareData, comfortable_with_sitter_dog_text: e.target.value})}}
                  />
                </FormInput>
            </Grid>}
           <Grid item xs={12}>
             <FormInput fullWidth variant="standard">
               <CustomLabelInput shrink="true">
               Is it OK if the sitter's home has a cat?<span style={{color: 'red', fontSize: '20px'}}>*</span>
               </CustomLabelInput>
               <NativeSelect
                 disableUnderline
                 defaultValue=""
                 ref={(el) => (inputRefs.current.comfortable_with_sitter_cat = el)}
                 onFocus={() => {
                   if (inputRefs.current.comfortable_with_sitter_cat) {
                     inputRefs.current.comfortable_with_sitter_cat.style.backgroundColor = "";
                   }
                 }}  
                 inputProps={{style: {fontFamily: "Brandon", padding: "8.5px 14px",}}}
                 sx={{ border: "1px solid #ced4d9", borderRadius: "3px", marginTop: "5px",}}
                 value={daycareData.comfortable_with_sitter_cat}
                 onChange={(e) => {setDaycareData({...daycareData, comfortable_with_sitter_cat: e.target.value})}}
                 >
                 <option selected disabled value="">
                   Select
                 </option>
                 <option value="Yes">Yes</option>
                 <option value="No">No</option>
                 <option value="Maybe">Maybe</option>
               </NativeSelect>
             </FormInput>
           </Grid>
           {daycareData.comfortable_with_sitter_cat == 'Maybe' && <Grid item xs={12}>
            <FormInput fullWidth variant="standard">
              <CustomLabelInput shrink="true">
              Please share additional details here:
              </CustomLabelInput>
                <InputTeaxtArea
                    minRows={2}
                    sx={{fontFamily: 'Brandon', height:"40px", fontSize: "1rem", border: "1px solid #ced4d9", borderRadius:'3px', padding: "8.5px 14px", marginTop:"5px"}}
                    size="small"
                    placeholder="Enter Details"
                    value={daycareData.comfortable_with_sitter_cat_text}
                    onChange={(e) => {setDaycareData({...daycareData, comfortable_with_sitter_cat_text: e.target.value})}}
                  />
                </FormInput>
            </Grid>}
           <Grid item xs={12}>
             <FormInput fullWidth variant="standard">
               <CustomLabelInput shrink="true">
                Is it OK if the sitter's home has a children?<span style={{color: 'red', fontSize: '20px'}}>*</span>
               </CustomLabelInput>
               <NativeSelect
                 disableUnderline
                 defaultValue=""
                 ref={(el) => (inputRefs.current.comfortable_with_sitter_children = el)}
                 onFocus={() => {
                   if (inputRefs.current.comfortable_with_sitter_children) {
                     inputRefs.current.comfortable_with_sitter_children.style.backgroundColor = "";
                   }
                 }}  
                 inputProps={{style: {fontFamily: "Brandon", padding: "8.5px 14px",}}}
                 sx={{ border: "1px solid #ced4d9", borderRadius: "3px", marginTop: "5px",}}
                 value={daycareData.comfortable_with_sitter_children}
                 onChange={(e) => {setDaycareData({...daycareData, comfortable_with_sitter_children: e.target.value})}}
                 >
                 <option selected disabled value="">
                   Select
                 </option>
                 <option value="Yes">Yes</option>
                 <option value="No">No</option>
                 <option value="Maybe">Maybe</option>
               </NativeSelect>
             </FormInput>
           </Grid>
           {daycareData.comfortable_with_sitter_children == 'Maybe' && <Grid item xs={12}>
            <FormInput fullWidth variant="standard">
              <CustomLabelInput shrink="true">
              Please share additional details here:
              </CustomLabelInput>
                <InputTeaxtArea
                    minRows={2}
                    sx={{fontFamily: 'Brandon', height:"40px", fontSize: "1rem", border: "1px solid #ced4d9", borderRadius:'3px', padding: "8.5px 14px", marginTop:"5px"}}
                    size="small"
                    placeholder="Enter Details"
                    value={daycareData.comfortable_with_sitter_children_text}
                    onChange={(e) => {setDaycareData({...daycareData, comfortable_with_sitter_children_text: e.target.value})}}
                  />
                </FormInput>
            </Grid>}
        </Grid>}
        <Grid item xs={12}>
             <FormInput fullWidth variant="standard">
               <CustomLabelInput shrink="true">
                How often do you need this service?<span style={{color: 'red', fontSize: '20px'}}>*</span>
               </CustomLabelInput>
                <Box sx={{display: 'flex', gap: '16px'}} ref={(el) => (inputRefs.current.selectedAction = el)} 
              >
                  <Box    className={selectedAction == 2 ? 'selector selected-item' :'selector'} onClick={() => {setSelectedAction(2);setModifiedDaycareData([]); setDayCareSelectDates([]); setSelectedWeekdays([]); setDaycareData({...daycareData, ideal_start_date: ''})}}>
                    <EditCalendarIcon color="#ced4d8"/> Only Specific Dates
                  </Box>
                  <Box className={selectedAction == 1 ? 'selector selected-item' :'selector'} onClick={() => {setSelectedAction(1); setModifiedDaycareData([]); setDayCareSelectDates([]); setSelectedWeekdays([]); setDaycareData({...daycareData, ideal_start_date: ''})}}>
                    <CalendarTodayIcon color="#ced4d8"/> Repeat Weekly
                  </Box>
                </Box>
             </FormInput>
           </Grid>


           {selectedAction == 1 && <Grid item xs={12} sx={{display:'flex', flexWrap: 'wrap', gap: '20px'}}>
            <Box>
              <FormInput fullWidth variant="standard">
                <CustomLabelInput shrink="true">
                  For which days?<span style={{color: 'red', fontSize: '20px'}}>*</span>
                </CustomLabelInput>
                <div                   ref={(el) => (inputRefs.current.ideal_start_date = el)}
                >
                <StyledToggleButtonGroup
                  ref={(el) => (inputRefs.current.dayCareSelectDates = el)}
                  size="small"
                  onFocus={() => {
                    if (inputRefs.current.dayCareSelectDates) {
                      inputRefs.current.dayCareSelectDates.style.backgroundColor = "";
                    }
                  }}
                
                  arial-label="Days of the week"
                  value={selectedWeekdays}
                  sx={{mt: 1, width: '100%'}}
                  onChange={(event, value) => setSelectedWeekdays(value)}
                >
                  {weekDaysArr.map((day, index) => (
                    <StyledToggle key={day.key} value={day.key} aria-label={day.key}>
                      {day.label}
                    </StyledToggle>
                  ))}
                </StyledToggleButtonGroup>
                </div>
                </FormInput>
              </Box>
              <Box>
              <FormInput fullWidth variant="standard">
              <CustomLabelInput shrink="true">
                Ideal start date<span style={{color: 'red', fontSize: '20px'}}>*</span>
              </CustomLabelInput>
              <InputField
                type="date"
                size="small"
                ref={(el) => (inputRefs.current.ideal_start_date = el)}
                  onFocus={() => {
                    if (inputRefs.current.ideal_start_date) {
                      inputRefs.current.ideal_start_date.style.backgroundColor = "";
                    }
                  }} 
                value={daycareData.ideal_start_date}
                inputProps={{ min: new Date().toISOString().substring(0,10)}}
                onChange={(e) => {setDaycareData({...daycareData, ideal_start_date: e.target.value})}}
                placeholder="Enter Date"
              />
              </FormInput>
              </Box>
          </Grid>}

          
         {selectedAction == 2 && <> <Grid item xs={12}>
          <FormInput fullWidth variant="standard">
            <CustomLabelInput shrink="true">
              Select Dates <span style={{color: 'red', fontSize: '20px'}}>*</span>
            </CustomLabelInput>
              <DatePicker
                className="date-picker-layout"
                isMulti
                onChange={handledDayCareMultipleDates}
                value={dayCareSelectDates}
                ref={(el) => (inputRefs.current.dayCareSelectDates = el)}
                  onFocus={() => {
                    if (inputRefs.current.dayCareSelectDates) {
                      inputRefs.current.dayCareSelectDates.style.backgroundColor = "";
                    }
                  }} 
                dateFormat="DD-MM-YYYY" 
                minDate={new Date()}
              />
              </FormInput>
          </Grid>
          <p style={{fontFamily: 'Brandon', fontSize: '14px', color: 'red', margin: '10px 0px 0px 0px'}}>Please switch this on if you wish to use the same start time and end time for all dates</p>
              <Grid xs={12} sx={{display: 'flex',     gap: { xs: '42px', sm: '20px' } 
}}>
              <Grid xs={1}>
              <FormInput fullWidth variant="standard">
                  <CustomLabelInput shrink="true">
                  Set default Time
                  </CustomLabelInput>
                  <Switch defaultChecked checked={isGlobalChangeDateEnabled} color="warning" onChange={() => {
                    setIsGlobalChangeDateEnabled(!isGlobalChangeDateEnabled)
                    if(isGlobalChangeDateEnabled){
                      setGlobalStartDate()
                      setGlobalEndDate()
                    } else return
                  }}/>
              </FormInput>
              </Grid>
              
              <Grid xs={5}>
                <FormInput fullWidth variant="standard">
                  <CustomLabelInput shrink="true">
                  Start Time
                  </CustomLabelInput>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <MobileTimePicker
                    sx={{marginTop: '5px'}}
                    // value={item.startTime}
                    ampm={false}
                    closeOnSelect={true}
                    onChange={setGlobalStartDate}
                    />
                  </LocalizationProvider>            
                  </FormInput>
                </Grid>
                <Grid xs={5}>
                <FormInput fullWidth variant="standard">
                  <CustomLabelInput shrink="true">
                  End Time
                  </CustomLabelInput>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <MobileTimePicker
                    sx={{marginTop: '5px'}}
                    // defaultValue={dayjs('2022-04-17T18:30')} 
                    // value={item.startTime}
                    minTime={dayjs(changeGlobalStartDate, "HH:mm")}
                    ampm={false}
                    closeOnSelect={true}
                    onChange={setGlobalEndDate}
                    />
                  </LocalizationProvider>            
                  </FormInput>
                </Grid>
              </Grid>
                 <Grid item xs={12}>
                 {modifiedDaycareData.map((item, index) => {
                   return(
                   <div className="events-wrapper">
                     <span onClick={() => {
                       const calenderItems = [...dayCareSelectDates];
                       const modifyItems = [...modifiedDaycareData];
                       calenderItems.splice(index, 1);
                       modifyItems.splice(index, 1);
                       setModifiedDaycareData(modifyItems);
                       setDayCareSelectDates(calenderItems);
                     }}><CancelIcon style={{ color: "#fe7648" }}/></span>
                     <Grid xs={12} sm={12} >
                     <FormInput fullWidth variant="standard">
                       <CustomLabelInput shrink="true">
                       Selected Dates
                       </CustomLabelInput>
                       <InputField
                         type="text"
                         size="small"
                         disabled
                         value={item.date}
                       />
                     </FormInput>
                     </Grid>
                     <Grid xs={12} sm={12} >
                     <FormInput fullWidth variant="standard">
                       <CustomLabelInput shrink="true">
                       Start Time
                       </CustomLabelInput>
                       <LocalizationProvider dateAdapter={AdapterDayjs}>
                         <MobileTimePicker
                          sx={{marginTop: '5px'}}
                          defaultValue={dayjs('2022-04-17T18:30')} 
                          value={dayjs(`${item.date}T${item.startTime}`)}
                          ref={(el) => (inputRefs.current.start_time = el)} 
                          ampm={false}
                          closeOnSelect={true}
                          onChange={(time) => {
                           const updatedData = { ...item, startTime: time.format('HH:mm'), totalHours: calculateTimeDifference(time.format('HH:mm'), item.endTime)};
                           setModifiedDaycareData((prevData) => {
                             const newData = [...prevData];
                             newData[index] = updatedData;
                             return newData;
                           });
                          }}
                          />
                       </LocalizationProvider>            
                       </FormInput>
                       </Grid>
                       <Grid xs={12} sm={12} >
                     <FormInput fullWidth variant="standard">
                       <CustomLabelInput shrink="true">
                       End Time
                       </CustomLabelInput>
                       <LocalizationProvider dateAdapter={AdapterDayjs}>
                         <MobileTimePicker
                           sx={{marginTop: '5px'}}
                           defaultValue={dayjs()}
                           value={dayjs(`${item.date}T${item.endTime}`)}
                           ref={(el) => (inputRefs.current.end_time = el)} 

                           ampm={false}
                           minTime={dayjs(item.startTime, 'HH:MM')}
                           onChange={(time) => {
                           const updatedData = { ...item, endTime: time.format('HH:mm'), totalHours: calculateTimeDifference(item.startTime, time.format('HH:mm')), day_type: calculateHoursToMins(calculateTimeDifference(item.startTime, time.format('HH:mm'))) == 0 ? '' : calculateHoursToMins(calculateTimeDifference(item.startTime, time.format('HH:mm'))) > 601 ? "One Half" : calculateHoursToMins(calculateTimeDifference(item.startTime, time.format('HH:mm'))) > 301 ? "Full Day" : "Half Day"};
                           setModifiedDaycareData((prevData) => {
                             const newData = [...prevData];
                             newData[index] = updatedData;
                             return newData;
                           });
                          }}
                         />
                       </LocalizationProvider>
                     </FormInput>
                     </Grid>
                     <Grid xs={12} sm={12} >
                     <FormInput fullWidth variant="standard">
                       <CustomLabelInput shrink="true">
                        No of Hours
                       </CustomLabelInput>
                       <InputField
                         type="text"
                         size="small"
                         placeholder="Hours"
                         disabled
                         value={item.totalHours}
                       />
                     </FormInput>
                     </Grid>
                     <Grid xs={12} sm={12} >
                     <FormInput fullWidth variant="standard">
                       <CustomLabelInput shrink="true">
                        No of Days
                       </CustomLabelInput>
                       <InputField
                         type="text"
                         size="small"
                         placeholder="Days"
                         disabled
                         value={item.day_type}
                       />
                     </FormInput>
                     </Grid>
                   </div>)
                 })}
                 {modifiedDaycareData.length > 0 && 
                 <Grid sx={{display: 'flex', justifyContent:'end', gap:'15px', marginRight:'10px',alignItems:"center"}}>
                  {modifiedDaycareData.map((item, index) => {
  return convertTimeToDecimal(item.totalHours) > 10 ? (
    <p style={{color:"red",fontSize:"13px",fontWeight:"lighter"}} key={index}>Your booking is more than 10 hours long</p>
  ) : null;
})}

                   {/* <Typography component="h4" color="text.secondary" fontWeight='bold' fontFamily='brandon'>{`Total hours: ${hours}`}</Typography> */}
                   <Typography component="h4" color="text.secondary" fontWeight='bold' fontFamily='brandon'>{`Total days: ${daycareDataDaysTime}`}</Typography>
                 </Grid>
                 }
                </Grid>
                </>
          }
</Grid>
<Grid item xs={12}   md={window.location.pathname != "/booking-request" ? 6 : undefined} sx={{ display: "block" }}>

       <Grid item xs={12}>
            <FormInput fullWidth variant="standard">
                <CustomLabelInput htmlFor="hear_from_source">
                Are your dates a little flexible? <span style={{color: 'red', fontSize: '20px'}}>*</span>
                </CustomLabelInput>
                <NativeSelect
                  disableUnderline
                  defaultValue=''
                  name="hear_from_source"
                  ref={(el) => (inputRefs.current.daycare_dates_flexible = el)}
                  onFocus={() => {
                    if (inputRefs.current.daycare_dates_flexible) {
                      inputRefs.current.daycare_dates_flexible.style.backgroundColor = "";
                    }
                  }}  
                  id="hear_from_source"
                  inputProps={{style:{fontFamily: 'Brandon', padding:'8.5px 14px'}}}
                  sx={{ border: "1px solid #ced4d9", borderRadius: '3px', marginTop: '5px'}}
                  value={daycareData.daycare_dates_flexible}
                  onChange={(e) => {setDaycareData({...daycareData, daycare_dates_flexible: e.target.value})}}
                >   <option selected disabled value=''>Select</option>
                    <option value='false'>No, my dates cannot change</option>
                    <option value='true'>Yes, my dates can be adjusted for the right dog sitter</option>
                </NativeSelect>
              </FormInput>
          </Grid>
          <Grid item xs={12}>
           <FormInput fullWidth variant="standard">
                <CustomLabelInput htmlFor="hear_from_source">
                  Is there anything else you would like us or the dog sitter to know? 
                </CustomLabelInput>
                <InputTeaxtArea
                value={daycareData.other_details}
                onChange={(e) => {setDaycareData({...daycareData, other_details: e.target.value})}}
                minRows={2}
                sx={{fontFamily: 'Brandon', height:"40px", fontSize: "1rem", border: "1px solid #ced4d9", borderRadius:'3px', padding: "8.5px 14px", marginTop:"5px"}}
                size="small"
                placeholder="Enter Details"
              />
              </FormInput>
           </Grid>
          
       <Grid item xs= {12} sx={{display: 'flex', justifyContent: 'center'}}> 
       <Button
         type="submit"
         className="default-btn"
         variant="contained"
         sx={{ mt: 3, mb: 2, width: "auto" }}
         onClick={handleCreateDaycareBooking}
       >
         Submit Request
       </Button>
       </Grid>
       </Grid>
      </Grid>
    </>
    }
    {activeModule == 2 &&   
          <div>
          <Box className="wrap-back-btn" onClick={() => {resetOvernightData()}}>{BackBtn()} Back</Box>
            <h2 className="request-type-heading"> Booking Request - Overnight Booking</h2>
            <Grid container sx={{marginTop:'10px'}}>
            <AddressModule data={(data) => setAddressData(data)}  inputRefs={inputRefs} activeModule={2}/>
        {addressData?.location_type.includes('sitter_location') && <Grid  xs={false}  md={12} sx={{gap: '10px'}}>
            <Grid item xs={12} sm={12}   md={window.location.pathname != "/booking-request" ? 5 : undefined}>
             <FormInput fullWidth variant="standard">
               <CustomLabelInput shrink="true">
               Is it OK if the sitter's home has a dog?<span style={{color: 'red', fontSize: '20px'}}>*</span>
               </CustomLabelInput>
               <NativeSelect
                 disableUnderline
                 defaultValue=""
                 ref={(el) => (inputRefs.current.comfortable_with_sitter_dog = el)}
                 onFocus={() => {
                   if (inputRefs.current.comfortable_with_sitter_dog) {
                     inputRefs.current.comfortable_with_sitter_dog.style.backgroundColor = "";
                   }
                 }}  
                 inputProps={{style: {fontFamily: "Brandon", padding: "8.5px 14px",}}}
                 sx={{ border: "1px solid #ced4d9", borderRadius: "3px", marginTop: "5px",}}
                 value={overnightData.comfortable_with_sitter_dog}
                 onChange={(e) => {setOvernightData({...overnightData, comfortable_with_sitter_dog: e.target.value})}}
                 >
                 <option selected disabled value="">
                   Select
                 </option>
                 <option value="Yes">Yes</option>
                 <option value="No">No</option>
                 <option value="Maybe">Maybe</option>
               </NativeSelect>
             </FormInput>
           </Grid>
           {overnightData.comfortable_with_sitter_dog == 'Maybe' && <Grid item xs={12}  sm={12} 
            md={window.location.pathname != "/booking-request" ? 5 : undefined}>
            <FormInput fullWidth variant="standard">
              <CustomLabelInput shrink="true">
              Please share additional details here:
              </CustomLabelInput>
                <InputField
                    type="text"
                    size="small"
                    placeholder="Enter Details"
                    value={overnightData.comfortable_with_sitter_dog_text}
                    onChange={(e) => {setOvernightData({...overnightData, comfortable_with_sitter_dog_text: e.target.value})}}
                  />
                </FormInput>
            </Grid>}
           <Grid item xs={12}  sm={12} 
            md={window.location.pathname != "/booking-request" ? 5 : undefined}>
             <FormInput fullWidth variant="standard">
               <CustomLabelInput shrink="true">
               Is it OK if the sitter's home has a cat?<span style={{color: 'red', fontSize: '20px'}}>*</span>
               </CustomLabelInput>
               <NativeSelect
                 disableUnderline
                 ref={(el) => (inputRefs.current.comfortable_with_sitter_cat = el)}
                 onFocus={() => {
                   if (inputRefs.current.comfortable_with_sitter_cat) {
                     inputRefs.current.comfortable_with_sitter_cat.style.backgroundColor = "";
                   }
                 }}  
                 defaultValue=""
                 inputProps={{style: {fontFamily: "Brandon", padding: "8.5px 14px",}}}
                 sx={{ border: "1px solid #ced4d9", borderRadius: "3px", marginTop: "5px",}}
                 value={overnightData.comfortable_with_sitter_cat}
                 onChange={(e) => {setOvernightData({...overnightData, comfortable_with_sitter_cat: e.target.value})}}
                 >
                 <option selected disabled value="">
                   Select
                 </option>
                 <option value="Yes">Yes</option>
                 <option value="No">No</option>
                 <option value="Maybe">Maybe</option>
               </NativeSelect>
             </FormInput>
           </Grid>
           {overnightData.comfortable_with_sitter_cat == 'Maybe' && <Grid item xs={12}  sm={12} 
            md={window.location.pathname != "/booking-request" ? 5 : undefined}>
            <FormInput fullWidth variant="standard">
              <CustomLabelInput shrink="true">
              Please share additional details here:
              </CustomLabelInput>
                <InputField
                    type="text"
                    size="small"
                    placeholder="Enter Details"
                    value={overnightData.comfortable_with_sitter_cat_text}
                    onChange={(e) => {setOvernightData({...overnightData, comfortable_with_sitter_cat_text: e.target.value})}}
                  />
                </FormInput>
            </Grid>}
           <Grid item xs={12}  sm={12} 
            md={window.location.pathname != "/booking-request" ? 5 : undefined}>
             <FormInput fullWidth variant="standard">
               <CustomLabelInput shrink="true">
                Is it OK if the sitter's home has a children?<span style={{color: 'red', fontSize: '20px'}}>*</span>
               </CustomLabelInput>
               <NativeSelect
                 disableUnderline
                 defaultValue=""
                 ref={(el) => (inputRefs.current.comfortable_with_sitter_children = el)}
                 onFocus={() => {
                   if (inputRefs.current.comfortable_with_sitter_children) {
                     inputRefs.current.comfortable_with_sitter_children.style.backgroundColor = "";
                   }
                 }}  
                 inputProps={{style: {fontFamily: "Brandon", padding: "8.5px 14px",}}}
                 sx={{ border: "1px solid #ced4d9", borderRadius: "3px", marginTop: "5px",}}
                 value={overnightData.comfortable_with_sitter_children}
                 onChange={(e) => {setOvernightData({...overnightData, comfortable_with_sitter_children: e.target.value})}}
                 >
                 <option selected disabled value="">
                   Select
                 </option>
                 <option value="Yes">Yes</option>
                 <option value="No">No</option>
                 <option value="Maybe">Maybe</option>
               </NativeSelect>
             </FormInput>
           </Grid>
           {overnightData.comfortable_with_sitter_children == 'Maybe' && <Grid item xs={12}  sm={12} 
            md={window.location.pathname != "/booking-request" ? 5 : undefined}
           >
            <FormInput fullWidth variant="standard">
              <CustomLabelInput shrink="true">
              Please share additional details here:
              </CustomLabelInput>
                <InputField
                    type="text"
                    size="small"
                    placeholder="Enter Details"
                    value={overnightData.comfortable_with_sitter_children_text}
                    onChange={(e) => {setOvernightData({...overnightData, comfortable_with_sitter_children_text: e.target.value})}}
                  />
                </FormInput>
            </Grid>}
        </Grid>}

        <Grid container xs={12} sx={{gap:'15px'}}>
          <Grid item xs={12} md={5}>
          <FormInput fullWidth variant="standard">
            <CustomLabelInput shrink="true">
              Start Date <span style={{color: 'red', fontSize: '20px'}}>*</span>
            </CustomLabelInput> 
            <InputField
              type="date"
              size="small"
              ref={(el) => (inputRefs.current.overnight_start_date = el)}
              onFocus={() => {
                if (inputRefs.current.overnight_start_date) {
                  inputRefs.current.overnight_start_date.style.backgroundColor = "";
                }
              }}  
              value={overnightData.overnight_start_date_time.date}
              inputProps={{ min: new Date().toISOString().substring(0,10)}}
              onChange={handleStartDateChange}
            
              placeholder="Enter Date"
            />
          </FormInput>
          </Grid>
          <Grid item xs={12} md={5}>
          <FormInput fullWidth variant="standard">
              <CustomLabelInput shrink="true">
              Start Time <span style={{color: 'red', fontSize: '20px'}}>*</span>
              </CustomLabelInput>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <MobileTimePicker
                 sx={{marginTop: '5px'}}
                 ampm={false}
                 ref={(el) => (inputRefs.current.overnight_start_time = el)}
                 onFocus={() => {
                   if (inputRefs.current.overnight_start_time) {
                     inputRefs.current.overnight_start_time.style.backgroundColor = "";
                   }
                 }}  
                 defaultValue={dayjs()}
                 value={overnightData.overnight_start_date_time.time}
                 onChange={(e) => setOvernightData({...overnightData, overnight_start_date_time: {...overnightData.overnight_start_date_time, time: e.format('HH:mm')}})}
                 />
              </LocalizationProvider>            
              </FormInput>
              </Grid>
        </Grid>

        <Grid container xs={12} sx={{gap:'15px'}}>
          <Grid item xs={12} md={5}>
          <FormInput fullWidth variant="standard">
            <CustomLabelInput shrink="true">
              End Date <span style={{color: 'red', fontSize: '20px'}}>*</span>
            </CustomLabelInput> 
            <InputField
              type="date"
              size="small"
              ref={(el) => (inputRefs.current.overnight_end_date = el)}
              onFocus={() => {
                if (inputRefs.current.overnight_end_date) {
                  inputRefs.current.overnight_end_date.style.backgroundColor = "";
                }
              }}  
              value={overnightData.overnight_end_date_time.date || ""}  // Allow blank value
              inputProps={{ min: getNextDay(overnightData.overnight_start_date_time.date) }}
              onChange={(e) => setOvernightData({...overnightData, overnight_end_date_time: {...overnightData.overnight_end_date_time, date: e.target.value}})}
              placeholder="Enter Date"
            />
          </FormInput>
          </Grid>
          <Grid item xs={12} md={5}>
          <FormInput fullWidth variant="standard">
              <CustomLabelInput shrink="true">
              End Time <span style={{color: 'red', fontSize: '20px'}}>*</span>
              </CustomLabelInput>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <MobileTimePicker 
                 sx={{marginTop: '5px'}}
                 ampm={false}
                 ref={(el) => (inputRefs.current.overnight_end_time = el)}
                 onFocus={() => {
                   if (inputRefs.current.overnight_end_time) {
                     inputRefs.current.overnight_end_time.style.backgroundColor = "";
                   }
                 }}  
                 defaultValue={dayjs()}
                 value={overnightData.overnight_end_date_time.time}
                 onChange={(e) => setOvernightData({...overnightData, overnight_end_date_time: {...overnightData.overnight_end_date_time, time: e.format('HH:mm')}})}
                 />
              </LocalizationProvider>            
              </FormInput>
              </Grid>
        </Grid>

          <h4 style={{fontFamily: 'Brandon', fontWeight:'bold', margin: '5px 0px 0px 0px', color: '#00000099', width: '100%', textAlign: 'center'}}>No of days: {overnightTimeDiff}</h4>
        <Grid item xs= {12} sx={{display: 'flex', justifyContent: 'center'}}> 
            <Button
              type="submit"
              className="default-btn"
              variant="contained"
              sx={{ mt: 3, mb: 2, width: "auto" }}
              onClick={handleCreateOvernightBooking}
            >
              Submit Request
            </Button>
            </Grid> 

      </Grid>
          </div>
          
    }
    {activeModule == 3 && 
         <div>
         <Box className="wrap-back-btn" onClick={() => {setActiveModule(0)}}>{BackBtn()} Back</Box>
           <h2 className="request-type-heading"> Booking Request - Home Visits</h2>
           <Grid container sx={{marginTop:'10px'}}>
           <Grid item xs={false} sm={6} md={12}>
           <AddressModule data={(data) => setAddressData(data)}/>
        </Grid>
        {/* <Grid item xs={false} sm={2} md={6}> */}
        <Grid item xs={12}>
             <FormInput fullWidth variant="standard">
               <CustomLabelInput shrink="true">
               How many visits would you like in a day? <span style={{color: 'red', fontSize: '20px'}}>*</span>
               </CustomLabelInput>
               <NativeSelect
                 disableUnderline
                 defaultValue=""
                 value={number_of_home_care_visits}
                 onChange={(e) => {setHomeVistData({...homeVistData, number_of_home_care_visits: e.target.value})}}
                 inputProps={{style: {fontFamily: "Brandon", padding: "8.5px 14px",}}}
                 sx={{ border: "1px solid #ced4d9", borderRadius: "3px", marginTop: "5px",}}
                 >
                 <option selected disabled value="">
                   Select
                 </option>
                 <option value="one">One</option>
                 <option value="two">Two</option>
                 <option value="three">Three</option>
               </NativeSelect>
             </FormInput>
           </Grid>
          
        <Grid item xs={12}>
            <FormInput fullWidth variant="standard">
              <CustomLabelInput shrink="true">
              What would you like the dog sitter to do for your dog during the visit? <span style={{color: 'red', fontSize: '20px'}}>*</span>
              </CustomLabelInput>
              <InputTeaxtArea
                minRows={2}
                value={home_care_activities}
                onChange={(e) => {setHomeVistData({...homeVistData, home_care_activities: e.target.value})}}
                sx={{fontFamily: 'Brandon', height:"40px", fontSize: "1rem", border: "1px solid #ced4d9", borderRadius:'3px', padding: "8.5px 14px", marginTop:"5px"}}
                size="small"
                placeholder="Enter Details"
              />
            </FormInput>
          </Grid>
          <Grid item xs= {12} sx={{display: 'flex', justifyContent: 'center'}}> 
            <Button
              type="submit"
              className="default-btn"
              variant="contained"
              sx={{ mt: 3, mb: 2, width: "auto" }}
              onClick={handleCreateHomeVisitBooking}
            >
              Submit Request
            </Button>
            </Grid> 
      </Grid>
         </div>
         
    }
      <SnackBar
      open={snackbar.isOpen}
      handleClose={() => setSnackbar({...snackbar, isOpen: false})}
      duration={300000}
      msg={snackbar.message}
      type={snackbar.type}
    />
   { dogList == 0 && 
    <AddDogDialog isOpen={isInfoDialogOpen} handleCloseDialog={() => setIsInfoDialogOpen(false)}/>}
    </>
)
}

export default BookingRequestForm