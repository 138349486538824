import React, { useEffect, useRef, useState } from "react";
import { Button, Grid, NativeSelect, Typography, Box } from "@mui/material"
import { deleteBookingById, getDogdetailByid, updateConstentDataById } from "../../../../../../Utils/Api";
import { useNavigate, useParams } from "react-router-dom";
import { CustomLabelInput, FormInput, InputTeaxtArea } from "../../../../../../Components/CustomElements";
import EditNoteOutlinedIcon from "@mui/icons-material/EditNoteOutlined";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import DynamicAlertPopup from "../../../../../../Components/Dialogs/dynamicSweetAlert";
import { BackBtn } from "../../../../../../Assets/assets";
import Masthead from "../../../../../../Components/Masthead";
import SnackBar from "../../../../../../Components/Snackbar";

const DogCareForm = () => {
  const { dogId } = useParams();
  const navigate = useNavigate()
  const [dogData, setDogData] = useState([])
  const [isDisabled, setIsDisabled] = useState(true)
  const[formData, setFormData] = useState([])
  const [deleteBookingDogForm, setDeleteBookingDogForm] = useState(false)
  const [snackbar, setSnackbar] = useState({ isOpen : false, message: '', type: ''})

  const inputRefs = useRef({}); // Initialize as an empty object

const getDogDetails = async () => {
  try {
    const { data } = await getDogdetailByid(dogId);

    if (!data.data.dog_info_form) {
      setIsDisabled(false);
    }

    // Merge dog_info_form with additional fields if missing
    const mergedFormData = {
      ...data.data.dog_info_form, // Existing form data
      is_aggressive_about_anything:
        data.data.dog_info_form?.is_aggressive_about_anything ?? data.data.is_aggressive,
      get_along_with_other_dogs:
        data.data.dog_info_form?.get_along_with_other_dogs ?? data.data.is_friendly_with_other_dogs,
      comfortable_with_cats:
        data.data.dog_info_form?.comfortable_with_cats ?? data.data.is_comfortable_around_cat,
    };

    setFormData(mergedFormData);
    setDogData(data.data);
  } catch (error) {
    console.error("Error fetching dog details:", error);
  }
};

const handleUpdateData = (e) => {
  const { name, value } = e.target;

  setFormData((prevData) => ({
    ...prevData,
    [name]: value, // Dynamically update the corresponding field
  }));
};

const showError = (field, message) => {
  const element = inputRefs.current[field];
  if (element) {
    element.scrollIntoView({ behavior: "smooth", block: "center" });
    element.style.backgroundColor = "#FEF2F6"; 
    setTimeout(() => {
      element.style.backgroundColor = ""; 
    }, 2000);
  }
  setSnackbar({ isOpen: true, message, type: "error" });
}

const handleUpdateDataById = async () => {
  const requiredFields = [
    "kind_of_day_dog_need",
    "no_of_meals_in_a_day",
    "food_to_avoid",
    "is_toilet_trained",
    "no_of_walks_in_a_day",
    "walk_duration_info",
    "walk_additional_info",
    "anything_to_hide_from_dog",
    "remove_dog_collar_at_home",
    "dog_damages_things",
    "get_along_with_other_dogs",
    "anything_dog_not_good_in",
    "comfortable_with_cats",
    "react_with_strangers",
    "is_possessive_with_toys_or_food",
    "is_aggressive_about_anything",
    "common_words_or_signals",
    "comfortable_travelling_in_car",
    "address_info_to_leave_dog_if_not_available",
    "emergency_details",
    "vaccination_up_to_date",
    "any_medication_to_dog",
    "any_health_issues",
  ];

  for (const field of requiredFields) {
    if (!formData?.[field]) {
      showError(field, "Oops! Looks like you have missed a question");
      return;
    }
  }

  try {
    const response = await updateConstentDataById(dogId, { dog_info_form: formData });

    if (response.data.response_code === 80) {
      navigate(`/dashboard/dog/view/${dogId}`);
    }
  } catch (error) {
    console.error("Error updating data:", error);
    setSnackbar({
      isOpen: true,
      message: "Failed to update data. Please try again.",
      type: "error",
    });
  }
};


  const handleDeleteDogForm = () => {
    deleteBookingById(dogId).then((res) => {
      if(res.data.response_code == 80) {
        setDeleteBookingDogForm(false);
        navigate(`/dashboard/dog/view/${dogId}`)
      }
    })
  }
  
  useEffect(() => {
    getDogDetails();
  }, []);

  return(
    <Box sx={{ display: "grid", gridTemplateRows: "auto 1fr", height: "100vh" }}>
    <Grid item sx={{ position: "sticky", top: 0, zIndex: 10 }}>
            <Masthead />
          </Grid>
            <Box container component="main" sx={{ flexGrow: 1, overflowY: "auto" ,scrollbarWidth: "none", /* Firefox */
                                              "&::-webkit-scrollbar": { display: "none" } /* Chrome, Safari, Edge */}}>
    <Box className='dogs-container'>
          <Box sx={{display: 'flex', alignItems: 'center'}}>
          <Box className="wrap-back-btn" onClick={() => {navigate(`/dashboard/dog/view/${dogId}`)}}>{BackBtn()} Back</Box>
          <Box className="wrap-back-btn" style={{color: '#f48220', marginLeft: "5px"}} onClick={() => { navigate('/dashboard') }}> /Home</Box>
          <Typography
            className="my-dog-heading"
            sx={{ mt: 5, mb: 5}}
          >
           Care Information - {dogData.name}
          </Typography>
          </Box>
      <Grid xs={12} sx={{textAlign: "right"}}>
          <Button className="dog-edit-action" startIcon={isDisabled ? <EditNoteOutlinedIcon/> : <CancelRoundedIcon/>} onClick={() => setIsDisabled(!isDisabled)}>{isDisabled ? 'Update' : 'Undo'}</Button>
          <Button className="dog-delete-action" onClick={() => setDeleteBookingDogForm(true)}><DeleteForeverIcon /> Delete</Button>
      </Grid>
        <Grid  container  spacing={2}>
                    <Grid item xs={12}  md={6} sx={{ display: "block" }}>
        <Grid item xs={12}>
        <Typography className="view-dog-heading" sx={{ mt: 2, mb: 0 }}>Basic Care</Typography>
        </Grid>
        <Grid item xs={12} >
              <FormInput fullWidth variant="standard">
                <CustomLabelInput htmlFor="kind_of_day_dog_need">
                What kind of day does your dog need? <span style={{color: 'red', fontSize: '20px'}}>*</span>
                </CustomLabelInput>
                <NativeSelect
                  disableUnderline
                  defaultValue=''
                  name="kind_of_day_dog_need"
                  inputProps={{style:{fontFamily: 'Brandon', padding:'8.5px 14px'}}}
                  value={formData?.kind_of_day_dog_need}
                  inputRef={(el) => (inputRefs.current.kind_of_day_dog_need = el)}
                  disabled={isDisabled}
                  className={isDisabled ? "" : "disabled-field"}
                  onChange={handleUpdateData}
                  sx={{ border: "1px solid #ced4d9", borderRadius: '3px', marginTop: '5px'}}
                >   <option selected disabled value=''>Select</option>
                    <option value='An Active Day'>An Active Day</option>
                    <option value='A Quite Day'>A Quite Day</option>
                    <option value='Other'>Other</option>
                </NativeSelect>
              </FormInput>
            </Grid>
          {  formData?.kind_of_day_dog_need =="Other" && <Grid item xs={12} >
              <FormInput fullWidth variant="standard">
                <CustomLabelInput shrink="true" htmlFor="kind_of_day_dog_need_details">
                Please share more details
                </CustomLabelInput>
                <InputTeaxtArea
                  type="textarea"
                  placeholder="Enter Details"
                  minRows={2}
                  size="small"
                  name="kind_of_day_dog_need_details"
                  sx={{fontFamily: 'Brandon', height:"40px", fontSize: "1rem", border: "1px solid #ced4d9", borderRadius:'3px', padding: "8.5px 14px", marginTop:"5px"}}
                  value={formData?.kind_of_day_dog_need_details}
                  disabled={isDisabled}
                  className={isDisabled ? "" : "disabled-field"}
                  onChange={handleUpdateData}
                />
              </FormInput>
            </Grid>}
          <Grid item xs={12} >
              <FormInput fullWidth variant="standard">
                <CustomLabelInput htmlFor="no_of_meals_in_a_day">
                  How many times does your dog need to be given food in a day? <span style={{color: 'red', fontSize: '20px'}}>*</span>
                </CustomLabelInput>
                <NativeSelect
                  disableUnderline
                  defaultValue=''
                  name="no_of_meals_in_a_day"
                  inputProps={{style:{fontFamily: 'Brandon', padding:'8.5px 14px'}}}
                  value={formData?.no_of_meals_in_a_day}
                  inputRef={(el) => (inputRefs.current.no_of_meals_in_a_day = el)}
                  disabled={isDisabled}
                  className={isDisabled ? "" : "disabled-field"}
                  onChange={handleUpdateData}
                  sx={{ border: "1px solid #ced4d9", borderRadius: '3px', marginTop: '5px'}}
                >   <option selected disabled value=''>Select</option>
                    <option value='once'>Once</option>
                    <option value='twice'>Twice</option>
                    <option value='thrice'>Thrice</option>
                </NativeSelect>
              </FormInput>
            </Grid>
            <Grid item xs={12} >
              <FormInput fullWidth variant="standard">
                <CustomLabelInput shrink="true" htmlFor="meal_additional_info">
                  Please share more details about what your dog should be given for each meal
                </CustomLabelInput>
                <InputTeaxtArea
                  type="textarea"
                  placeholder="Enter Details"
                  minRows={2}
                  size="small"
                  name="meal_additional_info"
                  sx={{fontFamily: 'Brandon', height:"40px", fontSize: "1rem", border: "1px solid #ced4d9", borderRadius:'3px', padding: "8.5px 14px", marginTop:"5px"}}
                  value={formData?.meal_additional_info}
                  disabled={isDisabled}
                  className={isDisabled ? "" : "disabled-field"}
                  onChange={handleUpdateData}
                />
              </FormInput>
            </Grid>
            <Grid item xs={12} >
              <FormInput fullWidth variant="standard">
                <CustomLabelInput shrink="true" htmlFor="food_to_avoid">
                Anything specific he/she is not allowed to eat or is allergic to? Please provide details <span style={{color: 'red', fontSize: '20px'}}>*</span>
                </CustomLabelInput>
                <InputTeaxtArea
                  type="textarea"
                  placeholder="Enter Details"
                  minRows={2}
                  size="small"
                  ref={(el) => (inputRefs.current.food_to_avoid = el)}
                  name="food_to_avoid"
                  sx={{fontFamily: 'Brandon', height:"40px", fontSize: "1rem", border: "1px solid #ced4d9", borderRadius:'3px', padding: "8.5px 14px", marginTop:"5px"}}
                  value={formData?.food_to_avoid}
                disabled={isDisabled}
                className={isDisabled ? "" : "disabled-field"}on
               onChange={handleUpdateData}
                />
              </FormInput>
            </Grid>
            <Grid item xs={12} >
              <FormInput fullWidth variant="standard">
                <CustomLabelInput htmlFor="is_dog_on_heat">
                Will your dog be on heat during the booking?
                </CustomLabelInput>
                <NativeSelect
                  disableUnderline
                  defaultValue=''
                  name="is_dog_on_heat"
                  inputProps={{style:{fontFamily: 'Brandon', padding:'8.5px 14px'}}}
                  value={formData?.is_dog_on_heat}
                  disabled={isDisabled}
                  className={isDisabled ? "" : "disabled-field"}
                  onChange={handleUpdateData}
                  sx={{ border: "1px solid #ced4d9", borderRadius: '3px', marginTop: '5px'}}
                >   <option selected disabled value=''>Select</option>
                    <option value='Yes'>Yes</option>
                    <option value='No'>No</option>
                    <option value='Male so not applicable'>Male so not applicable</option>
                </NativeSelect>
              </FormInput>
            </Grid>
            <Grid item xs={12} >
              <FormInput fullWidth variant="standard">
                <CustomLabelInput htmlFor="is_toilet_trained">
                Is your dog toilet trained? <span style={{color: 'red', fontSize: '20px'}}>*</span>
                </CustomLabelInput>
                <NativeSelect
                  disableUnderline
                  defaultValue=''
                  name="is_toilet_trained"
                  inputRef={(el) => (inputRefs.current.is_toilet_trained = el)}
                  inputProps={{style:{fontFamily: 'Brandon', padding:'8.5px 14px'}}}
                  value={formData?.is_toilet_trained}
                  disabled={isDisabled}
                  className={isDisabled ? "" : "disabled-field"}
                  onChange={handleUpdateData}
                  sx={{ border: "1px solid #ced4d9", borderRadius: '3px', marginTop: '5px'}}
                >   <option selected disabled value=''>Select</option>
                    <option value='Yes'>Yes</option>
                    <option value='No'>No</option>
                    <option value='Somewhat'>Somewhat</option>
                </NativeSelect>
              </FormInput>
            </Grid>
            <Grid item xs={12} >
              <FormInput fullWidth variant="standard">
                <CustomLabelInput shrink="true" htmlFor="toilet_habits">
                Please explain anything else that must be kept in mind about your dog's toilet habits?
                </CustomLabelInput>
                <InputTeaxtArea
                  type="textarea"
                  placeholder="Enter Details"
                  minRows={2}
                  size="small"
                  name="toilet_habits"
                  sx={{fontFamily: 'Brandon', height:"40px", fontSize: "1rem", border: "1px solid #ced4d9", borderRadius:'3px', padding: "8.5px 14px", marginTop:"5px"}}
                  value={formData?.toilet_habits}
                  disabled={isDisabled}
                  className={isDisabled ? "" : "disabled-field"}
                  onChange={handleUpdateData}
                />
              </FormInput>
            </Grid>
            </Grid>
            <Grid item xs={12} md={6} sx={{ display: "block" }}>

            <Grid item xs={12} >
              <FormInput fullWidth variant="standard">
                <CustomLabelInput htmlFor="no_of_walks_in_a_day">
                How many walks does your dog need in a day? <span style={{color: 'red', fontSize: '20px'}}>*</span>
                </CustomLabelInput>
                <NativeSelect
                  disableUnderline
                  defaultValue=''
                  name="no_of_walks_in_a_day"
                  inputRef={(el) => (inputRefs.current.no_of_walks_in_a_day = el)}
                  inputProps={{style:{fontFamily: 'Brandon', padding:'8.5px 14px'}}}
                  value={formData?.no_of_walks_in_a_day}
                  disabled={isDisabled}
                  className={isDisabled ? "" : "disabled-field"}
                  onChange={handleUpdateData}
                  sx={{ border: "1px solid #ced4d9", borderRadius: '3px', marginTop: '5px'}}
                >   <option selected disabled value=''>Select</option>
                    <option value='1'>1</option>
                    <option value='2'>2</option>
                    <option value='3'>3</option>
                    <option value='4'>4</option>
                </NativeSelect>
              </FormInput>
            </Grid>

            <Grid item xs={12} >
              <FormInput fullWidth variant="standard">
                <CustomLabelInput htmlFor="kind_of_walk_dog_need">
                What kind of walk does your dog need?
                </CustomLabelInput>
                <NativeSelect
                  disableUnderline
                  defaultValue=''
                  name="kind_of_walk_dog_need"
                  inputProps={{style:{fontFamily: 'Brandon', padding:'8.5px 14px'}}}
                  value={formData?.kind_of_walk_dog_need}
                  disabled={isDisabled}
                  className={isDisabled ? "" : "disabled-field"}
                  onChange={handleUpdateData}
                  sx={{ border: "1px solid #ced4d9", borderRadius: '3px', marginTop: '5px'}}
                >   <option selected disabled value=''>Select</option>
                    <option value='Active Walk'>Active Walk</option>
                    <option value='Relaxed Walk'>Relaxed Walk</option>
                    <option value='Other'>Other</option>
                </NativeSelect>
              </FormInput>
            </Grid>
            {formData?.kind_of_walk_dog_need == "Other" && <Grid item xs={12} >
              <FormInput fullWidth variant="standard">
                <CustomLabelInput shrink="true" htmlFor="kind_of_walk_dog_need_details">
                Please share more details
                </CustomLabelInput>
                <InputTeaxtArea
                  type="textarea"
                  placeholder="Enter Details"
                  minRows={2}
                  size="small"
                  name="kind_of_walk_dog_need_details"
                  sx={{fontFamily: 'Brandon', height:"40px", fontSize: "1rem", border: "1px solid #ced4d9", borderRadius:'3px', padding: "8.5px 14px", marginTop:"5px"}}
                  value={formData?.kind_of_walk_dog_need_details}
                  disabled={isDisabled}
                  className={isDisabled ? "" : "disabled-field"}
                  onChange={handleUpdateData}
                />
              </FormInput>
            </Grid>}

            <Grid item xs={12} >
              <FormInput fullWidth variant="standard">
                <CustomLabelInput shrink="true" htmlFor="walk_duration_info">
                 How long should each walk be? Please specify for each walk so that the dogsitter has all the information needed. <span style={{color: 'red', fontSize: '20px'}}>*</span>
                </CustomLabelInput>
                <InputTeaxtArea
                  type="textarea"
                  placeholder="Enter Details"
                  minRows={2}
                  size="small"
                  name="walk_duration_info"
                  ref={(el) => (inputRefs.current.walk_duration_info = el)}
                  sx={{fontFamily: 'Brandon', height:"40px", fontSize: "1rem", border: "1px solid #ced4d9", borderRadius:'3px', padding: "8.5px 14px", marginTop:"5px"}}
                  value={formData?.walk_duration_info}
                disabled={isDisabled}
                className={isDisabled ? "" : "disabled-field"}on
               onChange={handleUpdateData}
                />
              </FormInput>
            </Grid>
            <Grid item xs={12} >
              <FormInput fullWidth variant="standard">
                <CustomLabelInput shrink="true" htmlFor="walk_additional_info">
                Is there anything the dog sitter needs to be careful about when walking your dog?Please explain. <span style={{color: 'red', fontSize: '20px'}}>*</span>
                </CustomLabelInput>
                <InputTeaxtArea
                  type="textarea"
                  placeholder="Enter Details"
                  minRows={2}
                  size="small"
                  ref={(el) => (inputRefs.current.walk_additional_info = el)}
                  name="walk_additional_info"
                  sx={{fontFamily: 'Brandon', height:"40px", fontSize: "1rem", border: "1px solid #ced4d9", borderRadius:'3px', padding: "8.5px 14px", marginTop:"5px"}}
                  value={formData?.walk_additional_info}
                disabled={isDisabled}
                className={isDisabled ? "" : "disabled-field"}on
               onChange={handleUpdateData}
                />
              </FormInput>
            </Grid>
            <Grid item xs={12} >
              <FormInput fullWidth variant="standard">
                <CustomLabelInput shrink="true" htmlFor="trained_walk_on_leash">
                Is your dog trained to walk on the leash? Please explain
                </CustomLabelInput>
                <InputTeaxtArea
                  type="textarea"
                  placeholder="Enter Details"
                  minRows={2}
                  size="small"
                  name="trained_walk_on_leash"
                  sx={{fontFamily: 'Brandon', height:"40px", fontSize: "1rem", border: "1px solid #ced4d9", borderRadius:'3px', padding: "8.5px 14px", marginTop:"5px"}}
                  value={formData?.trained_walk_on_leash}
                disabled={isDisabled}
                className={isDisabled ? "" : "disabled-field"}on
               onChange={handleUpdateData}
                />
              </FormInput>
            </Grid>
            </Grid>
            
        </Grid>

        <Typography className="view-dog-heading" sx={{ mt: 5, mb: 5 }}>Knowing Your Dog Better</Typography>

      <Grid container  spacing={2} >
      <Grid item xs={12}  md={6} sx={{ display: "block" }}>

      <Grid item xs={12}>
      <FormInput fullWidth variant="standard">
                <CustomLabelInput htmlFor="is_dog_allowed_on_sofa">
                Is your dog allowed on the sofas at home?
                </CustomLabelInput>
                <NativeSelect
                  disableUnderline
                  defaultValue=''
                  name="is_dog_allowed_on_sofa"
                  inputProps={{style:{fontFamily: 'Brandon', padding:'8.5px 14px'}}}
                  value={formData?.is_dog_allowed_on_sofa}
                  disabled={isDisabled}
                  className={isDisabled ? "" : "disabled-field"}
                  onChange={handleUpdateData}
                  sx={{ border: "1px solid #ced4d9", borderRadius: '3px', marginTop: '5px'}}
                >   <option selected disabled value=''>Select</option>
                    <option value='Yes'>Yes</option>
                    <option value='No'>No</option>
                    <option value='Sometimes'>Sometimes</option>
                    <option value='Yes but please don`t allow'>Yes but please don`t allow</option>
                </NativeSelect>
              </FormInput>
            </Grid>
            <Grid item xs={12}>
            <FormInput fullWidth variant="standard">
                <CustomLabelInput shrink="true" htmlFor="is_dog_used_to_sleep_on_bed">
                Is your dog used to sleeping on the bed?
                </CustomLabelInput>
                <InputTeaxtArea
                  type="textarea"
                  placeholder="Enter Details"
                  minRows={2}
                  size="small"
                  name="is_dog_used_to_sleep_on_bed"
                  sx={{fontFamily: 'Brandon', height:"40px", fontSize: "1rem", border: "1px solid #ced4d9", borderRadius:'3px', padding: "8.5px 14px", marginTop:"5px"}}
                  value={formData?.is_dog_used_to_sleep_on_bed}
                disabled={isDisabled}
                className={isDisabled ? "" : "disabled-field"}on
               onChange={handleUpdateData}
                />
              </FormInput>
            </Grid>
            <Grid item xs={12}>
            <FormInput fullWidth variant="standard">
                <CustomLabelInput shrink="true" htmlFor="is_dog_used_to_sleep_in_same_room">
                Is your dog used to sleeping in the same room?
                </CustomLabelInput>
                <InputTeaxtArea
                  type="textarea"
                  placeholder="Enter Details"
                  minRows={2}
                  size="small"
                  name="is_dog_used_to_sleep_in_same_room"
                  sx={{fontFamily: 'Brandon', height:"40px", fontSize: "1rem", border: "1px solid #ced4d9", borderRadius:'3px', padding: "8.5px 14px", marginTop:"5px"}}
                  value={formData?.is_dog_used_to_sleep_in_same_room}
                disabled={isDisabled}
                className={isDisabled ? "" : "disabled-field"}on
               onChange={handleUpdateData}
                />
              </FormInput>
            </Grid>
            <Grid item xs={12}>
            <FormInput fullWidth variant="standard">
                <CustomLabelInput shrink="true" htmlFor="anything_to_hide_from_dog">
                Is there anything that needs to be put away or kept hidden when your dog is alone at home? <span style={{color: 'red', fontSize: '20px'}}>*</span>
                </CustomLabelInput>
                <InputTeaxtArea
                  type="textarea"
                  placeholder="Enter Details"
                  minRows={2}
                  size="small"
                  ref={(el) => (inputRefs.current.anything_to_hide_from_dog = el)}
                  name="anything_to_hide_from_dog"
                  sx={{fontFamily: 'Brandon', height:"40px", fontSize: "1rem", border: "1px solid #ced4d9", borderRadius:'3px', padding: "8.5px 14px", marginTop:"5px"}}
                  value={formData?.anything_to_hide_from_dog}
                  disabled={isDisabled}
                  className={isDisabled ? "" : "disabled-field"}
                  onChange={handleUpdateData}
                />
              </FormInput>
            </Grid>

            <Grid item xs={12}>
            <FormInput fullWidth variant="standard">
                <CustomLabelInput htmlFor="remove_dog_collar_at_home">
                Do you remove your dog's collar at home? <span style={{color: 'red', fontSize: '20px'}}>*</span>
                </CustomLabelInput>
                <NativeSelect
                  disableUnderline
                  defaultValue=''
                  name="remove_dog_collar_at_home"
                  inputRef={(el) => (inputRefs.current.remove_dog_collar_at_home = el)}
                  inputProps={{style:{fontFamily: 'Brandon', padding:'8.5px 14px'}}}
                  value={formData?.remove_dog_collar_at_home}
                  disabled={isDisabled}
                  className={isDisabled ? "" : "disabled-field"}
                  onChange={handleUpdateData}
                  sx={{ border: "1px solid #ced4d9", borderRadius: '3px', marginTop: '5px'}}
                >   <option selected disabled value=''>Select</option>
                    <option value='Yes'>Yes</option>
                    <option value='No'>No</option>
                </NativeSelect>
              </FormInput>
            </Grid>
            <Grid item xs={12}>
            <FormInput fullWidth variant="standard">
                <CustomLabelInput shrink="true" htmlFor="dog_damages_things">
                Does your dog chew on,  scratch or otherwise damage furniture,  electronics or other household objects? <span style={{color: 'red', fontSize: '20px'}}>*</span>
                </CustomLabelInput>
                <InputTeaxtArea
                  type="textarea"
                  placeholder="Enter Details"
                  minRows={2}
                  size="small"
                  ref={(el) => (inputRefs.current.dog_damages_things = el)}
                  name="dog_damages_things"
                  sx={{fontFamily: 'Brandon', height:"40px", fontSize: "1rem", border: "1px solid #ced4d9", borderRadius:'3px', padding: "8.5px 14px", marginTop:"5px"}}
                  value={formData?.dog_damages_things}
                  disabled={isDisabled}
                  className={isDisabled ? "" : "disabled-field"}
                  onChange={handleUpdateData}
                />
              </FormInput>
            </Grid>
            <Grid item xs={12}>
    <FormInput fullWidth variant="standard">
        <CustomLabelInput htmlFor="get_along_with_other_dogs">
            Does your dog get along with other dogs? 
            <span style={{ color: 'red', fontSize: '20px' }}>*</span>
        </CustomLabelInput>
        <NativeSelect
            disableUnderline
            defaultValue=""
            name="get_along_with_other_dogs"
            inputRef={(el) => (inputRefs.current.get_along_with_other_dogs = el)}
            inputProps={{ style: { fontFamily: 'Brandon', padding: '8.5px 14px' } }}
            value={formData?.get_along_with_other_dogs || dogData?.is_friendly_with_other_dogs}
            disabled={isDisabled}
            className={isDisabled ? "" : "disabled-field"}
            onChange={handleUpdateData}
            sx={{ border: "1px solid #ced4d9", borderRadius: '3px', marginTop: '5px' }}
        >
            <option selected disabled value="">Select</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
            <option value="Maybe">Maybe</option>
        </NativeSelect>
    </FormInput>

            </Grid>
            <Grid item xs={12}>
            <FormInput fullWidth variant="standard">
                <CustomLabelInput shrink="true" htmlFor="anything_dog_not_good_in">
                Are there any specific situations he/she is good/not good in? Please explain <span style={{color: 'red', fontSize: '20px'}}>*</span>
                </CustomLabelInput>
                <InputTeaxtArea
                  type="textarea"
                  placeholder="Enter Details"
                  minRows={2}
                  size="small"
                  name="anything_dog_not_good_in"
                  ref={(el) => (inputRefs.current.anything_dog_not_good_in = el)}
                  sx={{fontFamily: 'Brandon', height:"40px", fontSize: "1rem", border: "1px solid #ced4d9", borderRadius:'3px', padding: "8.5px 14px", marginTop:"5px"}}
                  value={formData?.anything_dog_not_good_in}
                  disabled={isDisabled}
                  className={isDisabled ? "" : "disabled-field"}
                  onChange={handleUpdateData}
                />
              </FormInput>
            </Grid>
            <Grid item xs={12}>
            <FormInput fullWidth variant="standard">
                <CustomLabelInput htmlFor="comfortable_with_cats">
                Is your dog comfortable with cats? <span style={{color: 'red', fontSize: '20px'}}>*</span>
                </CustomLabelInput>
                <NativeSelect
                  disableUnderline
                  defaultValue=''
                  name="comfortable_with_cats"
                  inputRef={(el) => (inputRefs.current.comfortable_with_cats = el)}
                  inputProps={{style:{fontFamily: 'Brandon', padding:'8.5px 14px'}}}
                  value={formData?.comfortable_with_cats ?? dogData?.is_comfortable_around_cat ?? ""}
                  disabled={isDisabled}
                  className={isDisabled ? "" : "disabled-field"}
                  onChange={handleUpdateData}
                  sx={{ border: "1px solid #ced4d9", borderRadius: '3px', marginTop: '5px'}}
                >   <option selected disabled value=''>Select</option>
                    <option value='Yes'>Yes</option>
                    <option value='No'>No</option>
                    <option value='Maybe'>Maybe</option>
                </NativeSelect>
              </FormInput>
            </Grid>
            <Grid item xs={12}>
            <FormInput fullWidth variant="standard">
                <CustomLabelInput shrink="true" htmlFor="comfortable_with_cats_info">
                Please explain
                </CustomLabelInput>
                <InputTeaxtArea
                  type="textarea"
                  placeholder="Enter Details"
                  minRows={2}
                  size="small"
                  name="comfortable_with_cats_info"
                  sx={{fontFamily: 'Brandon', height:"40px", fontSize: "1rem", border: "1px solid #ced4d9", borderRadius:'3px', padding: "8.5px 14px", marginTop:"5px"}}
                  value={formData?.comfortable_with_cats_info || dogData?.is_comfortable_around_cat_text}
                  disabled={isDisabled}
                  className={isDisabled ? "" : "disabled-field"}
                  onChange={handleUpdateData}
                />
              </FormInput>
            </Grid>
            <Grid item xs={12}>
            <FormInput fullWidth variant="standard">
                <CustomLabelInput shrink="true" htmlFor="react_with_strangers">
                How does your dog usually react to strangers? Please explain <span style={{color: 'red', fontSize: '20px'}}>*</span>
                </CustomLabelInput>
                <InputTeaxtArea
                  type="textarea"
                  placeholder="Enter Details"
                  minRows={2}
                  ref={(el) => (inputRefs.current.react_with_strangers = el)}
                  size="small"
                  name="react_with_strangers"
                  sx={{fontFamily: 'Brandon', height:"40px", fontSize: "1rem", border: "1px solid #ced4d9", borderRadius:'3px', padding: "8.5px 14px", marginTop:"5px"}}
                  value={formData?.react_with_strangers}
                  disabled={isDisabled}
                  className={isDisabled ? "" : "disabled-field"}
                  onChange={handleUpdateData}
                />
              </FormInput>
            </Grid>
            <Grid item xs={12}>
            <FormInput fullWidth variant="standard">
                <CustomLabelInput shrink="true" htmlFor="things_to_avoid_when_around_people">
                Is there anything specific that should be avoided when your dog is around people?
                </CustomLabelInput>
                <InputTeaxtArea
                  type="textarea"
                  placeholder="Enter Details"
                  minRows={2}
                  size="small"
                  name="things_to_avoid_when_around_people"
                  sx={{fontFamily: 'Brandon', height:"40px", fontSize: "1rem", border: "1px solid #ced4d9", borderRadius:'3px', padding: "8.5px 14px", marginTop:"5px"}}
                  value={formData?.things_to_avoid_when_around_people}
                  disabled={isDisabled}
                  className={isDisabled ? "" : "disabled-field"}
                  onChange={handleUpdateData}
                />
              </FormInput>
            </Grid>
            </Grid>
            <Grid item xs={12}  md={6} sx={{ display: "block" }}>

            <Grid item xs={12}>
            <FormInput fullWidth variant="standard">
                <CustomLabelInput shrink="true" htmlFor="is_possessive_with_toys_or_food">
                Is your dog possessive about his/her toys or food? <span style={{color: 'red', fontSize: '20px'}}>*</span>
                </CustomLabelInput>
                <InputTeaxtArea
                  type="textarea"
                  placeholder="Enter Details"
                  minRows={2}
                  ref={(el) => (inputRefs.current.is_possessive_with_toys_or_food = el)}
                  size="small"
                  name="is_possessive_with_toys_or_food"
                  sx={{fontFamily: 'Brandon', height:"40px", fontSize: "1rem", border: "1px solid #ced4d9", borderRadius:'3px', padding: "8.5px 14px", marginTop:"5px"}}
                  value={formData?.is_possessive_with_toys_or_food}
                  disabled={isDisabled}
                  className={isDisabled ? "" : "disabled-field"}
                  onChange={handleUpdateData}
                />
              </FormInput>
            </Grid>
            <Grid item xs={12}>
            <FormInput fullWidth variant="standard">
                <CustomLabelInput shrink="true" htmlFor="is_aggressive_about_anything">
                Does he/she get aggressive about anything? <span style={{color: 'red', fontSize: '20px'}}>*</span>
                </CustomLabelInput>
                <InputTeaxtArea
                  type="textarea"
                  ref={(el) => (inputRefs.current.is_aggressive_about_anything = el)}
                  placeholder="Enter Details"
                  minRows={2}
                  size="small"
                  name="is_aggressive_about_anything"
                  sx={{fontFamily: 'Brandon', height:"40px", fontSize: "1rem", border: "1px solid #ced4d9", borderRadius:'3px', padding: "8.5px 14px", marginTop:"5px"}}
                  value={formData?.is_aggressive_about_anything || dogData?.is_aggressive}
                  disabled={isDisabled}
                  className={isDisabled ? "" : "disabled-field"}
                  onChange={handleUpdateData}
                />
              </FormInput>
            </Grid>
  
            <Grid item xs={12}>
            <FormInput fullWidth variant="standard">
                <CustomLabelInput shrink="true" htmlFor="common_words_or_signals">
                Please share a list of the common words/verbal signals your dog understands inwhichever language you use so that the dog sitter is aware of these. Please explain if required. <span style={{color: 'red', fontSize: '20px'}}>*</span>
                </CustomLabelInput>
                <InputTeaxtArea
                  type="textarea"
                  placeholder="Enter Details"
                  minRows={2}
                  size="small"
                  ref={(el) => (inputRefs.current.common_words_or_signals = el)}
                  name="common_words_or_signals"
                  sx={{fontFamily: 'Brandon', height:"40px", fontSize: "1rem", border: "1px solid #ced4d9", borderRadius:'3px', padding: "8.5px 14px", marginTop:"5px"}}
                  value={formData?.common_words_or_signals}
                  disabled={isDisabled}
                  className={isDisabled ? "" : "disabled-field"}
                  onChange={handleUpdateData}
                />
              </FormInput>
            </Grid>
            <Grid item xs={12}>
            <FormInput fullWidth variant="standard">
                <CustomLabelInput shrink="true" htmlFor="comfortable_travelling_in_car">
                Is your dog comfortable travelling by car? <span style={{color: 'red', fontSize: '20px'}}>*</span>
                </CustomLabelInput>
                <InputTeaxtArea
                  type="textarea"
                  placeholder="Enter Details"
                  minRows={2}
                  size="small"
                  ref={(el) => (inputRefs.current.comfortable_travelling_in_car = el)}
                  name="comfortable_travelling_in_car"
                  sx={{fontFamily: 'Brandon', height:"40px", fontSize: "1rem", border: "1px solid #ced4d9", borderRadius:'3px', padding: "8.5px 14px", marginTop:"5px"}}
                  value={formData?.comfortable_travelling_in_car}
                  disabled={isDisabled}
                  className={isDisabled ? "" : "disabled-field"}
                  onChange={handleUpdateData}
                />
              </FormInput>
            </Grid>
            <Grid item xs={12}>
            <FormInput fullWidth variant="standard">
                <CustomLabelInput shrink="true" htmlFor="special_preparation_to_travel_in_car">
                Is there any special preparation needed for him/ her to travel by car? Please explain 
                </CustomLabelInput>
                <InputTeaxtArea
                  type="textarea"
                  placeholder="Enter Details"
                  minRows={2}
                  size="small"
                  name="special_preparation_to_travel_in_car"
                  sx={{fontFamily: 'Brandon', height:"40px", fontSize: "1rem", border: "1px solid #ced4d9", borderRadius:'3px', padding: "8.5px 14px", marginTop:"5px"}}
                  value={formData?.special_preparation_to_travel_in_car}
                  disabled={isDisabled}
                  className={isDisabled ? "" : "disabled-field"}
                  onChange={handleUpdateData}
                />
              </FormInput>
            </Grid>
            <Grid item xs={12}>
            <FormInput fullWidth variant="standard">
                <CustomLabelInput shrink="true" htmlFor="any_habit_or_pattern_of_dog">
                Are there any other habits or patterns of your dog which the dog sitter should know about?
                </CustomLabelInput>
                <InputTeaxtArea
                  type="textarea"
                  placeholder="Enter Details"
                  minRows={2}
                  size="small"
                  name="any_habit_or_pattern_of_dog"
                  sx={{fontFamily: 'Brandon', height:"40px", fontSize: "1rem", border: "1px solid #ced4d9", borderRadius:'3px', padding: "8.5px 14px", marginTop:"5px"}}
                  value={formData?.any_habit_or_pattern_of_dog}
                  disabled={isDisabled}
                  className={isDisabled ? "" : "disabled-field"}
                  onChange={handleUpdateData}
                />
              </FormInput>
            </Grid>
            <Grid item xs={12}>
            <FormInput fullWidth variant="standard">
                <CustomLabelInput htmlFor="no_of_updates_about_dog_in_a_day">
                How many times a day do you want to be updated about your dog? 
                </CustomLabelInput>
                <NativeSelect
                  disableUnderline
                  defaultValue=''
                  name="no_of_updates_about_dog_in_a_day"
                  inputProps={{style:{fontFamily: 'Brandon', padding:'8.5px 14px'}}}
                  value={formData?.no_of_updates_about_dog_in_a_day}
                  disabled={isDisabled}
                  className={isDisabled ? "" : "disabled-field"}
                  onChange={handleUpdateData}
                  sx={{ border: "1px solid #ced4d9", borderRadius: '3px', marginTop: '5px'}}
                >   <option selected disabled value=''>Select</option>
                    <option value='once'>Once</option>
                    <option value='twice'>Twice</option>
                    <option value='thrice'>Thrice</option>
                </NativeSelect>
              </FormInput>
            </Grid>
            {/* </Grid> */}
            <Grid item xs={12}>
            <FormInput fullWidth variant="standard">
                <CustomLabelInput shrink="true" htmlFor="address_info_to_leave_dog_if_not_available">
                How would you prefer to be updated? Please explain and share relevant details like number etc. 
                </CustomLabelInput>
                <InputTeaxtArea
                  type="textarea"
                  placeholder="Enter Details"
                  minRows={2}
                  ref={(el) => (inputRefs.current.address_info_to_leave_dog_if_not_available = el)}
                  size="small"
                  name="update_reference"
                  sx={{fontFamily: 'Brandon', height:"40px", fontSize: "1rem", border: "1px solid #ced4d9", borderRadius:'3px', padding: "8.5px 14px", marginTop:"5px"}}
                  value={formData?.update_reference}
                  disabled={isDisabled}
                  className={isDisabled ? "" : "disabled-field"}
                  onChange={handleUpdateData}
                />
              </FormInput>
            </Grid>

            <Grid item xs={12}>
            <FormInput fullWidth variant="standard">
                <CustomLabelInput shrink="true" htmlFor="address_info_to_leave_dog_if_not_available">
                If you are not able to pick up your dog and have not informed us, please share the name, telephone number and address of an alternate guardian your dog can be handed over to  <span style={{color: 'red', fontSize: '20px'}}>*</span>
                </CustomLabelInput>
                <InputTeaxtArea
                  type="textarea"
                  placeholder="Enter Details"
                  minRows={2}
                  size="small"
                  name="address_info_to_leave_dog_if_not_available"
                  sx={{fontFamily: 'Brandon', height:"40px", fontSize: "1rem", border: "1px solid #ced4d9", borderRadius:'3px', padding: "8.5px 14px", marginTop:"5px"}}
                  value={formData?.address_info_to_leave_dog_if_not_available}
                  disabled={isDisabled}
                  className={isDisabled ? "" : "disabled-field"}
                  onChange={handleUpdateData}
                />
              </FormInput>
            </Grid>

            <Grid item xs={12}>
            <FormInput fullWidth variant="standard">
                <CustomLabelInput shrink="true" htmlFor="emergency_details">
                Please share the name, telephone number and address of someone we can contact in case we cannot reach you in an emergency<span style={{color: 'red', fontSize: '20px'}}>*</span>
                </CustomLabelInput>
                <InputTeaxtArea
                  type="textarea"
                  placeholder="Enter Details"
                  minRows={2}
                  size="small"
                  ref={(el) => (inputRefs.current.emergency_details = el)}
                  name="emergency_details"
                  sx={{fontFamily: 'Brandon', height:"40px", fontSize: "1rem", border: "1px solid #ced4d9", borderRadius:'3px', padding: "8.5px 14px", marginTop:"5px"}}
                  value={formData?.emergency_details}
                  disabled={isDisabled}
                  className={isDisabled ? "" : "disabled-field"}
                  onChange={handleUpdateData}
                />
              </FormInput>
            </Grid>
      </Grid>
</Grid>
        <Typography className="view-dog-heading" sx={{ mt: 5, mb: 5 }}>Medical Information</Typography>

        <Grid container spacing={2} >
        <Grid item xs={12}  md={6} sx={{ display: "block" }}>
          <Grid item xs={12} >
              <FormInput fullWidth variant="standard">
                <CustomLabelInput htmlFor="vaccination_up_to_date">
                Will your dog's vaccinations be up to date at the time of the booking?<span style={{color: 'red', fontSize: '20px'}}>*</span>
                </CustomLabelInput>
                <NativeSelect
                  disableUnderline
                  defaultValue=''
                  name="vaccination_up_to_date"
                  inputRef={(el) => (inputRefs.current.vaccination_up_to_date = el)}
                  inputProps={{style:{fontFamily: 'Brandon', padding:'8.5px 14px'}}}
                  value={formData?.vaccination_up_to_date}
                  disabled={isDisabled}
                  className={isDisabled ? "" : "disabled-field"}
                  onChange={handleUpdateData}
                  sx={{ border: "1px solid #ced4d9", borderRadius: '3px', marginTop: '5px'}}
                >   <option selected disabled value=''>Select</option>
                    <option value='Yes'>Yes</option>
                    <option value='No'>No</option>
                    <option value='Other'>Other</option>
                </NativeSelect>
              </FormInput>
            </Grid>
            {formData?.vaccination_up_to_date == "Other" && <Grid item xs={12} >
              <FormInput fullWidth variant="standard">
                <CustomLabelInput shrink="true" htmlFor="vaccination_additional_info">
                If other, please explain
                </CustomLabelInput>
                <InputTeaxtArea
                  type="textarea"
                  placeholder="Enter Details"
                  minRows={2}
                  size="small"
                  name="vaccination_additional_info"
                  sx={{fontFamily: 'Brandon', height:"40px", fontSize: "1rem", border: "1px solid #ced4d9", borderRadius:'3px', padding: "8.5px 14px", marginTop:"5px"}}
                  value={formData?.vaccination_additional_info}
                  disabled={isDisabled}
                  className={isDisabled ? "" : "disabled-field"}
                  onChange={handleUpdateData}
                />
              </FormInput>
            </Grid>}
            <Grid item xs={12} >
              <FormInput fullWidth variant="standard">
                <CustomLabelInput htmlFor="any_medication_to_dog">
                Does your dog need to be given any medication while you are away?<span style={{color: 'red', fontSize: '20px'}}>*</span>
                </CustomLabelInput>
                <NativeSelect
                  disableUnderline
                  defaultValue=''
                  name="any_medication_to_dog"
                  inputRef={(el) => (inputRefs.current.any_medication_to_dog = el)}
                  inputProps={{style:{fontFamily: 'Brandon', padding:'8.5px 14px'}}}
                  value={formData?.any_medication_to_dog}
                  disabled={isDisabled}
                  className={isDisabled ? "" : "disabled-field"}
                  onChange={handleUpdateData}
                  sx={{ border: "1px solid #ced4d9", borderRadius: '3px', marginTop: '5px'}}
                >   <option selected disabled value=''>Select</option>
                    <option value='Yes'>Yes</option>
                    <option value='No'>No</option>
                    <option value='Other'>Other</option>
                </NativeSelect>
              </FormInput>
            </Grid>
            {formData?.any_medication_to_dog == "Other" && <Grid item xs={12} >
              <FormInput fullWidth variant="standard">
                <CustomLabelInput shrink="true" htmlFor="medication_additional_info">
                If other, please explain
                </CustomLabelInput>
                <InputTeaxtArea
                  type="textarea"
                  placeholder="Enter Details"
                  minRows={2}
                  size="small"
                  name="medication_additional_info"
                  sx={{fontFamily: 'Brandon', height:"40px", fontSize: "1rem", border: "1px solid #ced4d9", borderRadius:'3px', padding: "8.5px 14px", marginTop:"5px"}}
                  value={formData?.medication_additional_info}
                  disabled={isDisabled}
                  className={isDisabled ? "" : "disabled-field"}
                  onChange={handleUpdateData}
                />
              </FormInput>
            </Grid>}
            <Grid item xs={12} >
              <FormInput fullWidth variant="standard">
                <CustomLabelInput shrink="true" htmlFor="any_health_issues">
                Has your dog had any previous health problems or medical conditions? Please explain<span style={{color: 'red', fontSize: '20px'}}>*</span>
                </CustomLabelInput>
                <InputTeaxtArea
                  type="textarea"
                  placeholder="Enter Details"
                  minRows={2}
                  ref={(el) => (inputRefs.current.any_health_issues = el)}
                  size="small"
                  name="any_health_issues"
                  sx={{fontFamily: 'Brandon', height:"40px", fontSize: "1rem", border: "1px solid #ced4d9", borderRadius:'3px', padding: "8.5px 14px", marginTop:"5px"}}
                  value={formData?.any_health_issues}
                  disabled={isDisabled}
                  className={isDisabled ? "" : "disabled-field"}
                  onChange={handleUpdateData}
                />
              </FormInput>
            </Grid>
            </Grid>
            <Grid item xs={12}  md={6} sx={{ display: "block" }}>

            <Grid item xs={12} >
              <FormInput fullWidth variant="standard">
                <CustomLabelInput shrink="true" htmlFor="preferred_veterinary_doctor">
                Please share details of your preferred veterinary doctor in case of a situation where a vet is needed
                </CustomLabelInput>
                <InputTeaxtArea
                  type="textarea"
                  placeholder="Enter Details"
                  minRows={2}
                  size="small"
                  name="preferred_veterinary_doctor"
                  sx={{fontFamily: 'Brandon', height:"40px", fontSize: "1rem", border: "1px solid #ced4d9", borderRadius:'3px', padding: "8.5px 14px", marginTop:"5px"}}
                  value={formData?.preferred_veterinary_doctor}
                  disabled={isDisabled}
                  className={isDisabled ? "" : "disabled-field"}
                  onChange={handleUpdateData}
                />
              </FormInput>
            </Grid>
            <Grid item xs={12} >
              <FormInput fullWidth variant="standard">
                <CustomLabelInput shrink="true" htmlFor="upper_limit_to_spend_on_medication">
                In case of an emergency situation, what is the upper limit you authorise the dog sitter to spend?
                </CustomLabelInput>
                <InputTeaxtArea
                  type="textarea"
                  placeholder="Enter Details"
                  minRows={2}
                  size="small"
                  name="upper_limit_to_spend_on_medication"
                  sx={{fontFamily: 'Brandon', height:"40px", fontSize: "1rem", border: "1px solid #ced4d9", borderRadius:'3px', padding: "8.5px 14px", marginTop:"5px"}}
                  value={formData?.upper_limit_to_spend_on_medication}
                  disabled={isDisabled}
                  className={isDisabled ? "" : "disabled-field"}
                  onChange={handleUpdateData}
                />
              </FormInput>
            </Grid>
            
            <Grid item xs={12} >
              <FormInput fullWidth variant="standard">
                <CustomLabelInput shrink="true" htmlFor="any_medical_insurance">
                Is your dog covered by a specific medical insurance? Please share details
                </CustomLabelInput>
                <InputTeaxtArea
                  type="textarea"
                  placeholder="Enter Details"
                  minRows={2}
                  size="small"
                  name="any_medical_insurance"
                  sx={{fontFamily: 'Brandon', height:"40px", fontSize: "1rem", border: "1px solid #ced4d9", borderRadius:'3px', padding: "8.5px 14px", marginTop:"5px"}}
                  value={formData?.any_medical_insurance}
                  disabled={isDisabled}
                  className={isDisabled ? "" : "disabled-field"}
                  onChange={handleUpdateData}
                />
              </FormInput>
            </Grid>
            </Grid>
        </Grid>
        <Grid xs={12} sx={{textAlign: 'center'}}>
          {!isDisabled &&
            <Button className="default-btn" variant="contained" sx={{ mt: 5, mb: 2, width: "25%" }} onClick={handleUpdateDataById}>Save & Continue</Button>
          }
        </Grid>
        <DynamicAlertPopup handleCloseDialog={() => setDeleteBookingDogForm(false)} confirmBtnTxt='Delete' isOpen={deleteBookingDogForm} title={'Are you sure want to delete dog form?'} handleConfirm={handleDeleteDogForm}/>
      </Box>
      </Box>
      <SnackBar
        open={snackbar.isOpen}
        handleClose={() => setSnackbar({...snackbar, isOpen: false})}
        duration={300000}
        msg={snackbar.message}
        type={snackbar.type}
      />
    </Box>
)
};

export default DogCareForm;
