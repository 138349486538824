import { Avatar, Box, Grid, Tooltip, Typography } from "@mui/material";
import EditNoteOutlinedIcon from "@mui/icons-material/EditNoteOutlined";
import AddTwoToneIcon from "@mui/icons-material/AddTwoTone";
import Masthead from "../../../../../Components/Masthead";
import { useEffect, useState } from "react";
import { getDogList } from "../../../../../Utils/Api";
import Loader from "../../../../../Components/Loader";
import './style.css'
import { useNavigate, useParams } from "react-router-dom";
import { BackBtn } from "../../../../../Assets/assets";

const MyDogs = () => {
  const [dogList, setDogList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0)
  const navigate = useNavigate()

  useEffect(() => {
    setLoading(true)
    getDogList().then((res) => {
      setDogList(res.data.data)
      setLoading(false)
    });
  }, []);

  return (
    <Box sx={{ display: "grid", gridTemplateRows: "auto 1fr", height: "100vh" }}>
      <Grid item sx={{ position: "sticky", top: 0, zIndex: 10 }}>
            <Masthead />
          </Grid>
      {loading ? (
        <Loader />
      ) : (
         <Box container component="main" sx={{ flexGrow: 1, overflowY: "auto" ,scrollbarWidth: "none", /* Firefox */
                                                      "&::-webkit-scrollbar": { display: "none" } /* Chrome, Safari, Edge */}}>
        <Box className='dogs-container'>
          <Box sx={{display: 'flex', alignItems: 'center'}}>
          <Box className="wrap-back-btn" onClick={() => {navigate('/dashboard')}}>{BackBtn()} Back</Box>
          <Box className="wrap-back-btn" style={{color: '#f48220', marginLeft: "5px"}} onClick={() => { navigate('/dashboard') }}> /Home</Box>
          <Typography
            className="my-dog-heading"
            sx={{ mt: 5, mb: 5}}
          >
            My Dogs
          </Typography>
          </Box>
          <Box className='dogs-wrapper'>
            {dogList.map((item, index) => {
              return (
                <Box className='dog-widget' onMouseOver={() => {setCurrentIndex(index)}}>
                  <Tooltip title="View"><Avatar className='dog-widget-avatar' src={item.photos[0]}  onClick={() => navigate(`/dashboard/dog/view/${item.dog_id}`)}/></Tooltip>
                  <Tooltip title="Edit">
                    <EditNoteOutlinedIcon onClick={() => navigate(`/dashboard/dog/edit/${item.dog_id}`)} className="dog-widget-edit"/>
                  </Tooltip>
                  <Typography fontFamily="Brandon" sx={{ textAlign: "center" }}>
                    {item.name}
                  </Typography>
                </Box>
              );
            })}

{dogList.length < 7 && (
  <Box onClick={() => navigate('/dashboard/dog/create')}>
    <Avatar className="new-dog-avatar">
      <AddTwoToneIcon className="new-dog-icon" />
    </Avatar>
    <Typography fontFamily="Brandon" sx={{ textAlign: "center" }}>
      Add New Dog
    </Typography>
  </Box>
)}

          </Box>
          <Box className='dog-info-container'>
            <Typography className="dog-info-typo">Dog's Name : <span>{dogList[currentIndex]?.name}</span></Typography>
            <Typography className="dog-info-typo">Dog's Gender : <span>{dogList[currentIndex]?.gender}</span></Typography>
            <Typography className="dog-info-typo">Dog's Breed : <span>{dogList[currentIndex]?.breed}</span></Typography>
            <Typography className="dog-info-typo">Dog's DOB : <span>{dogList[currentIndex]?.dob}</span></Typography>
          </Box>
        </Box>
        </Box>
      )}
    </Box>
  );
};

export default MyDogs;
