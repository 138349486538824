export const countryValidationRules = {
    '93': { minLength: 9, maxLength: 9 }, // Afghanistan
    '355': { minLength: 8, maxLength: 8 }, // Albania
    '376': { minLength: 6, maxLength: 6 }, // Andorra
    '244': { minLength: 9, maxLength: 9 }, // Angola
    '1268': { minLength: 7, maxLength: 7 }, // Antigua and Barbuda
    '297': { minLength: 7, maxLength: 7 }, // Aruba
    '61': { minLength: 9, maxLength: 9 }, // Australia
    '43': { minLength: 11, maxLength: 11 }, // Austria
    '1242': { minLength: 7, maxLength: 7 }, // Bahamas
    '973': { minLength: 8, maxLength: 8 }, // Bahrain
    '880': { minLength: 11, maxLength: 11 }, // Bangladesh
    '1246': { minLength: 7, maxLength: 7 }, // Barbados
    '501': { minLength: 7, maxLength: 7 }, // Belize
    '229': { minLength: 8, maxLength: 8 }, // Benin
    '975': { minLength: 8, maxLength: 8 }, // Bhutan
    '591': { minLength: 8, maxLength: 8 }, // Bolivia
    '387': { minLength: 8, maxLength: 8 }, // Bosnia and Herzegovina
    '267': { minLength: 8, maxLength: 8 }, // Botswana
    '246': { minLength: 7, maxLength: 7 }, // British Indian Ocean Territory
    '673': { minLength: 7, maxLength: 7 }, // Brunei
    '359': { minLength: 8, maxLength: 8 }, // Bulgaria
    '226': { minLength: 8, maxLength: 8 }, // Burkina Faso
    '257': { minLength: 8, maxLength: 8 }, // Burundi
    '855': { minLength: 8, maxLength: 8 }, // Cambodia
    '237': { minLength: 9, maxLength: 9 }, // Cameroon
    '238': { minLength: 7, maxLength: 7 }, // Cape Verde
    '599': { minLength: 7, maxLength: 7 }, // Caribbean Netherlands
    '236': { minLength: 8, maxLength: 8 }, // Central African Republic
    '235': { minLength: 8, maxLength: 8 }, // Chad
    '56': { minLength: 9, maxLength: 9 }, // Chile
    '269': { minLength: 7, maxLength: 7 }, // Comoros
    '242': { minLength: 9, maxLength: 9 }, // Congo
    '385': { minLength: 9, maxLength: 9 }, // Croatia
    '53': { minLength: 8, maxLength: 8 }, // Cuba
    '599': { minLength: 7, maxLength: 7 }, // Curacao
    '253': { minLength: 7, maxLength: 7 }, // Djibouti
    '1767': { minLength: 7, maxLength: 7 }, // Dominica
    '1809': { minLength: 10, maxLength: 10 }, // Dominican Republic
    '593': { minLength: 9, maxLength: 9 }, // Ecuador
    '20': { minLength: 10, maxLength: 10 }, // Egypt
    '240': { minLength: 9, maxLength: 9 }, // Equatorial Guinea
    '291': { minLength: 7, maxLength: 7 }, // Eritrea,
    '262':{minLength:9,maxLength:9},//Réunion	,
    '1':{minLength:7,maxLength:7},//Puerto Rico	
    '372': { minLength: 7, maxLength: 7 }, // Estonia
    '251': { minLength: 9, maxLength: 9 }, // Ethiopia
    '679': { minLength: 7, maxLength: 7 }, // Fiji
    '33': { minLength: 10, maxLength: 10 }, // France
    '594': { minLength: 9, maxLength: 9 }, // French Guiana
    '689': { minLength: 8, maxLength: 8 }, // French Polynesia
    '241': { minLength: 7, maxLength: 7 }, // Gabon
    '220': { minLength: 7, maxLength: 7 }, // Gambia
    '995': { minLength: 9, maxLength: 9 }, // Georgia
    '233': { minLength: 9, maxLength: 9 }, // Ghana
    '30': { minLength: 10, maxLength: 10 }, // Greece
    '1473': { minLength: 10, maxLength: 10 }, // Grenada
    '590': { minLength: 9, maxLength: 9 }, // Guadeloupe
    '1671': { minLength: 7, maxLength: 7 }, // Guam
    '224': { minLength: 9, maxLength: 9 }, // Guinea
    '245': { minLength: 7, maxLength: 7 }, // Guinea-Bissau
    '592': { minLength: 7, maxLength: 7 }, // Guyana
    '504': { minLength: 8, maxLength: 8 }, // Honduras
    '36': { minLength: 9, maxLength: 9 }, // Hungary
    '62': { minLength: 10, maxLength: 12 }, // Indonesia
    '964': { minLength: 10, maxLength: 10 }, // Iraq
    '972': { minLength: 9, maxLength: 9 }, // Israel
    '1876': { minLength: 10, maxLength: 10 }, // Jamaica
    '81': { minLength: 11, maxLength: 11 }, // Japan
    '962': { minLength: 8, maxLength: 8 }, // Jordan
    '254': { minLength: 10, maxLength: 10 }, // Kenya
    '686': { minLength: 7, maxLength: 7 }, // Kiribati
    '383': { minLength: 8, maxLength: 8 }, // Kosovo
    '965': { minLength: 8, maxLength: 8 }, // Kuwait
    '856': { minLength: 8, maxLength: 8 }, // Laos
    '961': { minLength: 8, maxLength: 8 }, // Lebanon
    '266': { minLength: 8, maxLength: 8 }, // Lesotho
    '231': { minLength: 7, maxLength: 7 }, // Liberia
    '218': { minLength: 9, maxLength: 9 }, // Libya
    '423': { minLength: 8, maxLength: 8 }, // Liechtenstein
    '370': { minLength: 8, maxLength: 8 }, // Lithuania
    '352': { minLength: 9, maxLength: 9 }, // Luxembourg
    '853': { minLength: 8, maxLength: 8 }, // Macau
    '389': { minLength: 8, maxLength: 8 }, // North Macedonia
    '261': { minLength: 9, maxLength: 9 }, // Madagascar
    '265': { minLength: 9, maxLength: 9 }, // Malawi
    '960': { minLength: 7, maxLength: 7 }, // Maldives
    '223': { minLength: 8, maxLength: 8 }, // Mali
    '356': { minLength: 8, maxLength: 8 }, // Malta
    '692': { minLength: 7, maxLength: 7 }, // Marshall Islands
    '596': { minLength: 9, maxLength: 9 }, // Martinique
    '222': { minLength: 8, maxLength: 8 }, // Mauritania
    '230': { minLength: 8, maxLength: 8 }, // Mauritius
    '691': { minLength: 7, maxLength: 7 }, // Micronesia
    '377': { minLength: 8, maxLength: 8 }, // Monaco
    '976': { minLength: 8, maxLength: 8 }, // Mongolia
    '382': { minLength: 8, maxLength: 8 }, // Montenegro
    '212': { minLength: 10, maxLength: 10 }, // Morocco
    '258': { minLength: 9, maxLength: 9 }, // Mozambique
    '95': { minLength: 8, maxLength: 9 }, // Myanmar
    '264': { minLength: 8, maxLength: 8 }, // Namibia
    '674': { minLength: 7, maxLength: 7 }, // Nauru
    '977': { minLength: 10, maxLength: 10 }, // Nepal
    '31': { minLength: 9, maxLength: 9 }, // Netherlands
    '687': { minLength: 8, maxLength: 8 }, // New Caledonia
    '64': { minLength: 9, maxLength: 9 }, // New Zealand
    '505': { minLength: 8, maxLength: 8 }, // Nicaragua
    '227': { minLength: 8, maxLength: 8 }, // Niger
    '234': { minLength: 11, maxLength: 11 }, // Nigeria
    '850': { minLength: 8, maxLength: 8 }, // North Korea
    '968': { minLength: 8, maxLength: 8 }, // Oman
    '680': { minLength: 7, maxLength: 7 }, // Palau
    '970': { minLength: 9, maxLength: 9 }, // Palestine
    '507': { minLength: 8, maxLength: 8 }, // Panama
    '263': { minLength: 9, maxLength: 9 }, // Zimbabwe
    '260': { minLength: 9, maxLength: 9 }, // Zambia
    '967': { minLength: 9, maxLength: 9 }, // Yemen
    '84': { minLength: 10, maxLength: 10 }, // Vietnam
    '58': { minLength: 10, maxLength: 10 }, // Venezuela
    '678': { minLength: 5, maxLength: 7 }, // Vanuatu
    '971': { minLength: 9, maxLength: 9 }, // United Arab Emirates
    '688': { minLength: 5, maxLength: 6 }, // Tuvalu
    '993': { minLength: 8, maxLength: 8 }, // Turkmenistan
    '216': { minLength: 8, maxLength: 8 }, // Tunisia
    '670': { minLength: 7, maxLength: 9 }, // Timor-Leste
    '66': { minLength: 9, maxLength: 10 }, // Thailand
    '255': { minLength: 9, maxLength: 9 }  // Tanzania
  };