import { useState } from "react";
import './style.css'
import { ImageUpload } from "../../Utils/Api";
import SnackBar from "../Snackbar";

const MultiUpload = ({text, imageAssets, limit, isMadatory,highlight ,inputRef}) => {
  const [selectedfile, SetSelectedFile] = useState([]);
  const [snackbar, setSnackbar] = useState({ isOpen : false, message: '', type: ''})

  const InputChange = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      return;
    }
  
    let dynamicLimit = limit === 1 ? 1 : limit > 4 ? e.target.files.length : 3;
    let images = [];
  
    for (let i = 0; i < dynamicLimit && i < e.target.files.length; i++) {
      const acceptedFormats = ["image/jpeg", "image/png", "image/jpg"];
      const file = e.target.files[i];
  
      if (acceptedFormats.includes(file.type)) {
        let reader = new FileReader();
        
        reader.onloadend = () => {
          SetSelectedFile((prevValue) => [
            ...prevValue,
            {
              id: `${file.name}-${file.size}`, 
              filename: file.name,
              filetype: file.type,
              fileimage: reader.result,
            },
          ]);
        };
  
        reader.readAsDataURL(file);
        images.push(file);
      } else {
        setSnackbar({
          ...snackbar,
          isOpen: true,
          message: "Oops! Only JPG, PNG, JPEG formats accepted",
          type: "error",
        });
      }
    }
  
    if (images.length > 0) {
      imageAssets(images);
    }
  };
  
  
  const DeleteSelectFile = (id) => {
    if (window.confirm("Are you sure you want to delete this Image?")) {
      SetSelectedFile(prevFiles => prevFiles.filter(file => file.id !== id));
    }
  };
  
    

  return (
    <div className="card-body">
    <div className="kb-data-box">
      <div className="kb-modal-data-title">
        <div className="kb-data-title">
          {isMadatory ? <h6>Please upload a profile photo for your DogStays account<span style={{color: 'red'}}>*</span></h6> 
          : 
          <h6>Please upload a profile photo for your DogStays account</h6>
          }
        </div>
      </div>
      <div className={`kb-file-upload ${highlight ? "highlight-upload" : ""}`} >
      <div className="file-upload-box">
            <input
              type="file"
              id="fileupload"
              className="file-upload-input"
              onChange={InputChange}
              multiple
              accept=".png, .jpg, .jpeg"
              ref={(el) => inputRef && inputRef.current && (inputRef.current.images = el)} 
              />
            <span style={{textAlign: 'center'}}>
              Drag and drop or{" "}
              <span className="file-link">Choose your files</span>
              <p style={{fontSize: '14px', fontFamily: 'Brandon'}}>{text}</p>
            </span>
          </div>
        </div>
        <div className="kb-attach-box mb-3">
          {selectedfile.map((data, index) => {
            const {
              id,
              filename,
              fileimage,
            } = data;
            console.log(data, 'datadata')
            return (
              <>
              {filename.match(/.(jpg|jpeg|png)$/i) && <div className="file-atc-box" key={id}>
                {filename.match(/.(jpg|jpeg|png)$/i) ? (
                  <div className="file-image">
                    {" "}
                    <img src={fileimage} alt="" />
                  </div>
                ) : false}
                {filename.match(/.(jpg|jpeg|png)$/i) && <div className="file-detail">
                  {/* <h6>{filename}</h6> */}
                  <div className="file-actions">
                     <button
                      type="button"
                      className="file-action-btn"
                      onClick={() => DeleteSelectFile(id)}
                    >
                      Delete
                    </button>
                  </div>
                </div>}
              </div>}
              </>
            );
          })}
        </div>
    </div>
    <SnackBar
      open={snackbar.isOpen}
      handleClose={() => setSnackbar({...snackbar, isOpen: false})}
      duration={300000}
      msg={snackbar.message}
      type={snackbar.type}
    />
  </div>
  );
};

export default MultiUpload;
